import {
  ContactUsCategories,
  EnglishLevelMatch,
  EventKind,
  LessonKind,
} from "@hey-lady/shared/types/graphql";
import { GridColumns } from "../types/generic";
import { GridSize, UserFields } from "./enum";

// TODO Split folders into modules
// Agree on a rule for declaring constants

export const HEY_LADY_USER = "hey-lady-user";

export const ACCEPTED_USER_AVATAR_FILE_EXTENSIONS = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
};
export const ACCEPTED_ARTICLE_PICTURES_FILE_EXTENSIONS = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
};
export const ACCEPTED_CHAT_GROUP_AVATAR_FILE_EXTENSIONS = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
};
export const ACCEPTED_EVENT_ATTACHMENT_FILE_EXTENSIONS = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/pdf": [".pdf"],
};

export const EVENT_CARD_DATE_FORMAT = "EEEE, MMM do p";
export const SHORT_EVENT_CARD_DATE_FORMAT = "EEE MMM do p";
export const EVENT_PAGE_DATE_FORMAT = "EEEE, MMM do";
export const EVENT_PAGE_TIME_FORMAT = "p";
export const INVITE_DATE_FORMAT = "EEEE, MMMM d, yyy, p";

export const CAROUSEL_CONFIG = {
  visibleSlides: 4,
  naturalSlideWidth: 100,
  naturalSlideHeight: 70,
  orientation: "horizontal" as const,
};

export const EVENTS_CAROUSEL_CONFIG = {
  naturalSlideWidth: 100,
  naturalSlideHeight: 100,
  orientation: "horizontal" as const,
  isIntrinsicHeight: true,
  touchEnabled: false,
  dragEnabled: false,
};

export const BANNER_CONFIG = {
  visibleSlides: 1,
  naturalSlideWidth: 100,
  naturalSlideHeight: 100,
  orientation: "horizontal" as const,
  infinite: true,
  isIntrinsicHeight: true,
  isPlaying: true,
};

export const VIDEO_CONFIG = {
  naturalSlideWidth: 100,
  naturalSlideHeight: 70,
  orientation: "horizontal" as const,
  infinite: true,
  isIntrinsicHeight: true,
};

export const USERS_CONFIG = {
  naturalSlideWidth: 100,
  naturalSlideHeight: 100,
  orientation: "horizontal" as const,
  infinite: false,
  isIntrinsicHeight: true,
  isPlaying: true,
};

export const TABLES_CONFIG = {
  naturalSlideWidth: 100,
  naturalSlideHeight: 100,
  orientation: "horizontal" as const,
  infinite: false,
  isIntrinsicHeight: true,
  isPlaying: false,
  dragEnabled: false,
};

export const TITLE_CHARACTERS_LIMIT = 65;
export const ARTICLE_TITLE_CHARACTERS_LIMIT = 70;
export const ARTICLE_TITLE_CHARACTERS_LIMIT_ON_CARD = 47;
export const DESCRIPTION_CHARACTERS_LIMIT = 120;

export const DEFAULT_OFFSET = 0;
export const PAGE_DEFAULT = 0;
export const ROWS_PER_PAGE_DEFAULT = 9;
export const MEMBERZINE_ROWS_PER_PAGE_DEFAULT = 12;
export const USERS_ROWS_PER_PAGE_DEFAULT = 12;
export const EVENTS_ROWS_PER_PAGE_DEFAULT = 12;

export const DefaultPageParam = "1";

export const CONTACT_US_CATEGORIES = new Map<ContactUsCategories, string>([
  [ContactUsCategories.GeneralQuestion, "General question"],
  [ContactUsCategories.MyAccount, "My account/payments"],
  [ContactUsCategories.TopicsAndLessons, "Topics & Lessons"],
  [ContactUsCategories.Events, "Events"],
  [ContactUsCategories.Feedback, "Feedback"],
  [ContactUsCategories.TechnicalSupport, "Technical Support (website problem)"],
]);

export const HOURS = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const EVENT_HOURS = [
  { label: "12:00 AM", value: "00:00" },
  { label: "12:30 AM", value: "00:30" },
  { label: "01:00 AM", value: "01:00" },
  { label: "01:30 AM", value: "01:30" },
  { label: "02:00 AM", value: "02:00" },
  { label: "02:30 AM", value: "02:30" },
  { label: "03:00 AM", value: "03:00" },
  { label: "03:30 AM", value: "03:30" },
  { label: "04:00 AM", value: "04:00" },
  { label: "04:30 AM", value: "04:30" },
  { label: "05:00 AM", value: "05:00" },
  { label: "05:30 AM", value: "05:30" },
  { label: "06:00 AM", value: "06:00" },
  { label: "06:30 AM", value: "06:30" },
  { label: "07:00 AM", value: "07:00" },
  { label: "07:30 AM", value: "07:30" },
  { label: "08:00 AM", value: "08:00" },
  { label: "08:30 AM", value: "08:30" },
  { label: "09:00 AM", value: "09:00" },
  { label: "09:30 AM", value: "09:30" },
  { label: "10:00 AM", value: "10:00" },
  { label: "10:30 AM", value: "10:30" },
  { label: "11:00 AM", value: "11:00" },
  { label: "11:30 AM", value: "11:30" },
  { label: "12:00 PM", value: "12:00" },
  { label: "12:30 PM", value: "12:30" },
  { label: "01:00 PM", value: "13:00" },
  { label: "01:30 PM", value: "13:30" },
  { label: "02:00 PM", value: "14:00" },
  { label: "02:30 PM", value: "14:30" },
  { label: "03:00 PM", value: "15:00" },
  { label: "03:30 PM", value: "15:30" },
  { label: "04:00 PM", value: "16:00" },
  { label: "04:30 PM", value: "16:30" },
  { label: "05:00 PM", value: "17:00" },
  { label: "05:30 PM", value: "17:30" },
  { label: "06:00 PM", value: "18:00" },
  { label: "06:30 PM", value: "18:30" },
  { label: "07:00 PM", value: "19:00" },
  { label: "07:30 PM", value: "19:30" },
  { label: "08:00 PM", value: "20:00" },
  { label: "08:30 PM", value: "20:30" },
  { label: "09:00 PM", value: "21:00" },
  { label: "09:30 PM", value: "21:30" },
  { label: "10:00 PM", value: "22:00" },
  { label: "10:30 PM", value: "22:30" },
  { label: "11:00 PM", value: "23:00" },
  { label: "11:30 PM", value: "23:30" },
];

export const DURATION = [
  { label: "15 min", value: 15 },
  { label: "30 min", value: 30 },
  { label: "45 min", value: 45 },
  { label: "1 hr", value: 60 },
  { label: "1.5 hr", value: 90 },
  { label: "2 hr", value: 120 },
];

export const UNLIMITED = -1;

export const DEBOUNCE_TIME = 250;

export const MONTHLY_PRICE = 39;
export const QUARTERLY_PRICE = 89;
export const ANNUAL_PRICE = 299;
export const SUPPORTER_PRICE = 5;

export const DEFAULT_MESSAGES = 0;

export enum MONTHS {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export const PORTAL_BUTTON = "portal-button";

export enum MembershipPlans {
  Free = "Free-one-month-plan-USD-Monthly",
  Monthly = "Community-Membership-USD-Monthly",
  Quarterly = "Community-Membership-USD-Quarterly",
  Annual = "Community-Membership-USD-Yearly",
  ThreeMonthsOnly = "Community-Membership-USD-3-months-only",
  SupporterOneMonth = "Supporter-Plan-USD-Monthly",
}

export enum SubscriptionTypes {
  Free = "free",
  Monthly = "monthly",
  Quarterly = "quarterly",
  Annual = "annual",
  ThreeMonthsOnly = "3-mths-only",
  Supporter = "supporter",
}

export enum ChargeTypes {
  EnglishForWork = "EFW-Part-2",
}

export const SubscriptionToMembershipPlanMap = new Map([
  [SubscriptionTypes.Free, MembershipPlans.Free],
  [SubscriptionTypes.Monthly, MembershipPlans.Monthly],
  [SubscriptionTypes.Quarterly, MembershipPlans.Quarterly],
  [SubscriptionTypes.Annual, MembershipPlans.Annual],
  [SubscriptionTypes.ThreeMonthsOnly, MembershipPlans.ThreeMonthsOnly],
  [SubscriptionTypes.Supporter, MembershipPlans.SupporterOneMonth],
]);

export const THREE_MONTHS_ONLY_COUPON = "SEWE3MFR";

export enum InvoiceDescription {
  Free = "As a friend of a Hey Lady! members, you can experience the benefits of a full membership for one month at no charge. You can decide to become a paying member at any time.",
  Monthly = "Hey Lady! Community Membership (Monthly Plan)",
  Quarterly = "Hey Lady! Community Membership (Quarterly Plan)",
  Annual = "Hey Lady! Community Membership (Annual Plan)",
  ThreeMonthsOnly = "Enjoy three(3) months access to everything we offer in the Hey Lady! platform and community.",
}

export enum PlanDescription {
  Free = "One month plan USD Monthly",
  Monthly = "Membership USD Monthly",
  Quarterly = "Membership USD Quarterly",
  Annual = "Membership USD Annual",
  ThreeMonthsOnly = "Community-Membership-USD 3 months only",
  SupporterOneMonth = "Supporter USD Monthly",
}

export interface EventsPageState {
  pageName: string;
  pageNumber: number;
  accessDenied?: boolean;
}

export const JOIN_URL = "https://www.heylady.io/join";

export const ENGLISH_CHARACTERS = /^[a-zA-Z0-9-\s]*$/;
export const SIGN_UP_PAGE_ENGLISH_CHARACTERS_ERROR = "SIGN_UP_PAGE_ENGLISH_CHARACTERS_ERROR";
export const INVALID_REFERRAL_CODE = "INVALID_REFERRAL_CODE";

export enum CreateSessionErrors {
  CANCELLED,
  UNVERIFIED_MAIL,
}

export const CreateSessionErrorsMessage = new Map<CreateSessionErrors, string>([
  [
    CreateSessionErrors.CANCELLED,
    "A user with a cancelled subscription tried to complete the signup.",
  ],
  [
    CreateSessionErrors.UNVERIFIED_MAIL,
    "A user with an unverified mail tried to complete the signup.",
  ],
]);

export const ENGLISH_LEVEL_MATCH = new Map<EnglishLevelMatch, number>([
  [EnglishLevelMatch.Same, 0],
  [EnglishLevelMatch.Similar, 1],
  [EnglishLevelMatch.Different, 2],
]);

export enum SignUpSteps {
  ELIGIBILITY = 1,
  ENGLISH_LEVEL = 2,
  CONFIRMATION = 3,
}

export enum ONBOARDING_STEPS {
  Start = "START",
  Name = "NAME",
  Location = "LOCATION",
  EnglishSkills = "ENGLISH_SKILLS",
  EnglishLevel = "ENGLISH_LEVEL",
  Occupation = "OCCUPATION",
  Interests = "INTERESTS",
  Story = "STORY",
  Photo = "PHOTO",
  Success = "SUCCESS",
}

export enum COMPLETE_PROFILE_ERRORS {
  RequiredField = "REQUIRED_FIELD_ERROR",
  Story = "STORY_ERROR",
  MinInterests = "MIN_INTERESTS_ERROR",
  MaxInterests = "MAX_INTERESTS_ERROR",
  MinSkills = "MIN_SKILLS_ERROR",
  MaxSkills = "MAX_SKILLS_ERROR",
  MinWords = "MIN_WORDS_ERROR",
  AddPhotoError = "ADD_PHOTO_ERROR",
  InvalidDate = "INVALID_DATE_ERROR",
}

/**
 * Chat constants
 */
export const ANNOUNCEMENTS_CHANNEL = "announcements:primary";
export const COMMUNITY_CHAT_CHANNEL = "public:community-chat";
export const NEW_MEMBERS_CHANNEL = "public:new-members";

export const PublicChannels = [ANNOUNCEMENTS_CHANNEL, COMMUNITY_CHAT_CHANNEL, NEW_MEMBERS_CHANNEL];

export const LESSONS_PATH_NAMES = new Map<LessonKind, string>([
  [LessonKind.Overview, "overview"],
  [LessonKind.Vocabulary, "vocabulary"],
  [LessonKind.DeepDive, "deep-dive"],
]);

export const GRID_COLUMNS_SIZE = new Map<GridColumns, GridSize[]>([
  [GridColumns.TwoEqualColumns, [GridSize.Half, GridSize.Half]],
  [GridColumns.TreeEqualColumns, [GridSize.OneThird, GridSize.OneThird, GridSize.OneThird]],
  [GridColumns.OneThirdTwoThirds, [GridSize.OneThird, GridSize.TwoThirds]],
  [GridColumns.TwoThirdsOneThird, [GridSize.TwoThirds, GridSize.OneThird]],
  [GridColumns.OneFourthThreeFourths, [GridSize.OneFourth, GridSize.ThreeFourths]],
  [GridColumns.ThreeFourthsOneFourth, [GridSize.ThreeFourths, GridSize.OneFourth]],
]);

export enum TopicsPages {
  CurrentTopic = "current-topic",
  TopicLibrary = "topic-library",
  GrammarLibrary = "grammar-library",
  Resources = "resources",
}

export enum HomeTourSteps {
  ExploreHomePage = "EXPLORE_HOME_PAGE",
  ChooseFirstEvent = "CHOOSE_FIRST_EVENT",
  WatchTrainingVideos = "WATCH_TRAINING_VIDEOS",
  ReadConversationPact = "READ_CONVERSATION_PACT",
  TakeTheQuiz = "TAKE_THE_QUIZ",
}

export const checkInputValidity = (value: string) => {
  return ENGLISH_CHARACTERS.test(value);
};

// TODO find a great place for these fiellds
export const COMPLETED_FIELDS = new Map<UserFields, string>([
  [UserFields.name, "ACCOUNT_PAGE_COMPLETED_NAME"],
  [UserFields.englishLevel, "ACCOUNT_PAGE_COMPLETED_ENGLISH_LEVEL"],
  [UserFields.location, "ACCOUNT_PAGE_COMPLETED_LOCATION"],
  [UserFields.interests, "ACCOUNT_PAGE_COMPLETED_INTERESTS"],
  [UserFields.englishSkills, "ACCOUNT_PAGE_COMPLETED_ENGLISH_SKILLS"],
  [UserFields.story, "ACCOUNT_PAGE_COMPLETED_OCCUPATION"],
  [UserFields.occupation, "ACCOUNT_PAGE_COMPLETED_STORY"],
  [UserFields.birthday, "ACCOUNT_PAGE_COMPLETED_BIRTHDATE"],
]);

export const MISSING_FIELDS = new Map<UserFields, string>([
  [UserFields.name, "ACCOUNT_PAGE_COMPLETED_NAME"],
  [UserFields.englishLevel, "ACCOUNT_PAGE_COMPLETED_ENGLISH_LEVEL"],
  [UserFields.location, "ACCOUNT_PAGE_COMPLETED_LOCATION"],
  [UserFields.interests, "ACCOUNT_PAGE_MISSING_INTERESTS"],
  [UserFields.englishSkills, "ACCOUNT_PAGE_MISSING_ENGLISH_SKILLS"],
  [UserFields.story, "ACCOUNT_PAGE_MISSING_STORY"],
  [UserFields.occupation, "ACCOUNT_PAGE_MISSING_OCCUPATION"],
  [UserFields.birthday, "ACCOUNT_PAGE_MISSING_BIRTHDATE"],
]);

export enum NeedPartner {
  Yes = "YES",
  No = "NO",
}

export const AVAILABILITY_DEFAULTS = [
  { from: "06:00 AM", until: "10:00 PM" },
  { from: "06:00 AM", until: "10:00 PM" },
];

export const WebinarEvents = [
  EventKind.GrammarSpot,
  EventKind.Interview,
  EventKind.OfficeHours,
  EventKind.LadiesLunch,
];
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_NOT_VALID_ERROR = "Please enter a valid email address.";
export const PASSWORD_ERROR_MIN_CHARS = "Password must be at least 8 characters long.";
