import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  Date: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload_Scalar: { input: any; output: any };
}

export interface AbuseModel {
  abuser?: Maybe<UserModel>;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  reason?: Maybe<UserReportReason>;
  updatedAt: Scalars["Date"]["output"];
  victim?: Maybe<UserModel>;
}

export interface ActivateUserInput {
  activationKey: Scalars["String"]["input"];
}

export interface AddEventCalendarInput {
  calendarType: CalendarType;
  eventID: Scalars["String"]["input"];
}

export interface AdminFilterBannersInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterCoversInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterEventsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdminFilterFeatureFlagsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterIllustrationsInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterReportsUserFilterInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterThemesInput {
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterTopicsInput {
  category?: InputMaybe<AdminTopicsCategories>;
  excludeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<AdminTopicsOrderBy>;
  sort?: InputMaybe<SortDirection>;
  status?: InputMaybe<TopicStatus>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdminFilterUsersInput {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  badges?: InputMaybe<Array<UserBadgeType>>;
  englishLevels?: InputMaybe<Array<EnglishLevel>>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
  interests?: InputMaybe<Array<Interest>>;
  kind?: InputMaybe<Array<EventPermissions>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  location?: InputMaybe<Array<UserLocation>>;
  occupations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  openToSpeakingPartners?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  order?: InputMaybe<SortDirection>;
  orderBy?: InputMaybe<AdminUsersOrderBy>;
  plan?: InputMaybe<Array<MembershipPlans>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionStatus?: InputMaybe<Array<SubscriptionStatus>>;
  verificationStatus?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
}

export interface AdminFilterVideoSetInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface AdminFilterZineCoversInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export enum AdminTopicsCategories {
  GrammarLibrary = "GrammarLibrary",
  Resource = "Resource",
  Standard = "Standard",
  TopicLibrary = "TopicLibrary",
}

export enum AdminTopicsOrderBy {
  LastUpdated = "lastUpdated",
  Theme = "theme",
  Title = "title",
}

export enum AdminUsersOrderBy {
  Country = "country",
  Date = "date",
  Email = "email",
  Name = "name",
}

export enum ArticleAction {
  Publish = "Publish",
}

export enum ArticleByAuthor {
  Any = "Any",
  Follow = "Follow",
  Heylady = "Heylady",
  Members = "Members",
}

export enum ArticleCategory {
  Any = "Any",
  Featured = "Featured",
  Interests = "Interests",
  Journey = "Journey",
  Liked = "Liked",
  Popular = "Popular",
  Recommended = "Recommended",
  Topics = "Topics",
}

export interface ArticleModel {
  attachments?: Maybe<Array<ArticlesAttachmentModel>>;
  author?: Maybe<UserModel>;
  comments?: Maybe<Array<CommentModel>>;
  content?: Maybe<Scalars["String"]["output"]>;
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  likedByMe?: Maybe<LikeModel>;
  likes?: Maybe<Array<LikeModel>>;
  myEnglishJourney?: Maybe<Scalars["Boolean"]["output"]>;
  noComments?: Maybe<Scalars["Int"]["output"]>;
  noLikes?: Maybe<Scalars["Int"]["output"]>;
  publishedAt?: Maybe<Scalars["Date"]["output"]>;
  reads: ReadArticleModel;
  scheduledFor?: Maybe<Scalars["Date"]["output"]>;
  tags?: Maybe<Array<Interest>>;
  theme?: Maybe<ArticleThemes>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface ArticleNotificationModel {
  article: ArticleModel;
  articleAction: ArticleAction;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export enum ArticleOrderBy {
  Published = "Published",
  Title = "Title",
}

export interface ArticleSearchResult {
  item: ArticleModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export enum ArticleThemes {
  ArtAndCulture = "ART_AND_CULTURE",
  CareerWomen = "CAREER_WOMEN",
  Community = "COMMUNITY",
  FamilyAndFriends = "FAMILY_AND_FRIENDS",
  FoodAndTraditions = "FOOD_AND_TRADITIONS",
  HealthAndWellness = "HEALTH_AND_WELLNESS",
  PersonalDevelopment = "PERSONAL_DEVELOPMENT",
  ScienceAndTechnology = "SCIENCE_AND_TECHNOLOGY",
  TheEnvironment = "THE_ENVIRONMENT",
  Travel = "TRAVEL",
}

export interface ArticlesAttachmentModel {
  article: ArticleModel;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  meta?: Maybe<MetaFileType>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export interface AssessmentResultContentRecord {
  question: Scalars["String"]["output"];
  recording: Scalars["String"]["output"];
}

export interface AssessmentResultModel {
  assessmentId: Scalars["String"]["output"];
  assessmentType: Scalars["String"]["output"];
  cefrScore: Scalars["String"]["output"];
  coherenceScore: Scalars["Float"]["output"];
  content?: Maybe<Array<AssessmentResultContentRecord>>;
  createdAt: Scalars["Date"]["output"];
  fluencyScore: Scalars["Float"]["output"];
  grammarScore: Scalars["Float"]["output"];
  halloScore: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  improvements?: Maybe<Array<Scalars["String"]["output"]>>;
  language: Scalars["String"]["output"];
  pronunciationScore: Scalars["Float"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
  vocabularyScore: Scalars["Float"]["output"];
}

export interface AssignOrDeleteBadgeInput {
  achievedDate?: InputMaybe<Scalars["Date"]["input"]>;
  type: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export enum AssistanceReasons {
  Assistance = "ASSISTANCE",
  HelpFindingEvents = "HELP_FINDING_EVENTS",
  HelpFindingPeople = "HELP_FINDING_PEOPLE",
  NeedMoreTime = "NEED_MORE_TIME",
  SomethingElse = "SOMETHING_ELSE",
}

export interface AssistanceReasonsInput {
  customReason?: InputMaybe<Scalars["String"]["input"]>;
  reason: Array<AssistanceReasons>;
}

export interface AttendEventInput {
  eventID: Scalars["String"]["input"];
}

export interface AttendanceMetrics {
  totalHostedEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedGroup?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedInPerson?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedOneToOnes?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedOther?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedScheduledEvents?: Maybe<Scalars["Int"]["output"]>;
  totalParticipatedWebinars?: Maybe<Scalars["Int"]["output"]>;
  totalParticipationDuration?: Maybe<Scalars["Int"]["output"]>;
  totalVideoCalls?: Maybe<Scalars["Int"]["output"]>;
}

export interface AttendanceRecord {
  attendanceRatio?: Maybe<Scalars["Float"]["output"]>;
  attended?: Maybe<Scalars["Int"]["output"]>;
  totalRsvps?: Maybe<Scalars["Int"]["output"]>;
}

export interface AttendedEvents {
  groupEvents?: Maybe<Scalars["Int"]["output"]>;
  inPersonEvents?: Maybe<Scalars["Int"]["output"]>;
  oneToOnes?: Maybe<Scalars["Int"]["output"]>;
  other?: Maybe<Scalars["Int"]["output"]>;
  webinars?: Maybe<Scalars["Int"]["output"]>;
}

export enum Audience {
  NewMembers = "NEW_MEMBERS",
  OneMonthPlus = "ONE_MONTH_PLUS",
  Zero = "ZERO",
}

export interface Availability {
  from: Scalars["String"]["output"];
  until: Scalars["String"]["output"];
}

export interface AvailabilityForUsersInput {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface AvailabilityInput {
  from: Scalars["String"]["input"];
  until: Scalars["String"]["input"];
}

export interface AvailabilityMatchForUsers {
  availabilityMatch?: Maybe<AvailabilityStatus>;
  userId: Scalars["String"]["output"];
}

export enum AvailabilityStatus {
  Different = "Different",
  Shared = "Shared",
}

export interface BadgeNotificationModel {
  badge: UserBadgeModel;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface BannerModel {
  audience?: Maybe<Array<EventPermissions>>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  sliders?: Maybe<Array<SliderModel>>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface BulkInviteResponse {
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface BulkInviteWithEmailsInput {
  emails: Array<Scalars["String"]["input"]>;
}

export interface BulkInviteWithUserIdsInput {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface CalendarResponseType {
  reason?: Maybe<GoogleApiMessage>;
  status: MutationStatus;
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum CalendarType {
  Google = "GOOGLE",
  Outlook = "OUTLOOK",
}

export interface CancelAttendanceInput {
  eventID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ChargeBeeAddress {
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  line1?: Maybe<Scalars["String"]["output"]>;
  line2?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zip?: Maybe<Scalars["String"]["output"]>;
}

export interface ChargeBeeAddressInput {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  line1?: InputMaybe<Scalars["String"]["input"]>;
  line2?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ChargebeeCustomerModel {
  cbAddress?: Maybe<ChargeBeeAddress>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  currency?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  oldId?: Maybe<Scalars["String"]["output"]>;
  paymentMethods: Array<ChargebeePaymentMethodModel>;
  promotionalCredits?: Maybe<Scalars["Int"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  subscriptions: Array<ChargebeeSubscriptionModel>;
  user: UserModel;
}

export interface ChargebeePaymentMethodModel {
  card?: Maybe<Scalars["JSON"]["output"]>;
  cbCustomerId?: Maybe<Scalars["String"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  cbObj?: Maybe<Scalars["JSON"]["output"]>;
  customer: ChargebeeCustomerModel;
  id: Scalars["String"]["output"];
  isPrimary?: Maybe<Scalars["Boolean"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
}

export interface ChargebeeSubscriptionModel {
  billingCycleAnchor?: Maybe<Scalars["Date"]["output"]>;
  cancelReason?: Maybe<Scalars["String"]["output"]>;
  cancelScheduleCreatedAt?: Maybe<Scalars["Date"]["output"]>;
  cancelledAt?: Maybe<Scalars["Date"]["output"]>;
  cbCustomerId?: Maybe<Scalars["String"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  cbPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  currentPeriodEnd?: Maybe<Scalars["Date"]["output"]>;
  currentPeriodStart?: Maybe<Scalars["Date"]["output"]>;
  customer: ChargebeeCustomerModel;
  id: Scalars["String"]["output"];
  plan?: Maybe<Scalars["JSON"]["output"]>;
  resourceVersion: Scalars["Date"]["output"];
  resumesAt?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export enum ChatChannelType {
  Messaging = "messaging",
  Public = "public",
}

export interface ChatMentionNotificationModel {
  actor?: Maybe<UserModel>;
  channelId: Scalars["String"]["output"];
  chatMessage?: Maybe<Scalars["String"]["output"]>;
  chatMessageId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  parentChatMessageId?: Maybe<Scalars["String"]["output"]>;
  user: UserModel;
}

export enum ColourCode {
  Blue = "BLUE",
  Green = "GREEN",
  Orange = "ORANGE",
  Pink = "PINK",
  Red = "RED",
}

export enum CommentAction {
  Comment = "Comment",
  Mention = "Mention",
}

export interface CommentModel {
  author?: Maybe<UserModel>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  parent?: Maybe<CommentModel>;
  parentID?: Maybe<Scalars["String"]["output"]>;
  reactions?: Maybe<Array<CommentReactionsModel>>;
  replies?: Maybe<Array<CommentModel>>;
  targetArticle?: Maybe<ArticleModel>;
  targetEvent?: Maybe<EventModel>;
  targetId: Scalars["String"]["output"];
  targetTopic?: Maybe<TopicModel>;
  targetType: CommentTargetType;
  updatedAt: Scalars["Date"]["output"];
}

export interface CommentNotificationModel {
  actor?: Maybe<UserModel>;
  comment: CommentModel;
  commentAction: CommentAction;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface CommentReactionsModel {
  comment: CommentModel;
  commentId: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  unifiedId: Scalars["String"]["output"];
  user: UserModel;
  userId: Scalars["String"]["output"];
}

export enum CommentTargetType {
  Article = "Article",
  Event = "Event",
  Topic = "Topic",
}

export interface CompletedVideoModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  user: UserModel;
  videoId: Scalars["String"]["output"];
}

export enum ContactUsCategories {
  Events = "EVENTS",
  Feedback = "FEEDBACK",
  GeneralQuestion = "GENERAL_QUESTION",
  MyAccount = "MY_ACCOUNT",
  TechnicalSupport = "TECHNICAL_SUPPORT",
  TopicsAndLessons = "TOPICS_AND_LESSONS",
}

export interface ContactUsInput {
  category: ContactUsCategories;
  email: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export enum ConversationKind {
  CasualConversation = "CASUAL_CONVERSATION",
  CustomConversation = "CUSTOM_CONVERSATION",
  DeepDive = "DEEP_DIVE",
  StudyGroup = "STUDY_GROUP",
}

export interface Coordinates {
  lat?: Maybe<Scalars["Float"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
}

export interface CoverModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  primary?: Maybe<ResponsiveType>;
  secondary?: Maybe<ResponsiveType>;
  tertiary?: Maybe<ResponsiveType>;
  theme?: Maybe<Array<ThemeModel>>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface CreateArticlesAttachmentInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MetaFileInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateBannerInput {
  title: Scalars["String"]["input"];
}

export interface CreateChannelInput {
  usersID?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface CreateCommentInput {
  mentions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message: Scalars["String"]["input"];
  parentID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateCoverInput {
  primary?: InputMaybe<ResponsiveInput>;
  secondary?: InputMaybe<ResponsiveInput>;
  tertiary?: InputMaybe<ResponsiveInput>;
  title: Scalars["String"]["input"];
}

export interface CreateEventAttachmentInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MetaFileInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateEventInput {
  attachments?: InputMaybe<Array<CreateEventAttachmentInput>>;
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdFromTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  customTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  invitePeople?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForConfidentUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  maxParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  peopleIDToInvite?: InputMaybe<Array<Scalars["String"]["input"]>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateFeatureFlagInput {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  label: Scalars["String"]["input"];
}

export interface CreateIllustrationInput {
  title: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateInviteInput {
  eventID: Scalars["String"]["input"];
  isConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHost?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  userID: Scalars["String"]["input"];
}

export interface CreateLessonInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  kind: LessonKind;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicID: Scalars["String"]["input"];
}

export interface CreateLikeInput {
  genericId: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateLiveTableInput {
  englishLevel?: InputMaybe<EventEnglishLevelNamed>;
  maxParticipants?: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateMessageInput {
  description: Scalars["String"]["input"];
  receiver_id: Scalars["String"]["input"];
}

export interface CreateReactionInput {
  commentId: Scalars["String"]["input"];
  unifiedId: Scalars["String"]["input"];
}

export interface CreateSectionInput {
  elements?: InputMaybe<Array<ElementInput>>;
  lessonID: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
}

export interface CreateSparkInput {
  eventId?: InputMaybe<Scalars["String"]["input"]>;
  liveTableId?: InputMaybe<Scalars["String"]["input"]>;
  videoCallId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreateThemeInput {
  isGrammarLibrary: Scalars["Boolean"]["input"];
  isResource: Scalars["Boolean"]["input"];
  isStandard: Scalars["Boolean"]["input"];
  isTopicLibrary: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateTopicInput {
  title: Scalars["String"]["input"];
}

export interface CreateUserFeatureFlagInput {
  featureFlagId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface CreateUserInput {
  email: Scalars["String"]["input"];
  englishLevel: Scalars["Int"]["input"];
  password: Scalars["String"]["input"];
  planParam: Scalars["String"]["input"];
}

export interface CreateVideoCallInput {
  channelType: ChatChannelType;
  chatRoomId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export interface CreateVideoInput {
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videoSetId: Scalars["String"]["input"];
}

export interface CreateVideoSetInput {
  colour: ColourCode;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
}

export interface CreateZineInput {
  coverUrl: Scalars["String"]["input"];
}

export interface CursorPaginatedNotifications {
  items: Array<NotificationBaseModel>;
  nextCursor?: Maybe<Scalars["Date"]["output"]>;
  total: Scalars["Int"]["output"];
}

export interface DailyMeetingInput {
  id: Scalars["String"]["input"];
  userID: Scalars["String"]["input"];
}

export interface DatetimeFilters {
  gt?: InputMaybe<Scalars["Date"]["input"]>;
  lt?: InputMaybe<Scalars["Date"]["input"]>;
}

export interface DeactivateInput {
  otherReason: Scalars["String"]["input"];
  reason: Array<UserDeactivationReason>;
}

export interface DeclineInviteInput {
  inviteID?: InputMaybe<Scalars["String"]["input"]>;
}

export enum DeepDiveKind {
  RolePlay = "ROLE_PLAY",
  Roundtable = "ROUNDTABLE",
  ShareAndCompare = "SHARE_AND_COMPARE",
  SolveAProblem = "SOLVE_A_PROBLEM",
}

export interface DeleteInvitesInput {
  eventId: Scalars["String"]["input"];
  isHost: Scalars["Boolean"]["input"];
}

export interface DeletePhotoInput {
  id: Scalars["String"]["input"];
}

export interface DeleteTopicsInput {
  topicIds: Array<Scalars["String"]["input"]>;
}

export enum DisplayedStatistics {
  ArticlesWritten = "ArticlesWritten",
  EventsHosted = "EventsHosted",
  ParticipationTime = "ParticipationTime",
}

export interface DuplicateLessonInput {
  lessonId: Scalars["String"]["input"];
  topicId: Scalars["String"]["input"];
}

export interface EditArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditArticlesAttachmentInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface EditBannerInput {
  audience?: InputMaybe<Array<EventPermissions>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditCommentInput {
  message: Scalars["String"]["input"];
}

export interface EditCoverInput {
  primary?: InputMaybe<ResponsiveInput>;
  secondary?: InputMaybe<ResponsiveInput>;
  tertiary?: InputMaybe<ResponsiveInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditEventInput {
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  englishLevel?: InputMaybe<EventEnglishLevel>;
  eventCategory?: InputMaybe<EventCategory>;
  eventLessonKind?: InputMaybe<EventLessonKind>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForUnverified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["Any"]["input"]>;
  noParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  permissions?: InputMaybe<Array<EventPermissions>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditFeatureFlagInput {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  label: Scalars["String"]["input"];
}

export interface EditIllustrationInput {
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditLessonInput {
  deepDiveKind?: InputMaybe<DeepDiveKind>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  kind?: InputMaybe<LessonKind>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditSectionInput {
  elements?: InputMaybe<Array<ElementInput>>;
  lessonID?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface EditSliderInput {
  background?: InputMaybe<ResponsiveInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonURL?: InputMaybe<Scalars["String"]["input"]>;
  featureImage?: InputMaybe<Scalars["String"]["input"]>;
  imageAlignment?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  textColour?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleStyle?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditThemeInput {
  coverId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditTopicInput {
  illustrationId?: InputMaybe<Scalars["String"]["input"]>;
  stars?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TopicStatus>;
  themeId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditUserFeatureFlagInput {
  featureFlagId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface EditUserInput {
  availability?: InputMaybe<Array<AvailabilityInput>>;
  avatarUrl?: InputMaybe<Scalars["String"]["input"]>;
  badge?: InputMaybe<UserBadge>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  cambridgeEnglishLevel?: InputMaybe<EnglishLevel>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  extraConversations?: InputMaybe<Scalars["Int"]["input"]>;
  extraHostedEvents?: InputMaybe<Scalars["Int"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAgeVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHeyLady?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventPermissions>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  nativeLanguage?: InputMaybe<Scalars["String"]["input"]>;
  needSpeakingPartner?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationId?: InputMaybe<Scalars["String"]["input"]>;
  otherLanguage?: InputMaybe<Scalars["String"]["input"]>;
  pauseCredits?: InputMaybe<Scalars["Int"]["input"]>;
  showBirthday?: InputMaybe<Scalars["Boolean"]["input"]>;
  showBirthdayYear?: InputMaybe<Scalars["Boolean"]["input"]>;
  story?: InputMaybe<Scalars["String"]["input"]>;
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface EditVideoInput {
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videoSetID?: InputMaybe<Scalars["String"]["input"]>;
  videoURL?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditVideoSetInput {
  audience?: InputMaybe<Array<Audience>>;
  colour?: InputMaybe<ColourCode>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface EditZineInput {
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface Element {
  kind: ElementKind;
  meta: Scalars["JSON"]["output"];
  value?: Maybe<Scalars["Any"]["output"]>;
}

export interface ElementInput {
  kind: ElementKind;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  value?: InputMaybe<Scalars["Any"]["input"]>;
}

export enum ElementKind {
  Attachment = "ATTACHMENT",
  Banner = "BANNER",
  Button = "BUTTON",
  Divider = "DIVIDER",
  Embedded = "EMBEDDED",
  Grid = "GRID",
  Ideas = "IDEAS",
  Image = "IMAGE",
  ImageAndButton = "IMAGE_AND_BUTTON",
  Layout = "LAYOUT",
  Links = "LINKS",
  Paragraph = "PARAGRAPH",
  Questions = "QUESTIONS",
  Spacing = "SPACING",
  Title = "TITLE",
  Video = "VIDEO",
}

export enum EmailNotifications {
  Digest = "DIGEST",
  Inactive = "INACTIVE",
  Spot = "SPOT",
}

export enum EnglishLevel {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export enum EnglishLevelMatch {
  Different = "Different",
  Same = "Same",
  Similar = "Similar",
}

export enum EnglishSkill {
  GivingAPresentation = "GIVING_A_PRESENTATION",
  InterviewOrBeInterviewed = "INTERVIEW_OR_BE_INTERVIEWED",
  IntroduceMyself = "INTRODUCE_MYSELF",
  MeetNewPeople = "MEET_NEW_PEOPLE",
  NegotiateAndDebate = "NEGOTIATE_AND_DEBATE",
  PassAnEnglishExam = "PASS_AN_ENGLISH_EXAM",
  PersonalNetworking = "PERSONAL_NETWORKING",
  Pronunciation = "PRONUNCIATION",
  SpeakConfidentlyAtWork = "SPEAK_CONFIDENTLY_AT_WORK",
  ThinkInEnglish = "THINK_IN_ENGLISH",
  UnderstandMoviesAndMusic = "UNDERSTAND_MOVIES_AND_MUSIC",
  UnderstandNativeSpeakers = "UNDERSTAND_NATIVE_SPEAKERS",
  WriteCreatively = "WRITE_CREATIVELY",
  WriteProfessionally = "WRITE_PROFESSIONALLY",
}

export interface EntityIdResponseType {
  id: Scalars["String"]["output"];
}

export interface EventAttachmentModel {
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  event: EventModel;
  id: Scalars["String"]["output"];
  meta?: Maybe<MetaFileType>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum EventAvailabilityFilter {
  AnyTime = "ANY_TIME",
  AvailableWhenIAm = "AVAILABLE_WHEN_I_AM",
}

export enum EventCategory {
  Conversation = "CONVERSATION",
  None = "NONE",
  Professional = "PROFESSIONAL",
  StudyAndLearn = "STUDY_AND_LEARN",
}

export enum EventEnglishLevel {
  Advanced = "ADVANCED",
  Everyone = "EVERYONE",
  Intermediate = "INTERMEDIATE",
}

export enum EventEnglishLevelFilter {
  Advanced = "ADVANCED",
  All = "ALL",
  AnyLevel = "ANY_LEVEL",
  Intermediate = "INTERMEDIATE",
}

export enum EventEnglishLevelNamed {
  Advanced = "ADVANCED",
  AnyLevel = "ANY_LEVEL",
  Intermediate = "INTERMEDIATE",
}

export enum EventHostedByFilter {
  Anyone = "ANYONE",
  BigSisters = "BIG_SISTERS",
  HlCoach = "HL_COACH",
  HlMembers = "HL_MEMBERS",
  HlTeam = "HL_TEAM",
  PeopleIFollow = "PEOPLE_I_FOLLOW",
}

export enum EventKind {
  CasualConversation = "CASUAL_CONVERSATION",
  ConversationStarter = "CONVERSATION_STARTER",
  DeepDive = "DEEP_DIVE",
  GeneralEvent = "GENERAL_EVENT",
  GrammarSpot = "GRAMMAR_SPOT",
  GroupEvent = "GROUP_EVENT",
  Interview = "INTERVIEW",
  InPerson = "IN_PERSON",
  LadiesLunch = "LADIES_LUNCH",
  NewMemberMeetup = "NEW_MEMBER_MEETUP",
  OfficeHours = "OFFICE_HOURS",
  Offline = "OFFLINE",
  OneToOne = "ONE_TO_ONE",
  Online = "ONLINE",
  TeacherTalk = "TEACHER_TALK",
}

export enum EventLessonKind {
  DeepDive = "DEEP_DIVE",
  Overview = "OVERVIEW",
  Vocabulary = "VOCABULARY",
}

export interface EventModel {
  attachments?: Maybe<Array<EventAttachmentModel>>;
  closedDoorAt?: Maybe<Scalars["Date"]["output"]>;
  cohosts: Array<UserModel>;
  comments?: Maybe<Array<CommentModel>>;
  conversationKind?: Maybe<ConversationKind>;
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  createdFromTopic?: Maybe<Scalars["Boolean"]["output"]>;
  customTopic?: Maybe<Scalars["Boolean"]["output"]>;
  declined: Array<UserModel>;
  description?: Maybe<Scalars["String"]["output"]>;
  duplicateOf?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  englishLevel?: Maybe<EventEnglishLevel>;
  englishSkills?: Maybe<Array<EnglishSkill>>;
  eventCategory?: Maybe<EventCategory>;
  eventLessonKind?: Maybe<EventLessonKind>;
  host?: Maybe<UserModel>;
  id: Scalars["String"]["output"];
  ideasToDiscuss?: Maybe<Array<Scalars["String"]["output"]>>;
  illustrationUrl?: Maybe<Scalars["String"]["output"]>;
  interests?: Maybe<Array<Interest>>;
  invited: Array<UserModel>;
  invites?: Maybe<Array<InviteModel>>;
  isConfirmed?: Maybe<Scalars["Boolean"]["output"]>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isForConfidentUsers?: Maybe<Scalars["Boolean"]["output"]>;
  isForFoundation?: Maybe<Scalars["Boolean"]["output"]>;
  isForRise?: Maybe<Scalars["Boolean"]["output"]>;
  isForUnverified?: Maybe<Scalars["Boolean"]["output"]>;
  isFull?: Maybe<Scalars["Boolean"]["output"]>;
  isOnline?: Maybe<Scalars["Boolean"]["output"]>;
  isPublic?: Maybe<Scalars["Boolean"]["output"]>;
  kind?: Maybe<EventKind>;
  likes?: Maybe<Array<LikeModel>>;
  links?: Maybe<Array<Link>>;
  location?: Maybe<Scalars["String"]["output"]>;
  locationId?: Maybe<Scalars["String"]["output"]>;
  locationLat?: Maybe<Scalars["Float"]["output"]>;
  locationLong?: Maybe<Scalars["Float"]["output"]>;
  maxParticipants?: Maybe<Scalars["Int"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  noParticipants?: Maybe<Scalars["Int"]["output"]>;
  occupations?: Maybe<Array<OccupationModel>>;
  organizer?: Maybe<UserModel>;
  participants: Array<UserModel>;
  permissions?: Maybe<Array<EventPermissions>>;
  recurring?: Maybe<Scalars["Boolean"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  topic?: Maybe<TopicModel>;
  updatedAt: Scalars["Date"]["output"];
  videoUrl?: Maybe<Scalars["String"]["output"]>;
}

export enum EventNotificationAction {
  Cohosting = "Cohosting",
  Delete = "Delete",
  FollowingIsHosting = "FollowingIsHosting",
  HostRemoveCohost = "HostRemoveCohost",
  HostRemoveParticipant = "HostRemoveParticipant",
  InApp15MinBeforeEvent = "InApp15MinBeforeEvent",
  Invite = "Invite",
  OneToOneNoResponse = "OneToOneNoResponse",
  SharedEvent = "SharedEvent",
  Update = "Update",
  UserAccept = "UserAccept",
  UserDecline = "UserDecline",
}

export interface EventNotificationModel {
  action: EventNotificationAction;
  actor?: Maybe<UserModel>;
  event: EventModel;
  id: Scalars["String"]["output"];
  invite?: Maybe<InviteModel>;
  user: UserModel;
}

export enum EventPermissions {
  BigSister = "BIG_SISTER",
  HeyLadyAdmin = "HEY_LADY_ADMIN",
  HeyLadyTeam = "HEY_LADY_TEAM",
  InactiveMember = "INACTIVE_MEMBER",
  MoreThanTwelveMonths = "MORE_THAN_TWELVE_MONTHS",
  OneToThree = "ONE_TO_THREE",
  ThreeToTwelve = "THREE_TO_TWELVE",
  ZeroToOne = "ZERO_TO_ONE",
}

export enum EventTypeFilter {
  AllEventTypes = "ALL_EVENT_TYPES",
  Conversation = "CONVERSATION",
  DeepDive = "DEEP_DIVE",
  InPerson = "IN_PERSON",
  ListenOnly = "LISTEN_ONLY",
  NewMemberMeetup = "NEW_MEMBER_MEETUP",
  Professional = "PROFESSIONAL",
  StudyAndLearn = "STUDY_AND_LEARN",
}

export enum EventsOrderBy {
  Created = "CREATED",
  EndDate = "END_DATE",
  InvitedFirst = "INVITED_FIRST",
  StartDate = "START_DATE",
  Updated = "UPDATED",
}

export interface EventsPerDay {
  count: Scalars["Int"]["output"];
  day: Scalars["Date"]["output"];
}

export interface FeatureFlagModel {
  active?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  users: Array<UserFlagModel>;
}

export interface FilterArticlesInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  byAuthor?: InputMaybe<ArticleByAuthor>;
  category?: InputMaybe<ArticleCategory>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FilterPaginatedEventInput {
  availability?: InputMaybe<EventAvailabilityFilter>;
  duration?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  englishLevel?: InputMaybe<EventEnglishLevelFilter>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  eventDate?: InputMaybe<Scalars["Date"]["input"]>;
  hostId?: InputMaybe<Scalars["String"]["input"]>;
  hostedBy?: InputMaybe<EventHostedByFilter>;
  interests?: InputMaybe<Array<Interest>>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPast?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  matchEnglishLevel?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  organizerId?: InputMaybe<Scalars["String"]["input"]>;
  showFullEvents?: InputMaybe<Scalars["Boolean"]["input"]>;
  showTopPicks?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<EventTypeFilter>;
  userInviteStatus?: InputMaybe<UserInviteStatus>;
  usersDayLight?: InputMaybe<Scalars["Boolean"]["input"]>;
  withinOneWeek?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface FilterThemesInput {
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGrammarLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResource?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTopicLibrary?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface FilterTopicsInput {
  excludeTheme?: InputMaybe<Scalars["String"]["input"]>;
  includeTheme?: InputMaybe<Scalars["String"]["input"]>;
}

export interface FilterUsersInput {
  availability?: InputMaybe<Array<AvailabilityStatus>>;
  englishLevels?: InputMaybe<Array<EnglishLevel>>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<Array<EventPermissions>>;
  lastOnlineAt?: InputMaybe<DatetimeFilters>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  location?: InputMaybe<Array<UserLocation>>;
  occupations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface FollowInput {
  followingId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface FollowerModel {
  createdAt: Scalars["Date"]["output"];
  follower: UserModel;
  following: UserModel;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface FriendInput {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
}

export interface FriendJoinedNotificationModel {
  friend: UserModel;
  id: Scalars["String"]["output"];
  type: FriendJoinedNotificationType;
  user: UserModel;
}

export enum FriendJoinedNotificationType {
  AcceptedInvite = "AcceptedInvite",
  FriendJoined = "FriendJoined",
}

export interface FriendlyFaces {
  attended?: Maybe<Scalars["Int"]["output"]>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
}

export interface GenericResponseType {
  reason?: Maybe<Scalars["String"]["output"]>;
  status: MutationStatus;
}

export enum GoogleApiMessage {
  CalendarAlreadyAdded = "CALENDAR_ALREADY_ADDED",
  CalendarNotFound = "CALENDAR_NOT_FOUND",
  EventAlreadyAdded = "EVENT_ALREADY_ADDED",
  EventNotFound = "EVENT_NOT_FOUND",
  InvalidGrant = "INVALID_GRANT",
  InviteNotFound = "INVITE_NOT_FOUND",
  Success = "SUCCESS",
  Unauthorized = "UNAUTHORIZED",
}

export interface GoogleCalendarModel {
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  etag?: Maybe<Scalars["String"]["output"]>;
  googleId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface GoogleEventModel {
  createdAt: Scalars["Date"]["output"];
  endDate?: Maybe<Scalars["String"]["output"]>;
  etag?: Maybe<Scalars["String"]["output"]>;
  event?: Maybe<EventModel>;
  googleCalendar?: Maybe<GoogleCalendarModel>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  hostID?: Maybe<Scalars["String"]["output"]>;
  iCalUID?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  invite: InviteModel;
  kind?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  organizerID?: Maybe<Scalars["String"]["output"]>;
  reminders?: Maybe<Scalars["JSON"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface IllustrationModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  topics?: Maybe<Array<TopicModel>>;
  updatedAt: Scalars["Date"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
}

export enum ImageAlignment {
  Left = "Left",
  Right = "Right",
}

export interface ImportUserInput {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  extraConversations?: InputMaybe<Scalars["Int"]["input"]>;
  extraHostedEvents?: InputMaybe<Scalars["Int"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventPermissions>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  priceId?: InputMaybe<Scalars["String"]["input"]>;
  trialDays?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface Industry {
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  occupations: Array<OccupationModel>;
}

export interface IndustryInput {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  occupations: Array<OccupationInput>;
}

export enum Interest {
  AnimalsAndWildlife = "ANIMALS_AND_WILDLIFE",
  ArtsAndCrafts = "ARTS_AND_CRAFTS",
  BeautyAndFashion = "BEAUTY_AND_FASHION",
  BusinessAndEntrepreneurship = "BUSINESS_AND_ENTREPRENEURSHIP",
  CareersAndProfessionalDevelopment = "CAREERS_AND_PROFESSIONAL_DEVELOPMENT",
  Design = "DESIGN",
  DifferentCultures = "DIFFERENT_CULTURES",
  Economics = "ECONOMICS",
  EnvironmentalIssues = "ENVIRONMENTAL_ISSUES",
  Equality = "EQUALITY",
  FamilyAndMotherhood = "FAMILY_AND_MOTHERHOOD",
  FeminismAndEmpowerment = "FEMINISM_AND_EMPOWERMENT",
  FoodAndCooking = "FOOD_AND_COOKING",
  Games = "GAMES",
  GoalsSettingAndSuccess = "GOALS_SETTING_AND_SUCCESS",
  HealthAndWellness = "HEALTH_AND_WELLNESS",
  Hiking = "HIKING",
  History = "HISTORY",
  HomeAndGardening = "HOME_AND_GARDENING",
  Ielts = "IELTS",
  Languages = "LANGUAGES",
  Leadership = "LEADERSHIP",
  LivingAbroad = "LIVING_ABROAD",
  LoveAndRelationships = "LOVE_AND_RELATIONSHIPS",
  MoviesAndTvShows = "MOVIES_AND_TV_SHOWS",
  MusicAndDance = "MUSIC_AND_DANCE",
  NatureAndTheEnvironment = "NATURE_AND_THE_ENVIRONMENT",
  PersonalDevelopment = "PERSONAL_DEVELOPMENT",
  Photography = "PHOTOGRAPHY",
  Politics = "POLITICS",
  Psychology = "PSYCHOLOGY",
  Reading = "READING",
  ScienceAndTechnology = "SCIENCE_AND_TECHNOLOGY",
  Spirituality = "SPIRITUALITY",
  Sports = "SPORTS",
  SustainableLiving = "SUSTAINABLE_LIVING",
  TheatreAndPerformance = "THEATRE_AND_PERFORMANCE",
  Travel = "TRAVEL",
  VideoGames = "VIDEO_GAMES",
  VolunteeringAndCharity = "VOLUNTEERING_AND_CHARITY",
  Writing = "WRITING",
}

export interface InviteModel {
  createdAt: Scalars["Date"]["output"];
  event: EventModel;
  googleEvent: GoogleEventModel;
  id: Scalars["String"]["output"];
  isConfirmed?: Maybe<Scalars["Boolean"]["output"]>;
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  isHost?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<InviteStatus>;
  updatedAt: Scalars["Date"]["output"];
  user?: Maybe<UserModel>;
}

export enum InviteStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Initial = "Initial",
}

export interface InvitedFriendsStats {
  invitedFriendsTotal?: Maybe<Scalars["Int"]["output"]>;
  invitedPaidFriends?: Maybe<Scalars["Int"]["output"]>;
}

export interface Invoice {
  amount?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  last4Digits?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
}

export enum LessonKind {
  Custom = "CUSTOM",
  DeepDive = "DEEP_DIVE",
  Overview = "OVERVIEW",
  Vocabulary = "VOCABULARY",
}

export interface LessonModel {
  createdAt: Scalars["Date"]["output"];
  deepDiveKind?: Maybe<DeepDiveKind>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  kind: LessonKind;
  sections?: Maybe<Array<SectionModel>>;
  title?: Maybe<Scalars["String"]["output"]>;
  topic: TopicModel;
  updatedAt: Scalars["Date"]["output"];
}

export interface LikeModel {
  article: ArticleModel;
  event: EventModel;
  genericId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  topic: TopicModel;
  user: UserModel;
}

export interface Link {
  description: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
}

export interface LinkInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface LiveTableModel {
  createdAt: Scalars["Date"]["output"];
  creatorId?: Maybe<Scalars["String"]["output"]>;
  dailyRoomId: Scalars["String"]["output"];
  endedAt?: Maybe<Scalars["Date"]["output"]>;
  englishLevel?: Maybe<EventEnglishLevelNamed>;
  id: Scalars["String"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastSingleUserAt?: Maybe<Scalars["Date"]["output"]>;
  maxParticipants: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface LiveTableStartedMessage {
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  tableCreatorId: Scalars["String"]["output"];
  tableId: Scalars["String"]["output"];
  tableTitle: Scalars["String"]["output"];
}

export interface LiveTableStartedMessageInput {
  tableId: Scalars["String"]["input"];
  tableTitle: Scalars["String"]["input"];
}

export interface LiveTablesFeedbackInput {
  comment: Scalars["String"]["input"];
  rating: Scalars["Int"]["input"];
  willUseAgain: Scalars["Int"]["input"];
}

export interface LiveTablesParticipant {
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export interface LiveTablesResponse {
  participants?: Maybe<Array<LiveTablesParticipant>>;
  table: LiveTableModel;
}

export interface MatchDetails {
  availabilityMatch?: Maybe<AvailabilityStatus>;
  commonEnglishSkills?: Maybe<Array<EnglishSkill>>;
  commonInterests?: Maybe<Array<Interest>>;
  englishLevelMatch?: Maybe<EnglishLevelMatch>;
  occupationMatch?: Maybe<OccupationMatch>;
  sameCity?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface MatchScores {
  availabilityScore: Scalars["Float"]["output"];
  englishInterestScore: Scalars["Float"]["output"];
  englishLevelScore: Scalars["Float"]["output"];
  eventParticipationScore: Scalars["Float"]["output"];
  interestScore: Scalars["Float"]["output"];
  locationScore: Scalars["Float"]["output"];
  occupationScore: Scalars["Float"]["output"];
}

export interface MeetingTokenResponse {
  exp?: Maybe<Scalars["Int"]["output"]>;
  roomName: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
}

export enum MembershipPlans {
  Annual = "Annual",
  FreeOneMonth = "FreeOneMonth",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SupporterOneMonth = "SupporterOneMonth",
  ThreeMonthsOnly = "ThreeMonthsOnly",
}

export interface MessageModel {
  bodyText: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  receiver: UserModel;
  sender: UserModel;
  updatedAt: Scalars["Date"]["output"];
}

export interface MetaFileInput {
  extension?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface MetaFileType {
  extension?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
}

export enum MonthlyRecapSteps {
  ArticlesWritten = "ArticlesWritten",
  AttendanceRatio = "AttendanceRatio",
  EventsAttended = "EventsAttended",
  EventsHosted = "EventsHosted",
  EventsStreak = "EventsStreak",
  FriendlyFaces = "FriendlyFaces",
  GlobalNetwork = "GlobalNetwork",
  ParticipationTime = "ParticipationTime",
  Sparks = "Sparks",
  SpontaneousConversations = "SpontaneousConversations",
  ThankYou = "ThankYou",
}

export interface MonthlyReportNotificationModel {
  id: Scalars["String"]["output"];
  type: MonthlyReportNotificationType;
}

export enum MonthlyReportNotificationType {
  MonthlyReport = "MonthlyReport",
}

export interface Mutation {
  activateTrialSubscription: GenericResponseType;
  activateUser?: Maybe<GenericResponseType>;
  addEventToGoogleCalendar: CalendarResponseType;
  addZoomLicenseToUser?: Maybe<GenericResponseType>;
  adminBanUser?: Maybe<GenericResponseType>;
  adminCreateBanner?: Maybe<BannerModel>;
  adminCreateCover?: Maybe<CoverModel>;
  adminCreateFeatureFlag?: Maybe<FeatureFlagModel>;
  adminCreateIllustration?: Maybe<IllustrationModel>;
  adminCreateLesson?: Maybe<LessonModel>;
  adminCreateNote?: Maybe<NoteModel>;
  adminCreateSection?: Maybe<SectionModel>;
  adminCreateSlider?: Maybe<GenericResponseType>;
  adminCreateTheme?: Maybe<ThemeModel>;
  adminCreateTopic?: Maybe<TopicModel>;
  adminCreateUserFeatureFlag?: Maybe<UserFlagModel>;
  adminCreateVideo?: Maybe<GenericResponseType>;
  adminCreateVideoSet?: Maybe<VideoSetModel>;
  adminCreateZineCover?: Maybe<ZineModel>;
  adminDeleteArticle?: Maybe<GenericResponseType>;
  adminDeleteBanner?: Maybe<GenericResponseType>;
  adminDeleteCover?: Maybe<GenericResponseType>;
  adminDeleteEvent?: Maybe<GenericResponseType>;
  adminDeleteFeatureFlag?: Maybe<GenericResponseType>;
  adminDeleteIllustration?: Maybe<GenericResponseType>;
  adminDeleteLesson?: Maybe<GenericResponseType>;
  adminDeleteSection?: Maybe<GenericResponseType>;
  adminDeleteSlider?: Maybe<GenericResponseType>;
  adminDeleteTheme?: Maybe<GenericResponseType>;
  adminDeleteTopic?: Maybe<GenericResponseType>;
  adminDeleteTopics?: Maybe<GenericResponseType>;
  adminDeleteUserFeatureFlag?: Maybe<GenericResponseType>;
  adminDeleteUserReport?: Maybe<GenericResponseType>;
  adminDeleteVideo?: Maybe<GenericResponseType>;
  adminDeleteVideoSet?: Maybe<GenericResponseType>;
  adminDeleteZineCover?: Maybe<GenericResponseType>;
  adminEditArticle?: Maybe<ArticleModel>;
  adminEditBanner?: Maybe<BannerModel>;
  adminEditCover?: Maybe<CoverModel>;
  adminEditEvent?: Maybe<GenericResponseType>;
  adminEditFeatureFlag?: Maybe<FeatureFlagModel>;
  adminEditIllustration?: Maybe<IllustrationModel>;
  adminEditLesson?: Maybe<LessonModel>;
  adminEditSection?: Maybe<SectionModel>;
  adminEditSlide?: Maybe<SliderModel>;
  adminEditTheme?: Maybe<ThemeModel>;
  adminEditTopic?: Maybe<TopicModel>;
  adminEditUser?: Maybe<GenericResponseType>;
  adminEditUserFeatureFlag?: Maybe<UserFlagModel>;
  adminEditVideo?: Maybe<VideoModel>;
  adminEditVideoSet?: Maybe<VideoSetModel>;
  adminEditZineCover?: Maybe<ZineModel>;
  adminGenerateAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  adminImportUser?: Maybe<GenericResponseType>;
  adminOrderSlider?: Maybe<GenericResponseType>;
  adminOrderVideo?: Maybe<GenericResponseType>;
  adminOrderVideoSet?: Maybe<GenericResponseType>;
  adminRepublishEvent: GenericResponseType;
  adminUnbanUser?: Maybe<GenericResponseType>;
  adminWelcomeUser?: Maybe<GenericResponseType>;
  assignAllExtraReferralBadges: GenericResponseType;
  assignCompleteOnboardingQuizBadge: GenericResponseType;
  assignUserBadge?: Maybe<GenericResponseType>;
  attendEvent?: Maybe<InviteModel>;
  bulkInviteWithUserIds?: Maybe<BulkInviteResponse>;
  cancelAttendance: InviteModel;
  cancelScheduleCancelSubscription?: Maybe<GenericResponseType>;
  chatAddMembersToChannel?: Maybe<GenericResponseType>;
  chatCreateChannel?: Maybe<Scalars["Any"]["output"]>;
  chatGiveAdminRole?: Maybe<Scalars["Any"]["output"]>;
  chatRemoveMembersFromChannel?: Maybe<GenericResponseType>;
  chatRenameChannel?: Maybe<GenericResponseType>;
  chatSendDirectMessage?: Maybe<GenericResponseType>;
  chooseSubscription?: Maybe<Scalars["JSON"]["output"]>;
  closeDoor?: Maybe<EventModel>;
  closeVideoCallDoor: VideoCallModel;
  completeVideo?: Maybe<CompletedVideoModel>;
  confirmInPersonEvent: GenericResponseType;
  contactUs?: Maybe<GenericResponseType>;
  createAbuse?: Maybe<AbuseModel>;
  createArticle?: Maybe<ArticleModel>;
  createArticleAttachment?: Maybe<ArticlesAttachmentModel>;
  createAttachment?: Maybe<EventAttachmentModel>;
  createComment?: Maybe<CommentModel>;
  createEvent?: Maybe<EventModel>;
  createInvite: InviteModel;
  createLike?: Maybe<LikeModel>;
  createLiveTable: LiveTableModel;
  createMessage?: Maybe<MessageModel>;
  createReaction: CommentReactionsModel;
  createSpark: GenericResponseType;
  createUser: UserSignUpModel;
  createUserPhoto?: Maybe<UserPhotoModel>;
  createVideoCall: VideoCallModel;
  deactivate?: Maybe<GenericResponseType>;
  declineInvite: InviteModel;
  deleteAccount?: Maybe<GenericResponseType>;
  deleteArticle?: Maybe<EntityIdResponseType>;
  deleteArticleAttachment?: Maybe<EntityIdResponseType>;
  deleteAttachment?: Maybe<EntityIdResponseType>;
  deleteComment?: Maybe<GenericResponseType>;
  deleteEvent?: Maybe<GenericResponseType>;
  deleteInvite?: Maybe<GenericResponseType>;
  deleteInvites?: Maybe<GenericResponseType>;
  deleteLike?: Maybe<GenericResponseType>;
  deleteReaction: GenericResponseType;
  deleteUserBadge?: Maybe<GenericResponseType>;
  deleteUserPhoto?: Maybe<EntityIdResponseType>;
  duplicateEvent?: Maybe<EventModel>;
  duplicateLesson?: Maybe<GenericResponseType>;
  duplicateTopic?: Maybe<GenericResponseType>;
  editArticle?: Maybe<ArticleModel>;
  editComment?: Maybe<CommentModel>;
  eventMeetingToken?: Maybe<MeetingTokenResponse>;
  follow?: Maybe<GenericResponseType>;
  generateArticlesAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  generateAttachmentUploadUrl?: Maybe<UploadUrlsType>;
  generateGroupPictureUploadUrl?: Maybe<UploadUrlsType>;
  generateUserUploadUrl?: Maybe<UploadUrlsType>;
  getHostedPage?: Maybe<Scalars["JSON"]["output"]>;
  getPrecancelPage?: Maybe<Scalars["String"]["output"]>;
  heartbeat?: Maybe<GenericResponseType>;
  initiateAssessment: Scalars["String"]["output"];
  issueCompleteProfileNotification: GenericResponseType;
  liveTableMeetingToken: MeetingTokenResponse;
  markAllAsSeen?: Maybe<GenericResponseType>;
  markJoinEvent: GenericResponseType;
  markReportAsSeen?: Maybe<GenericResponseType>;
  notificationSeen?: Maybe<NotificationBaseModel>;
  notifyAboutStartedLiveTable: GenericResponseType;
  openDoor?: Maybe<EventModel>;
  openVideoCallDoor: VideoCallModel;
  pause: GenericResponseType;
  publishEvent?: Maybe<EventModel>;
  publishOrScheduleArticle?: Maybe<ArticleModel>;
  recoverAccount?: Maybe<GenericResponseType>;
  requestAssistance?: Maybe<GenericResponseType>;
  requestRefund?: Maybe<GenericResponseType>;
  requestResetPassword: GenericResponseType;
  resendConfirmationEmail: GenericResponseType;
  resetPassword?: Maybe<GenericResponseType>;
  restoreUsers?: Maybe<GenericResponseType>;
  resume: GenericResponseType;
  saveArticle?: Maybe<ArticleModel>;
  saveParticipantSession: GenericResponseType;
  scheduleCancelSubscription?: Maybe<GenericResponseType>;
  seenCompletedBasics?: Maybe<GenericResponseType>;
  sendLink?: Maybe<GenericResponseType>;
  sendLiveTablesFeedback: GenericResponseType;
  setGroupPictureUrl?: Maybe<GenericResponseType>;
  setToken: GenericResponseType;
  shareEvent: GenericResponseType;
  subscribe?: Maybe<Scalars["JSON"]["output"]>;
  switch?: Maybe<Scalars["JSON"]["output"]>;
  triggerAssignAllUserBadges: GenericResponseType;
  triggerBackupEventsParticipants: GenericResponseType;
  triggerChatServiceSync?: Maybe<GenericResponseType>;
  triggerRecreateDailyMeetings: GenericResponseType;
  triggerRecreateZoomMeetings: GenericResponseType;
  triggerUserDeletionAfterRetention: GenericResponseType;
  triggerUserPermissionPromotion: GenericResponseType;
  unFollow?: Maybe<GenericResponseType>;
  updateArticleAttachment?: Maybe<ArticlesAttachmentModel>;
  updateAttachment?: Maybe<EventAttachmentModel>;
  updateEvent?: Maybe<EventModel>;
  updateLiveTable: GenericResponseType;
  updateMe?: Maybe<UserModel>;
  updatePassword: GenericResponseType;
  updatePaymentMethod?: Maybe<Scalars["String"]["output"]>;
  updateUserPhoto?: Maybe<UserPhotoModel>;
  updateUserSettings?: Maybe<GenericResponseType>;
  uploadActiveDays: GenericResponseType;
  uploadArticleCover?: Maybe<ArticleModel>;
  uploadArticleImage?: Maybe<UploadUrlsType>;
  uploadEventDescriptionImage?: Maybe<UploadUrlsType>;
  uploadZineCover?: Maybe<ZineModel>;
  validateForSelectionBulkInvite: ValidateSelectionBulkInviteResponse;
  validateUsersForEmailBulkInvite?: Maybe<ValidateBulkInviteResponse>;
  verifyUser?: Maybe<GenericResponseType>;
  videoMeetingToken: MeetingTokenResponse;
}

export interface MutationActivateUserArgs {
  input: ActivateUserInput;
}

export interface MutationAddEventToGoogleCalendarArgs {
  input: AddEventCalendarInput;
}

export interface MutationAddZoomLicenseToUserArgs {
  userID: Scalars["String"]["input"];
}

export interface MutationAdminBanUserArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminCreateBannerArgs {
  input: CreateBannerInput;
}

export interface MutationAdminCreateCoverArgs {
  input: CreateCoverInput;
}

export interface MutationAdminCreateFeatureFlagArgs {
  input: CreateFeatureFlagInput;
}

export interface MutationAdminCreateIllustrationArgs {
  input: CreateIllustrationInput;
}

export interface MutationAdminCreateLessonArgs {
  input: CreateLessonInput;
}

export interface MutationAdminCreateNoteArgs {
  input: NoteInput;
  userID: Scalars["String"]["input"];
}

export interface MutationAdminCreateSectionArgs {
  input: CreateSectionInput;
}

export interface MutationAdminCreateSliderArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminCreateThemeArgs {
  input: CreateThemeInput;
}

export interface MutationAdminCreateTopicArgs {
  input: CreateTopicInput;
}

export interface MutationAdminCreateUserFeatureFlagArgs {
  input: CreateUserFeatureFlagInput;
}

export interface MutationAdminCreateVideoArgs {
  input: CreateVideoInput;
}

export interface MutationAdminCreateVideoSetArgs {
  input: CreateVideoSetInput;
}

export interface MutationAdminCreateZineCoverArgs {
  input: CreateZineInput;
}

export interface MutationAdminDeleteArticleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteBannerArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteCoverArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteIllustrationArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteLessonArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteSectionArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteSliderArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteThemeArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteTopicArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteTopicsArgs {
  input: DeleteTopicsInput;
}

export interface MutationAdminDeleteUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteUserReportArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteVideoArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteVideoSetArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminDeleteZineCoverArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminEditArticleArgs {
  id: Scalars["String"]["input"];
  input: EditArticleInput;
}

export interface MutationAdminEditBannerArgs {
  id: Scalars["String"]["input"];
  input: EditBannerInput;
}

export interface MutationAdminEditCoverArgs {
  id: Scalars["String"]["input"];
  input: EditCoverInput;
}

export interface MutationAdminEditEventArgs {
  id: Scalars["String"]["input"];
  input: EditEventInput;
}

export interface MutationAdminEditFeatureFlagArgs {
  id: Scalars["String"]["input"];
  input: EditFeatureFlagInput;
}

export interface MutationAdminEditIllustrationArgs {
  id: Scalars["String"]["input"];
  input: EditIllustrationInput;
}

export interface MutationAdminEditLessonArgs {
  id: Scalars["String"]["input"];
  input: EditLessonInput;
}

export interface MutationAdminEditSectionArgs {
  id: Scalars["String"]["input"];
  input: EditSectionInput;
}

export interface MutationAdminEditSlideArgs {
  id: Scalars["String"]["input"];
  input: EditSliderInput;
}

export interface MutationAdminEditThemeArgs {
  id: Scalars["String"]["input"];
  input: EditThemeInput;
}

export interface MutationAdminEditTopicArgs {
  id: Scalars["String"]["input"];
  input: EditTopicInput;
}

export interface MutationAdminEditUserArgs {
  id: Scalars["String"]["input"];
  input: EditUserInput;
}

export interface MutationAdminEditUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
  input: EditUserFeatureFlagInput;
}

export interface MutationAdminEditVideoArgs {
  id: Scalars["String"]["input"];
  input: EditVideoInput;
}

export interface MutationAdminEditVideoSetArgs {
  id: Scalars["String"]["input"];
  input: EditVideoSetInput;
}

export interface MutationAdminEditZineCoverArgs {
  id: Scalars["String"]["input"];
  input: EditZineInput;
}

export interface MutationAdminGenerateAttachmentUploadUrlArgs {
  filename: Scalars["String"]["input"];
}

export interface MutationAdminImportUserArgs {
  input: ImportUserInput;
}

export interface MutationAdminOrderSliderArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminOrderVideoArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminOrderVideoSetArgs {
  id: Scalars["String"]["input"];
  input: Scalars["String"]["input"];
}

export interface MutationAdminRepublishEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface MutationAdminUnbanUserArgs {
  id: Scalars["String"]["input"];
}

export interface MutationAdminWelcomeUserArgs {
  input: ImportUserInput;
}

export interface MutationAssignUserBadgeArgs {
  input: AssignOrDeleteBadgeInput;
}

export interface MutationAttendEventArgs {
  input: AttendEventInput;
}

export interface MutationBulkInviteWithUserIdsArgs {
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithUserIdsInput;
}

export interface MutationCancelAttendanceArgs {
  input: CancelAttendanceInput;
}

export interface MutationChatAddMembersToChannelArgs {
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}

export interface MutationChatCreateChannelArgs {
  input: CreateChannelInput;
}

export interface MutationChatGiveAdminRoleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationChatRemoveMembersFromChannelArgs {
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}

export interface MutationChatRenameChannelArgs {
  channelName: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
}

export interface MutationChatSendDirectMessageArgs {
  input: SendDirectChatMessageInput;
}

export interface MutationChooseSubscriptionArgs {
  input: SubscribeInput;
}

export interface MutationCloseDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationCloseVideoCallDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationCompleteVideoArgs {
  id: Scalars["String"]["input"];
}

export interface MutationConfirmInPersonEventArgs {
  id: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
}

export interface MutationContactUsArgs {
  input: ContactUsInput;
}

export interface MutationCreateAbuseArgs {
  input: ReportAbuseInput;
}

export interface MutationCreateArticleAttachmentArgs {
  articleId: Scalars["String"]["input"];
  input: CreateArticlesAttachmentInput;
}

export interface MutationCreateAttachmentArgs {
  eventID: Scalars["String"]["input"];
  input: CreateEventAttachmentInput;
}

export interface MutationCreateCommentArgs {
  input: CreateCommentInput;
  targetId: Scalars["String"]["input"];
  targetType: CommentTargetType;
}

export interface MutationCreateEventArgs {
  input: CreateEventInput;
}

export interface MutationCreateInviteArgs {
  input: CreateInviteInput;
}

export interface MutationCreateLikeArgs {
  input: CreateLikeInput;
}

export interface MutationCreateLiveTableArgs {
  input: CreateLiveTableInput;
}

export interface MutationCreateMessageArgs {
  writeMessageData: CreateMessageInput;
}

export interface MutationCreateReactionArgs {
  input: CreateReactionInput;
}

export interface MutationCreateSparkArgs {
  input: CreateSparkInput;
}

export interface MutationCreateUserArgs {
  input: CreateUserInput;
}

export interface MutationCreateUserPhotoArgs {
  input: UpdatePhotoInput;
}

export interface MutationCreateVideoCallArgs {
  input: CreateVideoCallInput;
}

export interface MutationDeactivateArgs {
  input: DeactivateInput;
}

export interface MutationDeclineInviteArgs {
  input: DeclineInviteInput;
}

export interface MutationDeleteAccountArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteArticleArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteArticleAttachmentArgs {
  attachmentId: Scalars["String"]["input"];
}

export interface MutationDeleteAttachmentArgs {
  attachmentID: Scalars["String"]["input"];
}

export interface MutationDeleteCommentArgs {
  commentID: Scalars["String"]["input"];
}

export interface MutationDeleteEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteInviteArgs {
  inviteId: Scalars["String"]["input"];
}

export interface MutationDeleteInvitesArgs {
  input: DeleteInvitesInput;
}

export interface MutationDeleteLikeArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteReactionArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDeleteUserBadgeArgs {
  input: AssignOrDeleteBadgeInput;
}

export interface MutationDeleteUserPhotoArgs {
  input: DeletePhotoInput;
}

export interface MutationDuplicateEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationDuplicateLessonArgs {
  input: DuplicateLessonInput;
}

export interface MutationDuplicateTopicArgs {
  id: Scalars["String"]["input"];
}

export interface MutationEditArticleArgs {
  id: Scalars["String"]["input"];
  input: EditArticleInput;
}

export interface MutationEditCommentArgs {
  commentID: Scalars["String"]["input"];
  input: EditCommentInput;
}

export interface MutationEventMeetingTokenArgs {
  input: DailyMeetingInput;
}

export interface MutationFollowArgs {
  input: FollowInput;
}

export interface MutationGenerateArticlesAttachmentUploadUrlArgs {
  articleId: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface MutationGenerateAttachmentUploadUrlArgs {
  eventID: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface MutationGenerateGroupPictureUploadUrlArgs {
  input: UploadPictureInput;
}

export interface MutationGenerateUserUploadUrlArgs {
  input: UploadPhotoInput;
}

export interface MutationGetHostedPageArgs {
  id: Scalars["String"]["input"];
}

export interface MutationHeartbeatArgs {
  input: UpdateUserInput;
}

export interface MutationLiveTableMeetingTokenArgs {
  input: DailyMeetingInput;
}

export interface MutationMarkJoinEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface MutationNotificationSeenArgs {
  id: Scalars["String"]["input"];
}

export interface MutationNotifyAboutStartedLiveTableArgs {
  input: LiveTableStartedMessageInput;
}

export interface MutationOpenDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationOpenVideoCallDoorArgs {
  id: Scalars["String"]["input"];
}

export interface MutationPublishEventArgs {
  id: Scalars["String"]["input"];
}

export interface MutationPublishOrScheduleArticleArgs {
  id: Scalars["String"]["input"];
  input: PublishArticleInput;
}

export interface MutationRequestAssistanceArgs {
  input: AssistanceReasonsInput;
}

export interface MutationRequestRefundArgs {
  input: RequestRefundInput;
}

export interface MutationRequestResetPasswordArgs {
  input: RequestPasswordInput;
}

export interface MutationResendConfirmationEmailArgs {
  input: ResendConfirmationEmailInput;
}

export interface MutationResetPasswordArgs {
  input: ResetPasswordInput;
}

export interface MutationRestoreUsersArgs {
  userIds: Array<Scalars["String"]["input"]>;
}

export interface MutationSaveArticleArgs {
  id: Scalars["String"]["input"];
  input: SaveDraftArticleInput;
}

export interface MutationSaveParticipantSessionArgs {
  meetingSessionId: Scalars["String"]["input"];
  participantId: Scalars["String"]["input"];
}

export interface MutationScheduleCancelSubscriptionArgs {
  input: RequestRefundInput;
}

export interface MutationSendLinkArgs {
  input: FriendInput;
}

export interface MutationSendLiveTablesFeedbackArgs {
  input: LiveTablesFeedbackInput;
}

export interface MutationSetGroupPictureUrlArgs {
  input: SetPictureInput;
}

export interface MutationSetTokenArgs {
  input: Scalars["String"]["input"];
}

export interface MutationShareEventArgs {
  eventId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]>;
}

export interface MutationSubscribeArgs {
  input: SubscribeInput;
}

export interface MutationSwitchArgs {
  input: SubscribeInput;
}

export interface MutationUnFollowArgs {
  input: FollowInput;
}

export interface MutationUpdateArticleAttachmentArgs {
  attachmentId: Scalars["String"]["input"];
  input: EditArticlesAttachmentInput;
}

export interface MutationUpdateAttachmentArgs {
  attachmentID: Scalars["String"]["input"];
  input: UpdateEventAttachmentInput;
}

export interface MutationUpdateEventArgs {
  id: Scalars["String"]["input"];
  input: UpdateEventInput;
}

export interface MutationUpdateLiveTableArgs {
  id: Scalars["String"]["input"];
  input: UpdateLiveTableInput;
}

export interface MutationUpdateMeArgs {
  input: UpdateUserInput;
}

export interface MutationUpdatePasswordArgs {
  input: UpdatePasswordInput;
}

export interface MutationUpdateUserPhotoArgs {
  input: UpdatePhotoInput;
  photoID: Scalars["String"]["input"];
}

export interface MutationUpdateUserSettingsArgs {
  input: UpdateSettingsInput;
}

export interface MutationUploadActiveDaysArgs {
  input: UploadActiveDaysInput;
}

export interface MutationUploadArticleCoverArgs {
  input: UploadImageArticleInput;
}

export interface MutationUploadArticleImageArgs {
  input: UploadImageArticleInput;
}

export interface MutationUploadEventDescriptionImageArgs {
  id: Scalars["String"]["input"];
  input: UploadEventImageInput;
}

export interface MutationUploadZineCoverArgs {
  input: UploadCoverZineInput;
}

export interface MutationValidateForSelectionBulkInviteArgs {
  eventId: Scalars["String"]["input"];
  filters: AdminFilterUsersInput;
}

export interface MutationValidateUsersForEmailBulkInviteArgs {
  eventId: Scalars["String"]["input"];
  input: BulkInviteWithEmailsInput;
}

export interface MutationVerifyUserArgs {
  id: Scalars["String"]["input"];
  input: VerifyUserInput;
}

export interface MutationVideoMeetingTokenArgs {
  input: DailyMeetingInput;
}

export enum MutationStatus {
  Failed = "FAILED",
  Success = "SUCCESS",
}

export interface NoteInput {
  description: Scalars["String"]["input"];
}

export interface NoteModel {
  createdAt: Scalars["Date"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface NotificationBaseModel {
  articleNotification?: Maybe<ArticleNotificationModel>;
  badgeNotification?: Maybe<BadgeNotificationModel>;
  chatMentionNotification?: Maybe<ChatMentionNotificationModel>;
  commentNotification?: Maybe<CommentNotificationModel>;
  createdAt: Scalars["Date"]["output"];
  eventNotification?: Maybe<EventNotificationModel>;
  friendJoinedNotification?: Maybe<FriendJoinedNotificationModel>;
  id: Scalars["String"]["output"];
  monthlyReportNotification?: Maybe<MonthlyReportNotificationModel>;
  notificationSeen?: Maybe<Array<NotificationSeenModel>>;
  profileUpdateNotification?: Maybe<ProfileUpdateNotificationModel>;
  type: NotificationType;
  updatedAt: Scalars["Date"]["output"];
}

export interface NotificationCursorPaginatedInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  maxDate?: InputMaybe<Scalars["Date"]["input"]>;
}

export interface NotificationSeenModel {
  id: Scalars["String"]["output"];
  seenAt?: Maybe<Scalars["Date"]["output"]>;
  user: UserModel;
}

export enum NotificationType {
  Article = "Article",
  Badge = "Badge",
  ChatMention = "ChatMention",
  Comment = "Comment",
  Event = "Event",
  FriendJoined = "FriendJoined",
  MonthlyReport = "MonthlyReport",
  ProfileUpdate = "ProfileUpdate",
}

export interface OccupationInput {
  id: Scalars["String"]["input"];
  industry?: InputMaybe<IndustryInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export enum OccupationMatch {
  Different = "Different",
  Same = "Same",
  SharedIndustry = "SharedIndustry",
}

export interface OccupationModel {
  id: Scalars["String"]["output"];
  industry?: Maybe<Industry>;
  name?: Maybe<Scalars["String"]["output"]>;
}

export enum Onboarding {
  Availability = "AVAILABILITY",
  Avatar = "AVATAR",
  Complete = "COMPLETE",
  EnglishLevel = "ENGLISH_LEVEL",
  EnglishSkills = "ENGLISH_SKILLS",
  Interests = "INTERESTS",
  Location = "LOCATION",
  Name = "NAME",
  Occupation = "OCCUPATION",
  Story = "STORY",
}

export interface OrderArticlesInput {
  by?: InputMaybe<ArticleOrderBy>;
  sortAsc?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface OrderEventInput {
  by?: InputMaybe<EventsOrderBy>;
  sort?: InputMaybe<SortDirection>;
}

export interface PaginatedArticles {
  items?: Maybe<Array<ArticleModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedAssessmentResult {
  items?: Maybe<Array<AssessmentResultModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedAssessmentResultInput {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PaginatedBanners {
  items?: Maybe<Array<BannerModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedCovers {
  items?: Maybe<Array<CoverModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedEvent {
  items?: Maybe<Array<EventModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedFeatureFlags {
  items?: Maybe<Array<FeatureFlagModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedIllustrations {
  items?: Maybe<Array<IllustrationModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedMatchedUser {
  items?: Maybe<Array<UserMatchingModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedReportsUser {
  items?: Maybe<Array<AbuseModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedThemes {
  items?: Maybe<Array<ThemeModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedTopics {
  items?: Maybe<Array<TopicModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedUser {
  items?: Maybe<Array<UserModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedVideoSets {
  items?: Maybe<Array<VideoSetModel>>;
  total: Scalars["Int"]["output"];
}

export interface PaginatedZines {
  items?: Maybe<Array<ZineModel>>;
  total: Scalars["Int"]["output"];
}

export enum ParticipationStatus {
  Attending = "Attending",
  Declined = "Declined",
  Invited = "Invited",
  NotInvited = "NotInvited",
}

export interface ParticipationStatusInput {
  eventId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
}

export interface ParticipationStatusResult {
  status: ParticipationStatus;
}

export interface PauseResponse {
  canPause: Scalars["Boolean"]["output"];
  pauseCredits: Scalars["Int"]["output"];
}

export interface PaymentMethod {
  email?: Maybe<Scalars["String"]["output"]>;
  expMonth?: Maybe<Scalars["Int"]["output"]>;
  expYear?: Maybe<Scalars["Int"]["output"]>;
  last4Digits?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
}

export interface ProfileUpdateNotificationModel {
  id: Scalars["String"]["output"];
  type: ProfileUpdateNotificationType;
}

export enum ProfileUpdateNotificationType {
  CompleteProfile = "CompleteProfile",
  UpdateAvailability = "UpdateAvailability",
}

export interface PublishArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface Query {
  adminGetArticle: ArticleModel;
  adminGetBanner: BannerModel;
  adminGetCover: CoverModel;
  adminGetFeatureFlag: FeatureFlagModel;
  adminGetGroupedOccupations: Array<OccupationModel>;
  adminGetIllustration: IllustrationModel;
  adminGetLesson: LessonModel;
  adminGetOccupations: Array<OccupationModel>;
  adminGetPlanDetails: CbPlanDetails;
  adminGetSelectedUsers: Array<UserModel>;
  adminGetSlide: SliderModel;
  adminGetTheme: ThemeModel;
  adminGetTopic: TopicModel;
  adminGetTopicByLessonId: TopicModel;
  adminGetUserFeatureFlag: UserFlagModel;
  adminGetVideoSet?: Maybe<VideoSetModel>;
  adminGetZineCover: ZineModel;
  adminListCovers: Array<CoverModel>;
  adminListIllustrations: Array<IllustrationModel>;
  adminListPaginatedArticles: PaginatedArticles;
  adminListPaginatedBanners: PaginatedBanners;
  adminListPaginatedCovers: PaginatedCovers;
  adminListPaginatedFeatureFlags: PaginatedFeatureFlags;
  adminListPaginatedIllustrations: PaginatedIllustrations;
  adminListPaginatedReportsUsers: PaginatedReportsUser;
  adminListPaginatedThemes: PaginatedThemes;
  adminListPaginatedTopics: PaginatedTopics;
  adminListPaginatedVideoSets: PaginatedVideoSets;
  adminListPaginatedZineCovers: PaginatedZines;
  adminListThemes: Array<ThemeModel>;
  adminListTopics: Array<TopicModel>;
  adminListUserFeatureFlags: Array<UserFlagModel>;
  adminListUsers: PaginatedUser;
  adminSearchEvents: PaginatedEvent;
  articlesWritten: Scalars["Int"]["output"];
  attendanceRecord: AttendanceRecord;
  attendedEvents: AttendedEvents;
  canPause: PauseResponse;
  chatCreateUserToken: Scalars["Any"]["output"];
  communityDistinctSparks: Scalars["Int"]["output"];
  completedVerificationForm: Array<VideoAskModel>;
  dailyEvents: Array<EventsPerDay>;
  friendlyFaces: Array<FriendlyFaces>;
  getArticle: ArticleModel;
  getAvalibilityMatchForUsers: Array<AvailabilityMatchForUsers>;
  getChannelVideoCall?: Maybe<VideoCallModel>;
  getComment?: Maybe<CommentModel>;
  getCurrentBanner?: Maybe<BannerModel>;
  getCurrentZine?: Maybe<ZineModel>;
  getEvent: EventModel;
  getEventAdmin?: Maybe<EventModel>;
  getGroupedOccupations: Array<OccupationModel>;
  getInvitedFriendsCount: Scalars["Int"]["output"];
  getInvitedFriendsStats: InvitedFriendsStats;
  getLiveTableById: LiveTableModel;
  getLiveTables: Array<LiveTablesResponse>;
  getOccupations: Array<OccupationModel>;
  getParticipationStatus: ParticipationStatusResult;
  getPopupNotifications: Array<NotificationBaseModel>;
  getTheme: ThemeModel;
  getTopic: TopicModel;
  getTotalDistinctSparks: Scalars["Int"]["output"];
  getUser: UserModel;
  getUserAdmin?: Maybe<UserModel>;
  getUserByReferralCode: UserByReferralCodeResponse;
  getUserMatchingInfo: UserMatchingModel;
  getUserSettings: UserSettingsModel;
  getUserSignUp: UserSignUpModel;
  getVideoCall: VideoCallModel;
  getVideos: Array<VideoModel>;
  globalNetwork: Array<Coordinates>;
  hostedEvents: Scalars["Int"]["output"];
  invoices?: Maybe<Array<Invoice>>;
  ladiesAttended: Scalars["Int"]["output"];
  listArticles: PaginatedArticles;
  listAttachment: Array<EventAttachmentModel>;
  listMatchedUsers: PaginatedMatchedUser;
  listMessages: Array<MessageModel>;
  listNotificationsForUser: CursorPaginatedNotifications;
  listPaginatedAssessmentResults: PaginatedAssessmentResult;
  listPaginatedEvents: PaginatedEvent;
  listRecommendedEvents: Array<EventModel>;
  listRecommendedUsers: Array<UserMatchingModel>;
  listThemes: Array<ThemeModel>;
  listTopics: Array<TopicModel>;
  listUserPhotos: Array<UserPhotoModel>;
  me: UserModel;
  monthlyRecapSteps: Array<MonthlyRecapSteps>;
  participationTime: Scalars["Int"]["output"];
  paymentMethod?: Maybe<Array<PaymentMethod>>;
  planDetails?: Maybe<CbPlanDetails>;
  searchMemberzine: Array<ArticleSearchResult>;
  searchTopics: Array<TopicSearchResult>;
  searchUsers: Array<UserSearchResult>;
  seenMonthlyReport: Scalars["Boolean"]["output"];
  userAttendedDistinctSparks: Scalars["Int"]["output"];
  userDistinctSparks: Scalars["Int"]["output"];
  userGaveSparkInEvent: Scalars["Boolean"]["output"];
  userHasNoAcceptedInvites: Scalars["Boolean"]["output"];
  userHostedDistinctSparks: Scalars["Int"]["output"];
  userHostedEvents: PaginatedEvent;
  userHostedEventsToShareCount: Scalars["Int"]["output"];
}

export interface QueryAdminGetArticleArgs {
  articleID: Scalars["String"]["input"];
}

export interface QueryAdminGetBannerArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetCoverArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetIllustrationArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetLessonArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetPlanDetailsArgs {
  userId: Scalars["String"]["input"];
}

export interface QueryAdminGetSelectedUsersArgs {
  filters: AdminFilterUsersInput;
}

export interface QueryAdminGetSlideArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetThemeArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetTopicByLessonIdArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetUserFeatureFlagArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetVideoSetArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminGetZineCoverArgs {
  id: Scalars["String"]["input"];
}

export interface QueryAdminListPaginatedArticlesArgs {
  filters: FilterArticlesInput;
  order: OrderArticlesInput;
}

export interface QueryAdminListPaginatedBannersArgs {
  filters: AdminFilterBannersInput;
}

export interface QueryAdminListPaginatedCoversArgs {
  filters: AdminFilterCoversInput;
}

export interface QueryAdminListPaginatedFeatureFlagsArgs {
  filters: AdminFilterFeatureFlagsInput;
}

export interface QueryAdminListPaginatedIllustrationsArgs {
  filters: AdminFilterIllustrationsInput;
}

export interface QueryAdminListPaginatedReportsUsersArgs {
  filters: AdminFilterReportsUserFilterInput;
}

export interface QueryAdminListPaginatedThemesArgs {
  filters: AdminFilterThemesInput;
}

export interface QueryAdminListPaginatedTopicsArgs {
  filters: AdminFilterTopicsInput;
}

export interface QueryAdminListPaginatedVideoSetsArgs {
  filters: AdminFilterVideoSetInput;
}

export interface QueryAdminListPaginatedZineCoversArgs {
  filters: AdminFilterZineCoversInput;
}

export interface QueryAdminListUsersArgs {
  filters: AdminFilterUsersInput;
}

export interface QueryAdminSearchEventsArgs {
  filters: AdminFilterEventsInput;
}

export interface QueryArticlesWrittenArgs {
  input: StatisticsInput;
}

export interface QueryAttendanceRecordArgs {
  input: StatisticsInput;
}

export interface QueryAttendedEventsArgs {
  input: StatisticsInput;
}

export interface QueryCommunityDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryDailyEventsArgs {
  input: StatisticsInput;
}

export interface QueryFriendlyFacesArgs {
  input: StatisticsInput;
}

export interface QueryGetArticleArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetAvalibilityMatchForUsersArgs {
  input: AvailabilityForUsersInput;
}

export interface QueryGetChannelVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetCommentArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetEventArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetEventAdminArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetInvitedFriendsStatsArgs {
  userId: Scalars["String"]["input"];
}

export interface QueryGetLiveTableByIdArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetParticipationStatusArgs {
  input: ParticipationStatusInput;
}

export interface QueryGetThemeArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetTopicArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserAdminArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserByReferralCodeArgs {
  codeId: Scalars["String"]["input"];
}

export interface QueryGetUserMatchingInfoArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetUserSignUpArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGetVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface QueryGlobalNetworkArgs {
  input: StatisticsInput;
}

export interface QueryHostedEventsArgs {
  input: StatisticsInput;
}

export interface QueryLadiesAttendedArgs {
  input: StatisticsInput;
}

export interface QueryListArticlesArgs {
  filters: FilterArticlesInput;
}

export interface QueryListAttachmentArgs {
  eventID: Scalars["String"]["input"];
}

export interface QueryListMatchedUsersArgs {
  filters: FilterUsersInput;
}

export interface QueryListNotificationsForUserArgs {
  cursor: NotificationCursorPaginatedInput;
  unreadOnly: Scalars["Boolean"]["input"];
}

export interface QueryListPaginatedAssessmentResultsArgs {
  filters: PaginatedAssessmentResultInput;
}

export interface QueryListPaginatedEventsArgs {
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}

export interface QueryListThemesArgs {
  filters: FilterThemesInput;
}

export interface QueryListTopicsArgs {
  filters: FilterTopicsInput;
}

export interface QueryListUserPhotosArgs {
  userID: Scalars["String"]["input"];
}

export interface QueryMonthlyRecapStepsArgs {
  input: StatisticsInput;
}

export interface QueryParticipationTimeArgs {
  input: StatisticsInput;
}

export interface QuerySearchMemberzineArgs {
  query: Scalars["String"]["input"];
}

export interface QuerySearchTopicsArgs {
  query: Scalars["String"]["input"];
}

export interface QuerySearchUsersArgs {
  eventId?: InputMaybe<Scalars["String"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  query: Scalars["String"]["input"];
}

export interface QueryUserAttendedDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserGaveSparkInEventArgs {
  eventId: Scalars["String"]["input"];
}

export interface QueryUserHostedDistinctSparksArgs {
  input: StatisticsInput;
}

export interface QueryUserHostedEventsArgs {
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}

export interface ReadArticleModel {
  article: ArticleModel;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export enum RefundReasons {
  Assistance = "ASSISTANCE",
  Custom = "CUSTOM",
  HelpFindingEvents = "HELP_FINDING_EVENTS",
  HelpFindingPeople = "HELP_FINDING_PEOPLE",
  NeedMoreTime = "NEED_MORE_TIME",
  SomethingElse = "SOMETHING_ELSE",
}

export interface ReportAbuseInput {
  abuserID: Scalars["String"]["input"];
  articleId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  reason: UserReportReason;
}

export interface RequestPasswordInput {
  email: Scalars["String"]["input"];
}

export interface RequestRefundInput {
  customReason?: InputMaybe<Scalars["String"]["input"]>;
  reason: Array<RefundReasons>;
}

export interface ResendConfirmationEmailInput {
  email: Scalars["String"]["input"];
}

export interface ResetPasswordInput {
  newPassword: Scalars["String"]["input"];
  resetToken: Scalars["String"]["input"];
}

export interface ResponsiveInput {
  desktop?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ResponsiveType {
  desktop?: Maybe<Scalars["String"]["output"]>;
  mobile?: Maybe<Scalars["String"]["output"]>;
}

export interface SaveDraftArticleInput {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  myEnglishJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["Date"]["input"]>;
  scheduledFor?: InputMaybe<Scalars["Date"]["input"]>;
  tags?: InputMaybe<Array<Interest>>;
  theme?: InputMaybe<ArticleThemes>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SearchMatch {
  indices: Array<Array<Scalars["Int"]["output"]>>;
  key?: Maybe<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface SectionModel {
  createdAt: Scalars["Date"]["output"];
  elements?: Maybe<Array<Element>>;
  id: Scalars["String"]["output"];
  lesson: LessonModel;
  order?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface SendDirectChatMessageInput {
  receiverId: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
}

export interface SetPictureInput {
  channelId: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface SliderModel {
  background?: Maybe<ResponsiveType>;
  banner: BannerModel;
  buttonText?: Maybe<Scalars["String"]["output"]>;
  buttonURL?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  featureImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  imageAlignment?: Maybe<ImageAlignment>;
  position?: Maybe<Scalars["Int"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  textColour?: Maybe<TextColour>;
  title?: Maybe<Scalars["String"]["output"]>;
  titleStyle?: Maybe<TitleStyle>;
  updatedAt: Scalars["Date"]["output"];
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export interface SparkModel {
  createdAt: Scalars["Date"]["output"];
  event?: Maybe<EventModel>;
  id: Scalars["String"]["output"];
  liveTable?: Maybe<LiveTableModel>;
  user: UserModel;
  videoCall?: Maybe<VideoCallModel>;
}

export interface StatisticsInput {
  end: Scalars["Date"]["input"];
  start: Scalars["Date"]["input"];
  userId: Scalars["String"]["input"];
}

export interface SubscribeInput {
  couponIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  planId?: InputMaybe<Scalars["String"]["input"]>;
  signUpId?: InputMaybe<Scalars["String"]["input"]>;
  trial?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface Subscription {
  channelVideoCall: VideoCallModel;
  commentCreated: CommentModel;
  commentDeleted: CommentModel;
  communitySparkCreated: SparkModel;
  eventUpdated: EventModel;
  liveTableAboutToEnd: LiveTableModel;
  liveTableHasEnded: LiveTableModel;
  liveTableHasStarted: LiveTableStartedMessage;
  liveTableUpdated: LiveTableModel;
  notificationAdded: NotificationBaseModel;
  popupNotificationAdded: NotificationBaseModel;
  reactionCreated: CommentReactionsModel;
  reactionDeleted: CommentReactionsModel;
  sparkCreated: SparkModel;
  videoAskFormCreated: VideoAskModel;
  videoCallUpdated: VideoCallModel;
}

export interface SubscriptionChannelVideoCallArgs {
  id: Scalars["String"]["input"];
}

export interface SubscriptionCommentCreatedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionCommentDeletedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionEventUpdatedArgs {
  eventId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableAboutToEndArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableHasEndedArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionLiveTableUpdatedArgs {
  liveTableId: Scalars["String"]["input"];
}

export interface SubscriptionReactionCreatedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionReactionDeletedArgs {
  targetId: Scalars["String"]["input"];
}

export interface SubscriptionSparkCreatedArgs {
  eventId: Scalars["String"]["input"];
}

export interface SubscriptionVideoCallUpdatedArgs {
  id: Scalars["String"]["input"];
}

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  Future = "FUTURE",
  Incomplete = "INCOMPLETE",
  IncompleteExpired = "INCOMPLETE_EXPIRED",
  InTrial = "IN_TRIAL",
  NonRenewing = "NON_RENEWING",
  PastDue = "PAST_DUE",
  Paused = "PAUSED",
  Unpaid = "UNPAID",
}

export enum SubscriptionTier {
  Free = "Free",
  Study = "Study",
  Supporter = "Supporter",
}

export enum TextColour {
  Dark = "Dark",
  Light = "Light",
}

export interface ThemeModel {
  cover?: Maybe<CoverModel>;
  createdAt: Scalars["Date"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isCurrent?: Maybe<Scalars["Boolean"]["output"]>;
  isGrammarLibrary?: Maybe<Scalars["Boolean"]["output"]>;
  isResource?: Maybe<Scalars["Boolean"]["output"]>;
  isStandard?: Maybe<Scalars["Boolean"]["output"]>;
  isTopicLibrary?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  topics?: Maybe<Array<TopicModel>>;
  updatedAt: Scalars["Date"]["output"];
}

export enum TitleStyle {
  Bold = "Bold",
  Standard = "Standard",
}

export interface TopicModel {
  comments?: Maybe<Array<CommentModel>>;
  createdAt: Scalars["Date"]["output"];
  events?: Maybe<Array<EventModel>>;
  id: Scalars["String"]["output"];
  ideasToDiscuss: Array<Scalars["String"]["output"]>;
  illustration?: Maybe<IllustrationModel>;
  lessons?: Maybe<Array<LessonModel>>;
  likes?: Maybe<Array<LikeModel>>;
  stars?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<TopicStatus>;
  theme?: Maybe<ThemeModel>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface TopicSearchResult {
  item: TopicModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export enum TopicStatus {
  ComingSoon = "COMING_SOON",
  Current = "CURRENT",
  Inactive = "INACTIVE",
}

export interface UpdateEventAttachmentInput {
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export interface UpdateEventInput {
  conversationKind?: InputMaybe<ConversationKind>;
  coverUrl?: InputMaybe<Scalars["String"]["input"]>;
  customTopic?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  englishLevel?: InputMaybe<EventEnglishLevel>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  eventCategory?: InputMaybe<EventCategory>;
  hostID?: InputMaybe<Scalars["String"]["input"]>;
  ideasToDiscuss?: InputMaybe<Array<Scalars["String"]["input"]>>;
  illustrationUrl?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForConfidentUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForFoundation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForRise?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForUnverified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<EventKind>;
  links?: InputMaybe<Array<LinkInput>>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  locationLat?: InputMaybe<Scalars["Float"]["input"]>;
  locationLong?: InputMaybe<Scalars["Float"]["input"]>;
  maxParticipants?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  occupationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  permissions?: InputMaybe<Array<EventPermissions>>;
  recurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  topicId?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdateLiveTableInput {
  englishLevel?: InputMaybe<EventEnglishLevelNamed>;
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UpdatePasswordInput {
  currentPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}

export interface UpdatePhotoInput {
  url: Scalars["String"]["input"];
}

export interface UpdateSettingsInput {
  emailNotifications?: InputMaybe<EmailNotifications>;
  receiveChatMentionsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveChatMentionsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRemindersEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRemindersNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRsvpEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventRsvpNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventUpdatesAndInvitationsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveEventUpdatesAndInvitationsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveFollowsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveFollowsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveLiveTableStartedNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveMentionsAndCommentsEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveMentionsAndCommentsNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  receivePlatformStatusEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  receiveSubscriptionChangesEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UpdateUserInput {
  availability?: InputMaybe<Array<AvailabilityInput>>;
  avatarUrl?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  cambridgeEnglishLevel?: InputMaybe<EnglishLevel>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  cityId?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  displayedStatistics?: InputMaybe<DisplayedStatistics>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  englishSkills?: InputMaybe<Array<EnglishSkill>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  interests?: InputMaybe<Array<Interest>>;
  isAgeVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastOnlineAt?: InputMaybe<Scalars["Date"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  long?: InputMaybe<Scalars["Float"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  nativeLanguage?: InputMaybe<Scalars["String"]["input"]>;
  needSpeakingPartner?: InputMaybe<Scalars["Boolean"]["input"]>;
  occupationId?: InputMaybe<Scalars["String"]["input"]>;
  otherLanguage?: InputMaybe<Scalars["String"]["input"]>;
  showBirthday?: InputMaybe<Scalars["Boolean"]["input"]>;
  showBirthdayYear?: InputMaybe<Scalars["Boolean"]["input"]>;
  story?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  wantsNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface UploadActiveDaysInput {
  file: Scalars["Upload_Scalar"]["input"];
}

export interface UploadCoverZineInput {
  file: Scalars["Upload_Scalar"]["input"];
  zineId: Scalars["String"]["input"];
}

export interface UploadEventImageInput {
  file: Scalars["Upload_Scalar"]["input"];
  height?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UploadImageArticleInput {
  articleId: Scalars["String"]["input"];
  cropHeight?: InputMaybe<Scalars["Int"]["input"]>;
  cropWidth?: InputMaybe<Scalars["Int"]["input"]>;
  file: Scalars["Upload_Scalar"]["input"];
  height?: InputMaybe<Scalars["Int"]["input"]>;
  left?: InputMaybe<Scalars["Int"]["input"]>;
  top?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface UploadPhotoInput {
  filename: Scalars["String"]["input"];
}

export interface UploadPictureInput {
  channelId: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}

export interface UploadUrlsType {
  contentURL?: Maybe<Scalars["String"]["output"]>;
  uploadURL?: Maybe<Scalars["String"]["output"]>;
}

export enum UserBadge {
  BigSister = "BIG_SISTER",
  CommunitySuccess = "COMMUNITY_SUCCESS",
  EnglishCoach = "ENGLISH_COACH",
  Founder = "FOUNDER",
  InactiveMember = "INACTIVE_MEMBER",
  LittleSister = "LITTLE_SISTER",
  Member = "MEMBER",
  MemberSupport = "MEMBER_SUPPORT",
  TechnicalSupport = "TECHNICAL_SUPPORT",
}

export interface UserBadgeModel {
  achievedDate?: Maybe<Scalars["Date"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  type: UserBadgeType;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export enum UserBadgeType {
  Ambassador = "Ambassador",
  ArticlesPublished_1 = "ArticlesPublished_1",
  BigSister = "BigSister",
  BroughtAFriend = "BroughtAFriend",
  CertifiedHost = "CertifiedHost",
  DiamondAmbassador = "DiamondAmbassador",
  EventsParticipated_10 = "EventsParticipated_10",
  EventsParticipated_100 = "EventsParticipated_100",
  EventsParticipated_500 = "EventsParticipated_500",
  EventsParticipated_1000 = "EventsParticipated_1000",
  FoundingMember = "FoundingMember",
  HeyLadyWay = "HeyLadyWay",
  Hosted_1 = "Hosted_1",
  Hosted_10 = "Hosted_10",
  Hosted_50 = "Hosted_50",
  Hosted_100 = "Hosted_100",
  Hosted_500 = "Hosted_500",
  LegendaryLady = "LegendaryLady",
  Membership_1 = "Membership_1",
  Membership_2 = "Membership_2",
  Membership_3 = "Membership_3",
  PerfectProfile = "PerfectProfile",
  PlatinumAmbassador = "PlatinumAmbassador",
  Verified = "Verified",
}

export interface UserByReferralCodeResponse {
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
}

export enum UserDeactivationReason {
  Custom = "CUSTOM",
  HardToUse = "HARD_TO_USE",
  NoSpeakingPartner = "NO_SPEAKING_PARTNER",
  NoTimeForEnglishPractice = "NO_TIME_FOR_ENGLISH_PRACTICE",
  ToExpensive = "TO_EXPENSIVE",
  TutorOrLanguageCourse = "TUTOR_OR_LANGUAGE_COURSE",
}

export interface UserEngagementMetrics {
  attendanceMetrics?: Maybe<AttendanceMetrics>;
  attendanceRatio?: Maybe<Scalars["Float"]["output"]>;
  publishedArticles?: Maybe<Scalars["Int"]["output"]>;
  totalHostedParticipants?: Maybe<Scalars["Int"]["output"]>;
  totalRsvps?: Maybe<Scalars["Int"]["output"]>;
}

export interface UserFlagModel {
  featureFlag: FeatureFlagModel;
  id: Scalars["String"]["output"];
  user: UserModel;
}

export interface UserInviteStatus {
  inviteStatus: InviteStatus;
  userId: Scalars["String"]["input"];
}

export interface UserLocation {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UserMatchingModel {
  defaultMatchScore?: Maybe<Scalars["Float"]["output"]>;
  matchDetails?: Maybe<MatchDetails>;
  matchScores?: Maybe<MatchScores>;
  matchWeights?: Maybe<MatchScores>;
  score?: Maybe<Scalars["Float"]["output"]>;
  user: UserModel;
  userId: Scalars["String"]["output"];
}

export interface UserModel {
  abusers?: Maybe<Array<AbuseModel>>;
  activationKey?: Maybe<Scalars["String"]["output"]>;
  availability?: Maybe<Array<Availability>>;
  availabilityWeekdayFrom?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekdayUntil?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekendsFrom?: Maybe<Scalars["Int"]["output"]>;
  availabilityWeekendsUntil?: Maybe<Scalars["Int"]["output"]>;
  availableAssessmentCharges?: Maybe<Scalars["Int"]["output"]>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<UserBadge>;
  badges: Array<UserBadgeModel>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  cambridgeEnglishLevel?: Maybe<Scalars["Int"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  cityId?: Maybe<Scalars["String"]["output"]>;
  completedHomeTour?: Maybe<Scalars["Boolean"]["output"]>;
  completedPercentage: Scalars["Int"]["output"];
  country?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  customFlags: Array<UserFlagModel>;
  customers: Array<ChargebeeCustomerModel>;
  deletedAt?: Maybe<Scalars["Date"]["output"]>;
  displayedStatistics?: Maybe<DisplayedStatistics>;
  email: Scalars["String"]["output"];
  engagementMetrics?: Maybe<UserEngagementMetrics>;
  englishSkills?: Maybe<Array<EnglishSkill>>;
  extraConversations?: Maybe<Scalars["Int"]["output"]>;
  extraHostedEvents?: Maybe<Scalars["Int"]["output"]>;
  extraInvitedFriends?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  followers?: Maybe<Array<FollowerModel>>;
  following?: Maybe<Array<FollowerModel>>;
  googleRefreshToken?: Maybe<Scalars["String"]["output"]>;
  hasActivePlan: Scalars["Boolean"]["output"];
  hasGoogleRefreshToken: Scalars["Boolean"]["output"];
  hasPassword: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  impersonated: Scalars["Boolean"]["output"];
  interests?: Maybe<Array<Interest>>;
  invites: Array<InviteModel>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isAgeVisible?: Maybe<Scalars["Boolean"]["output"]>;
  isBanned: Scalars["Boolean"]["output"];
  isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isHeyLady?: Maybe<Scalars["Boolean"]["output"]>;
  isOnline?: Maybe<Scalars["Boolean"]["output"]>;
  isVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  kind?: Maybe<EventPermissions>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastOnlineAt?: Maybe<Scalars["Date"]["output"]>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  nationality?: Maybe<Scalars["String"]["output"]>;
  nativeLanguage?: Maybe<Scalars["String"]["output"]>;
  needSpeakingPartner?: Maybe<Scalars["Boolean"]["output"]>;
  notes: Array<NoteModel>;
  occupation?: Maybe<OccupationModel>;
  onboarding: Onboarding;
  otherLanguage?: Maybe<Scalars["String"]["output"]>;
  partners?: Maybe<Scalars["Int"]["output"]>;
  pauseCredits?: Maybe<Scalars["Int"]["output"]>;
  photos: Array<UserPhotoModel>;
  planSlug?: Maybe<Scalars["String"]["output"]>;
  promotionalCredits?: Maybe<Scalars["Int"]["output"]>;
  referralCouponId?: Maybe<Scalars["String"]["output"]>;
  seenCompletedAt?: Maybe<Scalars["Date"]["output"]>;
  showBirthday?: Maybe<Scalars["Boolean"]["output"]>;
  showBirthdayYear?: Maybe<Scalars["Boolean"]["output"]>;
  speakingPartners?: Maybe<Scalars["String"]["output"]>;
  stepsCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  story?: Maybe<Scalars["String"]["output"]>;
  subscriptionTier: SubscriptionTier;
  timezone?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  updatedLocation?: Maybe<Scalars["Boolean"]["output"]>;
  victims?: Maybe<Array<AbuseModel>>;
  wantsNewsletter?: Maybe<Scalars["Boolean"]["output"]>;
}

export interface UserPhotoModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  url: Scalars["String"]["output"];
  user: UserModel;
}

export enum UserReportReason {
  MaleGenderOrFalseIdentityOrPreviouslyBanned = "MALE_GENDER_OR_FALSE_IDENTITY_OR_PREVIOUSLY_BANNED",
  OffensiveOrDisrespectful = "OFFENSIVE_OR_DISRESPECTFUL",
  Other = "OTHER",
  UnacceptableBehaviour = "UNACCEPTABLE_BEHAVIOUR",
}

export interface UserSearchResult {
  item: UserModel;
  matches?: Maybe<Array<SearchMatch>>;
  refIndex: Scalars["Int"]["output"];
}

export interface UserSettingsModel {
  createdAt: Scalars["Date"]["output"];
  emailNotifications?: Maybe<EmailNotifications>;
  id: Scalars["String"]["output"];
  receiveChatMentionsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveChatMentionsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRemindersEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRemindersNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRsvpEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventRsvpNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventUpdatesAndInvitationsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveEventUpdatesAndInvitationsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveFollowsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveFollowsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveLiveTableStartedNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receiveMentionsAndCommentsEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveMentionsAndCommentsNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  receivePlatformStatusEmails?: Maybe<Scalars["Boolean"]["output"]>;
  receiveSubscriptionChangesEmails?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
}

export interface UserSignUpModel {
  createdAt: Scalars["Date"]["output"];
  email: Scalars["String"]["output"];
  englishLevel?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasVerifiedEmail: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  planParam: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface ValidateBulkInviteResponse {
  userIds: Array<Scalars["String"]["output"]>;
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface ValidateSelectionBulkInviteResponse {
  eventTitle?: Maybe<Scalars["String"]["output"]>;
  userIds: Array<Scalars["String"]["output"]>;
  warningMessages: Array<Scalars["String"]["output"]>;
}

export interface VerifyUserInput {
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export enum VideoAskFormType {
  Verification = "Verification",
}

export interface VideoAskModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  type: VideoAskFormType;
  user: UserModel;
}

export interface VideoCallModel {
  chatRoomId: Scalars["String"]["output"];
  closedDoorAt?: Maybe<Scalars["Date"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  dailyRoomId: Scalars["String"]["output"];
  endedAt?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["String"]["output"];
  lastSingleUserAt?: Maybe<Scalars["Date"]["output"]>;
  participants: Array<VideoParticipantModel>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
}

export interface VideoModel {
  completedByMe: Scalars["Boolean"]["output"];
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  position: Scalars["Int"]["output"];
  set: VideoSetModel;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  videoURL?: Maybe<Scalars["String"]["output"]>;
}

export interface VideoParticipantModel {
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isHost: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: UserModel;
  userId: Scalars["String"]["output"];
  videoCall: VideoCallModel;
}

export interface VideoSetModel {
  audience?: Maybe<Array<Audience>>;
  colour?: Maybe<ColourCode>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  position: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Date"]["output"];
  videos?: Maybe<Array<VideoModel>>;
}

export interface ZineModel {
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["String"]["output"];
  isCurrent?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
}

export interface CbPlanDetails {
  amount?: Maybe<Scalars["Int"]["output"]>;
  cancelledAt?: Maybe<Scalars["Date"]["output"]>;
  cbId?: Maybe<Scalars["String"]["output"]>;
  customerChargebeeId?: Maybe<Scalars["String"]["output"]>;
  end?: Maybe<Scalars["Date"]["output"]>;
  interval?: Maybe<Scalars["String"]["output"]>;
  intervalCount?: Maybe<Scalars["Int"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  resumesAt?: Maybe<Scalars["Date"]["output"]>;
  start?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionChargebeeId?: Maybe<Scalars["String"]["output"]>;
  userAmount?: Maybe<Scalars["Int"]["output"]>;
}

export type CommentNotificationFieldsFragment = {
  id: string;
  commentAction: CommentAction;
  comment: {
    id: string;
    targetId: string;
    targetType: CommentTargetType;
    targetArticle?: { title?: string | null } | null;
    targetEvent?: { title?: string | null } | null;
    targetTopic?: { title: string } | null;
  };
  actor?: {
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  } | null;
};

export type EventNotificationFieldsFragment = {
  id: string;
  action: EventNotificationAction;
  actor?: {
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  } | null;
  event: {
    id: string;
    startDate?: any | null;
    title?: string | null;
    kind?: EventKind | null;
    isDeleted?: boolean | null;
    host?: { id: string; name: string } | null;
  };
  invite?: { id: string; status?: InviteStatus | null } | null;
};

export type ChatMentionNotificationFieldsFragment = {
  id: string;
  channelId: string;
  chatMessageId: string;
  parentChatMessageId?: string | null;
  chatMessage?: string | null;
  actor?: {
    id: string;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  } | null;
};

export type BadgeNotificationFieldsFragment = {
  id: string;
  badge: { id: string; type: UserBadgeType; achievedDate?: any | null };
};

export type ProfileUpdateNotificationFieldsFragment = {
  id: string;
  type: ProfileUpdateNotificationType;
};

export type ArticleNotificationFieldsFragment = {
  id: string;
  articleAction: ArticleAction;
  article: {
    id: string;
    title?: string | null;
    author?: {
      id: string;
      avatarUrl?: string | null;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type MonthlyReportNotificationFieldsFragment = {
  id: string;
  type: MonthlyReportNotificationType;
};

export type FriendJoinedNotificationFieldsFragment = {
  id: string;
  type: FriendJoinedNotificationType;
  user: { id: string; firstName?: string | null; lastName?: string | null };
  friend: {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  };
};

export type InitiateAssessmentMutationVariables = Exact<{ [key: string]: never }>;

export type InitiateAssessmentMutation = { initiateAssessment: string };

export type ChatCreateChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;

export type ChatCreateChannelMutation = { chatCreateChannel?: any | null };

export type ChatAddMembersToChannelMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}>;

export type ChatAddMembersToChannelMutation = {
  chatAddMembersToChannel?: { status: MutationStatus } | null;
};

export type ChatRemoveMembersFromChannelMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: CreateChannelInput;
}>;

export type ChatRemoveMembersFromChannelMutation = {
  chatRemoveMembersFromChannel?: { status: MutationStatus } | null;
};

export type ChatRenameChannelMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  channelName: Scalars["String"]["input"];
}>;

export type ChatRenameChannelMutation = { chatRenameChannel?: { status: MutationStatus } | null };

export type GenerateGroupPictureUploadUrlMutationVariables = Exact<{
  input: UploadPictureInput;
}>;

export type GenerateGroupPictureUploadUrlMutation = {
  generateGroupPictureUploadUrl?: { contentURL?: string | null; uploadURL?: string | null } | null;
};

export type SetGroupPictureUrlMutationVariables = Exact<{
  input: SetPictureInput;
}>;

export type SetGroupPictureUrlMutation = { setGroupPictureUrl?: { status: MutationStatus } | null };

export type CreateVideoCallMutationVariables = Exact<{
  input: CreateVideoCallInput;
}>;

export type CreateVideoCallMutation = {
  createVideoCall: { id: string; title: string; chatRoomId: string; dailyRoomId: string };
};

export type ChatSendDirectMessageMutationVariables = Exact<{
  input: SendDirectChatMessageInput;
}>;

export type ChatSendDirectMessageMutation = {
  chatSendDirectMessage?: { status: MutationStatus } | null;
};

export type CreateCommentMutationVariables = Exact<{
  targetId: Scalars["String"]["input"];
  targetType: CommentTargetType;
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  createComment?: {
    id: string;
    createdAt: any;
    message: string;
    author?: { id: string; name: string; avatarUrl?: string | null } | null;
    replies?: Array<{
      id: string;
      createdAt: any;
      message: string;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
    }> | null;
  } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  commentID: Scalars["String"]["input"];
}>;

export type DeleteCommentMutation = { deleteComment?: { status: MutationStatus } | null };

export type CreateReactionMutationVariables = Exact<{
  input: CreateReactionInput;
}>;

export type CreateReactionMutation = { createReaction: { id: string; unifiedId: string } };

export type DeleteReactionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteReactionMutation = { deleteReaction: { status: MutationStatus } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = { createEvent?: { id: string } | null };

export type PublishEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type PublishEventMutation = { publishEvent?: { id: string } | null };

export type DuplicateEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DuplicateEventMutation = { duplicateEvent?: { id: string } | null };

export type CreateInviteMutationVariables = Exact<{
  input: CreateInviteInput;
}>;

export type CreateInviteMutation = {
  createInvite: {
    id: string;
    status?: InviteStatus | null;
    isHost?: boolean | null;
    user?: { id: string; name: string; avatarUrl?: string | null } | null;
  };
};

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteInviteMutation = { deleteInvite?: { status: MutationStatus } | null };

export type GenerateAttachmentUploadUrlMutationVariables = Exact<{
  filename: Scalars["String"]["input"];
  eventID: Scalars["String"]["input"];
}>;

export type GenerateAttachmentUploadUrlMutation = {
  generateAttachmentUploadUrl?: { contentURL?: string | null; uploadURL?: string | null } | null;
};

export type CreateAttachmentMutationVariables = Exact<{
  eventID: Scalars["String"]["input"];
  input: CreateEventAttachmentInput;
}>;

export type CreateAttachmentMutation = {
  createAttachment?: {
    id: string;
    url?: string | null;
    meta?: { name?: string | null; extension?: string | null; size?: number | null } | null;
  } | null;
};

export type DeleteAttachmentMutationVariables = Exact<{
  attachmentID: Scalars["String"]["input"];
}>;

export type DeleteAttachmentMutation = { deleteAttachment?: { id: string } | null };

export type DeleteEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteEventMutation = { deleteEvent?: { status: MutationStatus } | null };

export type AttendEventMutationVariables = Exact<{
  input: AttendEventInput;
}>;

export type AttendEventMutation = {
  attendEvent?: { id: string; status?: InviteStatus | null } | null;
};

export type CancelAttendanceMutationVariables = Exact<{
  input: CancelAttendanceInput;
}>;

export type CancelAttendanceMutation = {
  cancelAttendance: { id: string; status?: InviteStatus | null };
};

export type DeclineInviteMutationVariables = Exact<{
  input: DeclineInviteInput;
}>;

export type DeclineInviteMutation = { declineInvite: { id: string; status?: InviteStatus | null } };

export type UpdateEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: UpdateEventInput;
}>;

export type UpdateEventMutation = {
  updateEvent?: {
    title?: string | null;
    description?: string | null;
    kind?: EventKind | null;
    conversationKind?: ConversationKind | null;
    startDate?: any | null;
    endDate?: any | null;
    isPublic?: boolean | null;
    host?: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
    } | null;
    topic?: { id: string } | null;
    organizer?: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
    } | null;
  } | null;
};

export type CloseDoorMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CloseDoorMutation = { closeDoor?: { id: string; closedDoorAt?: any | null } | null };

export type OpenDoorMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type OpenDoorMutation = { openDoor?: { id: string; closedDoorAt?: any | null } | null };

export type AddEventToGoogleCalendarMutationVariables = Exact<{
  input: AddEventCalendarInput;
}>;

export type AddEventToGoogleCalendarMutation = {
  addEventToGoogleCalendar: {
    status: MutationStatus;
    url?: string | null;
    reason?: GoogleApiMessage | null;
  };
};

export type EventMeetingTokenMutationVariables = Exact<{
  input: DailyMeetingInput;
}>;

export type EventMeetingTokenMutation = {
  eventMeetingToken?: { token: string; exp?: number | null } | null;
};

export type MarkJoinEventMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
}>;

export type MarkJoinEventMutation = { markJoinEvent: { status: MutationStatus } };

export type SaveParticipantSessionMutationVariables = Exact<{
  participantId: Scalars["String"]["input"];
  meetingSessionId: Scalars["String"]["input"];
}>;

export type SaveParticipantSessionMutation = { saveParticipantSession: { status: MutationStatus } };

export type UploadEventDescriptionImageMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: UploadEventImageInput;
}>;

export type UploadEventDescriptionImageMutation = {
  uploadEventDescriptionImage?: { contentURL?: string | null } | null;
};

export type DeleteInvitesMutationVariables = Exact<{
  input: DeleteInvitesInput;
}>;

export type DeleteInvitesMutation = { deleteInvites?: { status: MutationStatus } | null };

export type ConfirmInPersonEventMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type ConfirmInPersonEventMutation = { confirmInPersonEvent: { status: MutationStatus } };

export type ShareEventMutationVariables = Exact<{
  eventId: Scalars["String"]["input"];
  userIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type ShareEventMutation = { shareEvent: { status: MutationStatus } };

export type CreateLiveTableMutationVariables = Exact<{
  input: CreateLiveTableInput;
}>;

export type CreateLiveTableMutation = {
  createLiveTable: { id: string; title: string; dailyRoomId: string };
};

export type UpdateLiveTableMutationVariables = Exact<{
  input: UpdateLiveTableInput;
  id: Scalars["String"]["input"];
}>;

export type UpdateLiveTableMutation = { updateLiveTable: { status: MutationStatus } };

export type LiveTableMeetingTokenMutationVariables = Exact<{
  input: DailyMeetingInput;
}>;

export type LiveTableMeetingTokenMutation = {
  liveTableMeetingToken: { token: string; exp?: number | null };
};

export type SendLiveTablesFeedbackMutationVariables = Exact<{
  input: LiveTablesFeedbackInput;
}>;

export type SendLiveTablesFeedbackMutation = { sendLiveTablesFeedback: { status: MutationStatus } };

export type NotifyAboutStartedLiveTableMutationVariables = Exact<{
  input: LiveTableStartedMessageInput;
}>;

export type NotifyAboutStartedLiveTableMutation = {
  notifyAboutStartedLiveTable: { status: MutationStatus };
};

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteArticleMutation = { deleteArticle?: { id: string } | null };

export type CreateArticleMutationVariables = Exact<{ [key: string]: never }>;

export type CreateArticleMutation = { createArticle?: { id: string } | null };

export type PublishOrScheduleArticleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: PublishArticleInput;
}>;

export type PublishOrScheduleArticleMutation = { publishOrScheduleArticle?: { id: string } | null };

export type SaveArticleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: SaveDraftArticleInput;
}>;

export type SaveArticleMutation = {
  saveArticle?: { id: string; tags?: Array<Interest> | null } | null;
};

export type DeleteLikeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteLikeMutation = { deleteLike?: { status: MutationStatus } | null };

export type CreateLikeMutationVariables = Exact<{
  input: CreateLikeInput;
}>;

export type CreateLikeMutation = { createLike?: { id: string } | null };

export type GenerateArticlesAttachmentUploadUrlMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
}>;

export type GenerateArticlesAttachmentUploadUrlMutation = {
  generateArticlesAttachmentUploadUrl?: {
    contentURL?: string | null;
    uploadURL?: string | null;
  } | null;
};

export type CreateArticleAttachmentMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: CreateArticlesAttachmentInput;
}>;

export type CreateArticleAttachmentMutation = {
  createArticleAttachment?: { id: string; url?: string | null } | null;
};

export type DeleteArticleAttachmentMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteArticleAttachmentMutation = { deleteArticleAttachment?: { id: string } | null };

export type UploadArticleImageMutationVariables = Exact<{
  input: UploadImageArticleInput;
}>;

export type UploadArticleImageMutation = {
  uploadArticleImage?: { contentURL?: string | null } | null;
};

export type NotificationSeenMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type NotificationSeenMutation = {
  notificationSeen?: {
    id: string;
    notificationSeen?: Array<{ id: string; seenAt?: any | null }> | null;
  } | null;
};

export type MarkAllAsSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllAsSeenMutation = { markAllAsSeen?: { status: MutationStatus } | null };

export type SubscribePaymentsMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribePaymentsMutation = { subscribe?: any | null };

export type GetHostedPageMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetHostedPageMutation = { getHostedPage?: any | null };

export type SwitchMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SwitchMutation = { switch?: any | null };

export type ChooseSubscriptionMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type ChooseSubscriptionMutation = { chooseSubscription?: any | null };

export type ScheduleCancelSubscriptionMutationVariables = Exact<{
  input: RequestRefundInput;
}>;

export type ScheduleCancelSubscriptionMutation = {
  scheduleCancelSubscription?: { status: MutationStatus } | null;
};

export type CancelScheduleCancelSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelScheduleCancelSubscriptionMutation = {
  cancelScheduleCancelSubscription?: { status: MutationStatus } | null;
};

export type RequestAssistanceMutationVariables = Exact<{
  input: AssistanceReasonsInput;
}>;

export type RequestAssistanceMutation = { requestAssistance?: { status: MutationStatus } | null };

export type GetPrecancelPageMutationVariables = Exact<{ [key: string]: never }>;

export type GetPrecancelPageMutation = { getPrecancelPage?: string | null };

export type UpdatePaymentMethodMutationVariables = Exact<{ [key: string]: never }>;

export type UpdatePaymentMethodMutation = { updatePaymentMethod?: string | null };

export type ActivateTrialSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type ActivateTrialSubscriptionMutation = {
  activateTrialSubscription: { status: MutationStatus };
};

export type CreateSparkMutationVariables = Exact<{
  input: CreateSparkInput;
}>;

export type CreateSparkMutation = { createSpark: { status: MutationStatus } };

export type ActivateUserMutationVariables = Exact<{
  input: ActivateUserInput;
}>;

export type ActivateUserMutation = { activateUser?: { status: MutationStatus } | null };

export type ResendConfirmationEmailMutationVariables = Exact<{
  input: ResendConfirmationEmailInput;
}>;

export type ResendConfirmationEmailMutation = {
  resendConfirmationEmail: { status: MutationStatus };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = { createUser: { id: string } };

export type VerifyUserMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  input: VerifyUserInput;
}>;

export type VerifyUserMutation = { verifyUser?: { status: MutationStatus } | null };

export type HeartbeatMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type HeartbeatMutation = { heartbeat?: { status: MutationStatus } | null };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateMeMutation = {
  updateMe?: {
    id: string;
    avatarUrl?: string | null;
    needSpeakingPartner?: boolean | null;
    otherLanguage?: string | null;
    cambridgeEnglishLevel?: number | null;
    firstName?: string | null;
    englishSkills?: Array<EnglishSkill> | null;
    interests?: Array<Interest> | null;
    isComplete?: boolean | null;
    lastName?: string | null;
    city?: string | null;
    country?: string | null;
    story?: string | null;
    birthday?: any | null;
    showBirthday?: boolean | null;
    nativeLanguage?: string | null;
    wantsNewsletter?: boolean | null;
    completedPercentage: number;
    occupation?: { id: string } | null;
    availability?: Array<{ from: string; until: string }> | null;
  } | null;
};

export type GenerateUserUploadUrlMutationVariables = Exact<{
  input: UploadPhotoInput;
}>;

export type GenerateUserUploadUrlMutation = {
  generateUserUploadUrl?: { contentURL?: string | null; uploadURL?: string | null } | null;
};

export type CreateUserPhotoMutationVariables = Exact<{
  input: UpdatePhotoInput;
}>;

export type CreateUserPhotoMutation = {
  createUserPhoto?: { id: string; url: string; createdAt: any; updatedAt: any } | null;
};

export type DeleteUserPhotoMutationVariables = Exact<{
  input: DeletePhotoInput;
}>;

export type DeleteUserPhotoMutation = { deleteUserPhoto?: { id: string } | null };

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestPasswordInput;
}>;

export type RequestResetPasswordMutation = { requestResetPassword: { status: MutationStatus } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { resetPassword?: { status: MutationStatus } | null };

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UpdateSettingsInput;
}>;

export type UpdateUserSettingsMutation = { updateUserSettings?: { status: MutationStatus } | null };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = { updatePassword: { status: MutationStatus } };

export type DeactivateMutationVariables = Exact<{
  input: DeactivateInput;
}>;

export type DeactivateMutation = {
  deactivate?: { reason?: string | null; status: MutationStatus } | null;
};

export type RecoverAccountMutationVariables = Exact<{ [key: string]: never }>;

export type RecoverAccountMutation = { recoverAccount?: { status: MutationStatus } | null };

export type CreateAbuseMutationVariables = Exact<{
  input: ReportAbuseInput;
}>;

export type CreateAbuseMutation = {
  createAbuse?: { reason?: UserReportReason | null; description?: string | null } | null;
};

export type ContactUsMutationVariables = Exact<{
  input: ContactUsInput;
}>;

export type ContactUsMutation = { contactUs?: { status: MutationStatus } | null };

export type AddZoomLicenseToUserMutationVariables = Exact<{
  userID: Scalars["String"]["input"];
}>;

export type AddZoomLicenseToUserMutation = {
  addZoomLicenseToUser?: { status: MutationStatus } | null;
};

export type FollowMutationVariables = Exact<{
  input: FollowInput;
}>;

export type FollowMutation = { follow?: { status: MutationStatus } | null };

export type UnFollowMutationVariables = Exact<{
  input: FollowInput;
}>;

export type UnFollowMutation = { unFollow?: { status: MutationStatus } | null };

export type PauseMutationVariables = Exact<{ [key: string]: never }>;

export type PauseMutation = { pause: { status: MutationStatus } };

export type ResumeMutationVariables = Exact<{ [key: string]: never }>;

export type ResumeMutation = { resume: { status: MutationStatus } };

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribeMutation = { subscribe?: any | null };

export type SetTokenMutationVariables = Exact<{
  input: Scalars["String"]["input"];
}>;

export type SetTokenMutation = { setToken: { status: MutationStatus } };

export type MarkReportAsSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkReportAsSeenMutation = { markReportAsSeen?: { status: MutationStatus } | null };

export type AssignCompleteOnboardingQuizBadgeMutationVariables = Exact<{ [key: string]: never }>;

export type AssignCompleteOnboardingQuizBadgeMutation = {
  assignCompleteOnboardingQuizBadge: { status: MutationStatus };
};

export type SeenCompletedBasicsMutationVariables = Exact<{ [key: string]: never }>;

export type SeenCompletedBasicsMutation = {
  seenCompletedBasics?: { status: MutationStatus } | null;
};

export type SendLinkMutationVariables = Exact<{
  input: FriendInput;
}>;

export type SendLinkMutation = { sendLink?: { status: MutationStatus } | null };

export type CloseVideoCallDoorMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CloseVideoCallDoorMutation = {
  closeVideoCallDoor: { id: string; closedDoorAt?: any | null };
};

export type OpenVideoCallDoorMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type OpenVideoCallDoorMutation = {
  openVideoCallDoor: { id: string; closedDoorAt?: any | null };
};

export type VideoMeetingTokenMutationVariables = Exact<{
  input: DailyMeetingInput;
}>;

export type VideoMeetingTokenMutation = {
  videoMeetingToken: { token: string; exp?: number | null };
};

export type CompleteVideoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CompleteVideoMutation = { completeVideo?: { id: string } | null };

export type GetAssessmentChargesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssessmentChargesQuery = {
  me: { id: string; availableAssessmentCharges?: number | null };
};

export type GetCurrentBannerQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentBannerQuery = {
  getCurrentBanner?: {
    id: string;
    title: string;
    isActive: boolean;
    sliders?: Array<{
      id: string;
      title?: string | null;
      subtitle?: string | null;
      buttonText?: string | null;
      buttonURL?: string | null;
      featureImage?: string | null;
      titleStyle?: TitleStyle | null;
      textColour?: TextColour | null;
      imageAlignment?: ImageAlignment | null;
      background?: { mobile?: string | null; desktop?: string | null } | null;
    }> | null;
  } | null;
};

export type ChatCreateUserTokenQueryVariables = Exact<{ [key: string]: never }>;

export type ChatCreateUserTokenQuery = { chatCreateUserToken: any };

export type ListPaginatedEventsQueryVariables = Exact<{
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}>;

export type ListPaginatedEventsQuery = {
  listPaginatedEvents: {
    total: number;
    items?: Array<{
      id: string;
      kind?: EventKind | null;
      title?: string | null;
      description?: string | null;
      isOnline?: boolean | null;
      conversationKind?: ConversationKind | null;
      coverUrl?: string | null;
      illustrationUrl?: string | null;
      englishLevel?: EventEnglishLevel | null;
      startDate?: any | null;
      endDate?: any | null;
      isForUnverified?: boolean | null;
      isFull?: boolean | null;
      maxParticipants?: number | null;
      noParticipants?: number | null;
      isDraft?: boolean | null;
      permissions?: Array<EventPermissions> | null;
      topic?: { id: string } | null;
      participants: Array<{ id: string; avatarUrl?: string | null }>;
      cohosts: Array<{
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
        kind?: EventPermissions | null;
      }>;
      host?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
        kind?: EventPermissions | null;
      } | null;
      organizer?: { id: string; name: string; kind?: EventPermissions | null } | null;
      invites?: Array<{
        id: string;
        status?: InviteStatus | null;
        user?: { id: string } | null;
      }> | null;
      occupations?: Array<{ id: string }> | null;
    }> | null;
  };
};

export type UserHostedEventsQueryVariables = Exact<{
  filters: FilterPaginatedEventInput;
  order?: InputMaybe<OrderEventInput>;
}>;

export type UserHostedEventsQuery = {
  userHostedEvents: {
    total: number;
    items?: Array<{
      id: string;
      title?: string | null;
      isFull?: boolean | null;
      startDate?: any | null;
    }> | null;
  };
};

export type UserHostedEventsToShareCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserHostedEventsToShareCountQuery = { userHostedEventsToShareCount: number };

export type EventQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type EventQuery = {
  getEvent: {
    id: string;
    coverUrl?: string | null;
    illustrationUrl?: string | null;
    createdAt: any;
    conversationKind?: ConversationKind | null;
    englishLevel?: EventEnglishLevel | null;
    englishSkills?: Array<EnglishSkill> | null;
    eventCategory?: EventCategory | null;
    duplicateOf?: string | null;
    isForConfidentUsers?: boolean | null;
    isForFoundation?: boolean | null;
    isForUnverified?: boolean | null;
    isForRise?: boolean | null;
    description?: string | null;
    endDate?: any | null;
    isDraft?: boolean | null;
    isOnline?: boolean | null;
    isPublic?: boolean | null;
    isDeleted?: boolean | null;
    isConfirmed?: boolean | null;
    interests?: Array<Interest> | null;
    kind?: EventKind | null;
    meta?: any | null;
    location?: string | null;
    locationId?: string | null;
    locationLat?: number | null;
    locationLong?: number | null;
    noParticipants?: number | null;
    recurring?: boolean | null;
    startDate?: any | null;
    title?: string | null;
    updatedAt: any;
    closedDoorAt?: any | null;
    videoUrl?: string | null;
    maxParticipants?: number | null;
    eventLessonKind?: EventLessonKind | null;
    permissions?: Array<EventPermissions> | null;
    ideasToDiscuss?: Array<string> | null;
    customTopic?: boolean | null;
    createdFromTopic?: boolean | null;
    occupations?: Array<{ id: string; name?: string | null }> | null;
    links?: Array<{ url: string; description: string }> | null;
    organizer?: {
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      createdAt: any;
      badge?: UserBadge | null;
      kind?: EventPermissions | null;
    } | null;
    host?: {
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      createdAt: any;
      badge?: UserBadge | null;
      kind?: EventPermissions | null;
      city?: string | null;
      country?: string | null;
      interests?: Array<Interest> | null;
    } | null;
    cohosts: Array<{
      id: string;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      createdAt: any;
      badge?: UserBadge | null;
      kind?: EventPermissions | null;
      city?: string | null;
      country?: string | null;
      interests?: Array<Interest> | null;
    }>;
    topic?: {
      id: string;
      title: string;
      ideasToDiscuss: Array<string>;
      illustration?: { id: string; url?: string | null } | null;
      lessons?: Array<{ id: string; kind: LessonKind; deepDiveKind?: DeepDiveKind | null }> | null;
      theme?: { id: string; title: string; isTopicLibrary?: boolean | null } | null;
    } | null;
    participants: Array<{
      id: string;
      avatarUrl?: string | null;
      createdAt: any;
      firstName?: string | null;
      lastName?: string | null;
      isVerified?: boolean | null;
      kind?: EventPermissions | null;
      city?: string | null;
      country?: string | null;
      interests?: Array<Interest> | null;
    }>;
    invites?: Array<{
      id: string;
      isHost?: boolean | null;
      status?: InviteStatus | null;
      isConfirmed?: boolean | null;
      user?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
        badge?: UserBadge | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
    attachments?: Array<{
      id: string;
      url?: string | null;
      meta?: { extension?: string | null; name?: string | null; size?: number | null } | null;
    }> | null;
    comments?: Array<{
      id: string;
      createdAt: any;
      message: string;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
      reactions?: Array<{
        id: string;
        unifiedId: string;
        user: {
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        };
      }> | null;
      replies?: Array<{
        id: string;
        createdAt: any;
        message: string;
        author?: { id: string; name: string; avatarUrl?: string | null } | null;
        reactions?: Array<{
          id: string;
          unifiedId: string;
          user: {
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatarUrl?: string | null;
          };
        }> | null;
      }> | null;
    }> | null;
  };
};

export type GetParticipationStatusQueryVariables = Exact<{
  input: ParticipationStatusInput;
}>;

export type GetParticipationStatusQuery = {
  getParticipationStatus: { status: ParticipationStatus };
};

export type TopicsAndThemesQueryVariables = Exact<{ [key: string]: never }>;

export type TopicsAndThemesQuery = {
  listThemes: Array<{
    id: string;
    title: string;
    createdAt: any;
    description?: string | null;
    isCurrent?: boolean | null;
    isStandard?: boolean | null;
    isTopicLibrary?: boolean | null;
    isGrammarLibrary?: boolean | null;
    updatedAt: any;
    cover?: {
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    } | null;
    topics?: Array<{
      id: string;
      title: string;
      illustration?: { id: string; title: string; url?: string | null } | null;
    }> | null;
  }>;
};

export type GetLiveTablesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLiveTablesQuery = {
  getLiveTables: Array<{
    table: {
      id: string;
      title: string;
      englishLevel?: EventEnglishLevelNamed | null;
      maxParticipants: number;
      dailyRoomId: string;
    };
    participants?: Array<{
      id?: string | null;
      name?: string | null;
      avatarUrl?: string | null;
    }> | null;
  }>;
};

export type GetLiveTableByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetLiveTableByIdQuery = {
  getLiveTableById: {
    id: string;
    title: string;
    englishLevel?: EventEnglishLevelNamed | null;
    dailyRoomId: string;
    isActive?: boolean | null;
    endedAt?: any | null;
    creatorId?: string | null;
  };
};

export type MemberzineQueryVariables = Exact<{ [key: string]: never }>;

export type MemberzineQuery = {
  getCurrentZine?: { coverUrl?: string | null } | null;
  listFeatured: {
    total: number;
    items?: Array<{
      id: string;
      coverUrl?: string | null;
      title?: string | null;
      isFeatured?: boolean | null;
      noComments?: number | null;
      noLikes?: number | null;
      author?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
  followingAuthorsArticles: {
    total: number;
    items?: Array<{
      id: string;
      coverUrl?: string | null;
      title?: string | null;
      isFeatured?: boolean | null;
      noComments?: number | null;
      noLikes?: number | null;
      author?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
  recommendedArticles: {
    items?: Array<{
      id: string;
      coverUrl?: string | null;
      title?: string | null;
      isFeatured?: boolean | null;
      noComments?: number | null;
      noLikes?: number | null;
      author?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
  allArticles: {
    total: number;
    items?: Array<{
      id: string;
      coverUrl?: string | null;
      title?: string | null;
      isFeatured?: boolean | null;
      noComments?: number | null;
      noLikes?: number | null;
      author?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
};

export type UploadArticleCoverMutationVariables = Exact<{
  input: UploadImageArticleInput;
}>;

export type UploadArticleCoverMutation = {
  uploadArticleCover?: { id: string; coverUrl?: string | null } | null;
};

export type GetArticleQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetArticleQuery = {
  getArticle: {
    noComments?: number | null;
    noLikes?: number | null;
    content?: string | null;
    coverUrl?: string | null;
    createdAt: any;
    id: string;
    isDraft?: boolean | null;
    isFeatured?: boolean | null;
    publishedAt?: any | null;
    scheduledFor?: any | null;
    tags?: Array<Interest> | null;
    myEnglishJourney?: boolean | null;
    title?: string | null;
    theme?: ArticleThemes | null;
    updatedAt: any;
    likedByMe?: { id: string } | null;
    author?: { id: string; name: string; avatarUrl?: string | null } | null;
    attachments?: Array<{
      id: string;
      description?: string | null;
      url?: string | null;
      createdAt: any;
      updatedAt: any;
      meta?: { name?: string | null; size?: number | null; extension?: string | null } | null;
    }> | null;
    comments?: Array<{
      id: string;
      createdAt: any;
      message: string;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
      reactions?: Array<{
        id: string;
        unifiedId: string;
        user: {
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        };
      }> | null;
      replies?: Array<{
        id: string;
        createdAt: any;
        message: string;
        author?: { id: string; name: string; avatarUrl?: string | null } | null;
        reactions?: Array<{
          id: string;
          unifiedId: string;
          user: {
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatarUrl?: string | null;
          };
        }> | null;
      }> | null;
    }> | null;
  };
};

export type ListArticlesQueryVariables = Exact<{
  filters: FilterArticlesInput;
}>;

export type ListArticlesQuery = {
  listArticles: {
    total: number;
    items?: Array<{
      id: string;
      coverUrl?: string | null;
      title?: string | null;
      isFeatured?: boolean | null;
      isDraft?: boolean | null;
      publishedAt?: any | null;
      noComments?: number | null;
      noLikes?: number | null;
      author?: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name: string;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
};

export type ListNotificationsForUserQueryVariables = Exact<{
  cursor: NotificationCursorPaginatedInput;
  unreadOnly: Scalars["Boolean"]["input"];
}>;

export type ListNotificationsForUserQuery = {
  listNotificationsForUser: {
    total: number;
    nextCursor?: any | null;
    items: Array<{
      id: string;
      type: NotificationType;
      createdAt: any;
      notificationSeen?: Array<{ id: string; seenAt?: any | null }> | null;
      chatMentionNotification?: {
        id: string;
        channelId: string;
        chatMessageId: string;
        parentChatMessageId?: string | null;
        chatMessage?: string | null;
        actor?: {
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        } | null;
      } | null;
      commentNotification?: {
        id: string;
        commentAction: CommentAction;
        comment: {
          id: string;
          targetId: string;
          targetType: CommentTargetType;
          targetArticle?: { title?: string | null } | null;
          targetEvent?: { title?: string | null } | null;
          targetTopic?: { title: string } | null;
        };
        actor?: {
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        } | null;
      } | null;
      eventNotification?: {
        id: string;
        action: EventNotificationAction;
        actor?: {
          id: string;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        } | null;
        event: {
          id: string;
          startDate?: any | null;
          title?: string | null;
          kind?: EventKind | null;
          isDeleted?: boolean | null;
          host?: { id: string; name: string } | null;
        };
        invite?: { id: string; status?: InviteStatus | null } | null;
      } | null;
      articleNotification?: {
        id: string;
        articleAction: ArticleAction;
        article: {
          id: string;
          title?: string | null;
          author?: {
            id: string;
            avatarUrl?: string | null;
            name: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        };
      } | null;
    }>;
  };
};

export type GetPopupNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPopupNotificationsQuery = {
  getPopupNotifications: Array<{
    id: string;
    type: NotificationType;
    createdAt: any;
    notificationSeen?: Array<{ id: string; seenAt?: any | null }> | null;
    badgeNotification?: {
      id: string;
      badge: { id: string; type: UserBadgeType; achievedDate?: any | null };
    } | null;
    eventNotification?: {
      id: string;
      action: EventNotificationAction;
      actor?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
      event: {
        id: string;
        startDate?: any | null;
        title?: string | null;
        kind?: EventKind | null;
        isDeleted?: boolean | null;
        host?: { id: string; name: string } | null;
      };
      invite?: { id: string; status?: InviteStatus | null } | null;
    } | null;
    profileUpdateNotification?: { id: string; type: ProfileUpdateNotificationType } | null;
    monthlyReportNotification?: { id: string; type: MonthlyReportNotificationType } | null;
    friendJoinedNotification?: {
      id: string;
      type: FriendJoinedNotificationType;
      user: { id: string; firstName?: string | null; lastName?: string | null };
      friend: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      };
    } | null;
  }>;
};

export type GetUserByReferralCodeQueryVariables = Exact<{
  codeId: Scalars["String"]["input"];
}>;

export type GetUserByReferralCodeQuery = {
  getUserByReferralCode: { firstName?: string | null; avatarUrl?: string | null };
};

export type GetInvitedFriendsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvitedFriendsCountQuery = { getInvitedFriendsCount: number };

export type SearchUsersQueryVariables = Exact<{
  query: Scalars["String"]["input"];
  eventId?: InputMaybe<Scalars["String"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SearchUsersQuery = {
  searchUsers: Array<{
    item: { id: string; name: string; avatarUrl?: string | null };
    matches?: Array<{ indices: Array<Array<number>>; value?: string | null }> | null;
  }>;
};

export type SearchMemberzineQueryVariables = Exact<{
  query: Scalars["String"]["input"];
}>;

export type SearchMemberzineQuery = {
  searchMemberzine: Array<{
    item: {
      id: string;
      title?: string | null;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
    };
    matches?: Array<{
      indices: Array<Array<number>>;
      value?: string | null;
      key?: string | null;
    }> | null;
  }>;
};

export type SearchTopicsQueryVariables = Exact<{
  query: Scalars["String"]["input"];
}>;

export type SearchTopicsQuery = {
  searchTopics: Array<{
    item: { id: string; title: string };
    matches?: Array<{
      indices: Array<Array<number>>;
      value?: string | null;
      key?: string | null;
    }> | null;
  }>;
};

export type GetUserSignUpQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetUserSignUpQuery = {
  getUserSignUp: {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    hasVerifiedEmail: boolean;
  };
};

export type GetTotalDistinctSparksQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalDistinctSparksQuery = { getTotalDistinctSparks: number };

export type UserGaveSparkInEventQueryVariables = Exact<{
  eventId: Scalars["String"]["input"];
}>;

export type UserGaveSparkInEventQuery = { userGaveSparkInEvent: boolean };

export type ThemesQueryVariables = Exact<{
  filters: FilterThemesInput;
}>;

export type ThemesQuery = {
  listThemes: Array<{
    id: string;
    title: string;
    createdAt: any;
    description?: string | null;
    isCurrent?: boolean | null;
    isStandard?: boolean | null;
    isTopicLibrary?: boolean | null;
    isGrammarLibrary?: boolean | null;
    isResource?: boolean | null;
    updatedAt: any;
    cover?: {
      id: string;
      title: string;
      primary?: { mobile?: string | null; desktop?: string | null } | null;
      secondary?: { mobile?: string | null; desktop?: string | null } | null;
      tertiary?: { mobile?: string | null; desktop?: string | null } | null;
    } | null;
    topics?: Array<{
      id: string;
      title: string;
      status?: TopicStatus | null;
      illustration?: { id: string; title: string; url?: string | null } | null;
    }> | null;
  }>;
};

export type ThemeQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ThemeQuery = {
  getTheme: {
    id: string;
    isResource?: boolean | null;
    topics?: Array<{
      id: string;
      title: string;
      illustration?: { id: string; title: string; url?: string | null } | null;
    }> | null;
  };
};

export type TopicsQueryVariables = Exact<{
  filters: FilterTopicsInput;
}>;

export type TopicsQuery = {
  listTopics: Array<{
    id: string;
    title: string;
    illustration?: { id: string; title: string; url?: string | null } | null;
  }>;
};

export type TopicQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type TopicQuery = {
  getTopic: {
    id: string;
    stars?: number | null;
    title: string;
    ideasToDiscuss: Array<string>;
    comments?: Array<{
      id: string;
      createdAt: any;
      message: string;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
      reactions?: Array<{
        id: string;
        unifiedId: string;
        user: {
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          avatarUrl?: string | null;
        };
      }> | null;
      replies?: Array<{
        id: string;
        createdAt: any;
        message: string;
        author?: { id: string; name: string; avatarUrl?: string | null } | null;
        reactions?: Array<{
          id: string;
          unifiedId: string;
          user: {
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            avatarUrl?: string | null;
          };
        }> | null;
      }> | null;
    }> | null;
    theme?: {
      id: string;
      isResource?: boolean | null;
      isGrammarLibrary?: boolean | null;
      cover?: {
        id: string;
        primary?: { desktop?: string | null; mobile?: string | null } | null;
        secondary?: { desktop?: string | null; mobile?: string | null } | null;
        tertiary?: { desktop?: string | null; mobile?: string | null } | null;
      } | null;
    } | null;
    illustration?: { id: string; title: string; url?: string | null } | null;
    lessons?: Array<{
      id: string;
      kind: LessonKind;
      title?: string | null;
      sections?: Array<{
        id: string;
        order?: number | null;
        elements?: Array<{ kind: ElementKind; value?: any | null; meta: any }> | null;
      }> | null;
    }> | null;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  me: {
    id: string;
    email: string;
    subscriptionTier: SubscriptionTier;
    avatarUrl?: string | null;
    isActive?: boolean | null;
    isVerified?: boolean | null;
    firstName?: string | null;
    isComplete?: boolean | null;
    completedPercentage: number;
    completedHomeTour?: boolean | null;
    badge?: UserBadge | null;
    createdAt: any;
    lastName?: string | null;
    name: string;
    hasActivePlan: boolean;
    kind?: EventPermissions | null;
    timezone?: string | null;
    hasGoogleRefreshToken: boolean;
    city?: string | null;
    country?: string | null;
    cityId?: string | null;
    countryId?: string | null;
    lat?: number | null;
    long?: number | null;
    cambridgeEnglishLevel?: number | null;
    birthday?: any | null;
    showBirthday?: boolean | null;
    showBirthdayYear?: boolean | null;
    needSpeakingPartner?: boolean | null;
    englishSkills?: Array<EnglishSkill> | null;
    interests?: Array<Interest> | null;
    displayedStatistics?: DisplayedStatistics | null;
    story?: string | null;
    availabilityWeekdayFrom?: number | null;
    availabilityWeekdayUntil?: number | null;
    availabilityWeekendsFrom?: number | null;
    availabilityWeekendsUntil?: number | null;
    planSlug?: string | null;
    deletedAt?: any | null;
    referralCouponId?: string | null;
    promotionalCredits?: number | null;
    impersonated: boolean;
    seenCompletedAt?: any | null;
    stepsCompleted?: boolean | null;
    lastOnlineAt?: any | null;
    badges: Array<{ id: string; type: UserBadgeType; achievedDate?: any | null }>;
    following?: Array<{ id: string }> | null;
    followers?: Array<{ id: string }> | null;
    occupation?: { id: string; name?: string | null } | null;
    availability?: Array<{ from: string; until: string }> | null;
  };
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  me: {
    id: string;
    email: string;
    avatarUrl?: string | null;
    subscriptionTier: SubscriptionTier;
    needSpeakingPartner?: boolean | null;
    cambridgeEnglishLevel?: number | null;
    completedPercentage: number;
    isVerified?: boolean | null;
    firstName?: string | null;
    nationality?: string | null;
    otherLanguage?: string | null;
    isComplete?: boolean | null;
    badge?: UserBadge | null;
    createdAt: any;
    lastName?: string | null;
    city?: string | null;
    country?: string | null;
    cityId?: string | null;
    countryId?: string | null;
    lat?: number | null;
    long?: number | null;
    story?: string | null;
    birthday?: any | null;
    englishSkills?: Array<EnglishSkill> | null;
    interests?: Array<Interest> | null;
    displayedStatistics?: DisplayedStatistics | null;
    showBirthday?: boolean | null;
    showBirthdayYear?: boolean | null;
    nativeLanguage?: string | null;
    name: string;
    wantsNewsletter?: boolean | null;
    completedHomeTour?: boolean | null;
    speakingPartners?: string | null;
    hasActivePlan: boolean;
    kind?: EventPermissions | null;
    timezone?: string | null;
    hasGoogleRefreshToken: boolean;
    availabilityWeekdayFrom?: number | null;
    availabilityWeekdayUntil?: number | null;
    availabilityWeekendsFrom?: number | null;
    availabilityWeekendsUntil?: number | null;
    planSlug?: string | null;
    deletedAt?: any | null;
    referralCouponId?: string | null;
    promotionalCredits?: number | null;
    impersonated: boolean;
    seenCompletedAt?: any | null;
    stepsCompleted?: boolean | null;
    occupation?: { id: string; name?: string | null } | null;
    badges: Array<{ id: string; type: UserBadgeType; achievedDate?: any | null }>;
    engagementMetrics?: {
      publishedArticles?: number | null;
      totalHostedParticipants?: number | null;
      attendanceMetrics?: {
        totalHostedEvents?: number | null;
        totalParticipatedEvents?: number | null;
        totalParticipatedScheduledEvents?: number | null;
        totalParticipationDuration?: number | null;
        totalParticipatedOneToOnes?: number | null;
        totalParticipatedGroup?: number | null;
        totalParticipatedWebinars?: number | null;
        totalParticipatedInPerson?: number | null;
        totalParticipatedOther?: number | null;
      } | null;
    } | null;
    following?: Array<{ id: string }> | null;
    followers?: Array<{ id: string }> | null;
    availability?: Array<{ from: string; until: string }> | null;
    photos: Array<{ id: string; url: string }>;
  };
};

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSettingsQuery = {
  getUserSettings: {
    id: string;
    receiveEventUpdatesAndInvitationsNotifications?: boolean | null;
    receiveEventUpdatesAndInvitationsEmails?: boolean | null;
    receiveEventRemindersNotifications?: boolean | null;
    receiveEventRemindersEmails?: boolean | null;
    receiveEventRsvpNotifications?: boolean | null;
    receiveEventRsvpEmails?: boolean | null;
    receiveFollowsNotifications?: boolean | null;
    receiveFollowsEmails?: boolean | null;
    receiveMentionsAndCommentsNotifications?: boolean | null;
    receiveMentionsAndCommentsEmails?: boolean | null;
    receiveChatMentionsNotifications?: boolean | null;
    receiveChatMentionsEmails?: boolean | null;
    receiveLiveTableStartedNotifications?: boolean | null;
    receivePlatformStatusEmails?: boolean | null;
    receiveSubscriptionChangesEmails?: boolean | null;
    emailNotifications?: EmailNotifications | null;
    updatedAt: any;
    createdAt: any;
  };
};

export type UserQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type UserQuery = {
  getUser: {
    id: string;
    email: string;
    avatarUrl?: string | null;
    needSpeakingPartner?: boolean | null;
    cambridgeEnglishLevel?: number | null;
    firstName?: string | null;
    otherLanguage?: string | null;
    isComplete?: boolean | null;
    badge?: UserBadge | null;
    createdAt: any;
    lastOnlineAt?: any | null;
    lastName?: string | null;
    city?: string | null;
    name: string;
    country?: string | null;
    story?: string | null;
    birthday?: any | null;
    englishSkills?: Array<EnglishSkill> | null;
    interests?: Array<Interest> | null;
    showBirthday?: boolean | null;
    nativeLanguage?: string | null;
    completedPercentage: number;
    occupation?: { id: string; name?: string | null } | null;
    followers?: Array<{ follower: { id: string; name: string } }> | null;
    availability?: Array<{ from: string; until: string }> | null;
    photos: Array<{ id: string; url: string }>;
  };
};

export type SimpleUserQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type SimpleUserQuery = { getUser: { id: string; firstName?: string | null } };

export type PlanDetailsAndPaymentMethodQueryVariables = Exact<{ [key: string]: never }>;

export type PlanDetailsAndPaymentMethodQuery = {
  planDetails?: {
    meta?: any | null;
    interval?: string | null;
    intervalCount?: number | null;
    name?: string | null;
    amount?: number | null;
    userAmount?: number | null;
    start?: any | null;
    end?: any | null;
    status?: SubscriptionStatus | null;
    resumesAt?: any | null;
    cancelledAt?: any | null;
  } | null;
  paymentMethod?: Array<{
    last4Digits?: string | null;
    expYear?: number | null;
    expMonth?: number | null;
    type: string;
    email?: string | null;
  }> | null;
  invoices?: Array<{
    id?: string | null;
    created?: any | null;
    description?: string | null;
    amount?: number | null;
    currency?: string | null;
    downloadUrl?: string | null;
    status?: string | null;
  }> | null;
};

export type CanPauseQueryVariables = Exact<{ [key: string]: never }>;

export type CanPauseQuery = { canPause: { canPause: boolean; pauseCredits: number } };

export type GetOccupationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOccupationsQuery = { getOccupations: Array<{ id: string; name?: string | null }> };

export type UserHasNoAcceptedInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type UserHasNoAcceptedInvitesQuery = { userHasNoAcceptedInvites: boolean };

export type AttendedEventsQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type AttendedEventsQuery = {
  attendedEvents: {
    oneToOnes?: number | null;
    groupEvents?: number | null;
    webinars?: number | null;
    inPersonEvents?: number | null;
    other?: number | null;
  };
};

export type AttendanceRecordQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type AttendanceRecordQuery = {
  attendanceRecord: {
    attendanceRatio?: number | null;
    totalRsvps?: number | null;
    attended?: number | null;
  };
};

export type ParticipationTimeQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type ParticipationTimeQuery = { participationTime: number };

export type GlobalNetworkQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type GlobalNetworkQuery = {
  globalNetwork: Array<{ lat?: number | null; long?: number | null }>;
};

export type FriendlyFacesQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type FriendlyFacesQuery = {
  friendlyFaces: Array<{
    userId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  }>;
};

export type ArticlesWrittenQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type ArticlesWrittenQuery = { articlesWritten: number };

export type DailyEventsQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type DailyEventsQuery = { dailyEvents: Array<{ day: any; count: number }> };

export type HostedEventsStatisticsQueryVariables = Exact<{
  input: StatisticsInput;
  prevInput: StatisticsInput;
}>;

export type HostedEventsStatisticsQuery = {
  hostedEvents: number;
  ladiesAttended: number;
  userHostedDistinctSparks: number;
  previousHostedEvents: number;
};

export type MonthlyRecapStepsQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type MonthlyRecapStepsQuery = { monthlyRecapSteps: Array<MonthlyRecapSteps> };

export type SeenMonthlyReportQueryVariables = Exact<{ [key: string]: never }>;

export type SeenMonthlyReportQuery = { seenMonthlyReport: boolean };

export type GetGroupedOccupationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGroupedOccupationsQuery = {
  getGroupedOccupations: Array<{
    id: string;
    name?: string | null;
    industry?: { id: string; name: string } | null;
  }>;
};

export type GetSparksQueryVariables = Exact<{
  input: StatisticsInput;
}>;

export type GetSparksQuery = {
  userDistinctSparks: number;
  userAttendedDistinctSparks: number;
  communityDistinctSparks: number;
};

export type ListRecommendedUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListRecommendedUsersQuery = {
  listRecommendedUsers: Array<{
    userId: string;
    user: {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      city?: string | null;
      country?: string | null;
      story?: string | null;
    };
  }>;
};

export type ListMatchedUsersQueryVariables = Exact<{
  filters: FilterUsersInput;
}>;

export type ListMatchedUsersQuery = {
  listMatchedUsers: {
    total: number;
    items?: Array<{
      userId: string;
      score?: number | null;
      defaultMatchScore?: number | null;
      user: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        badge?: UserBadge | null;
        createdAt: any;
        lastOnlineAt?: any | null;
        avatarUrl?: string | null;
        interests?: Array<Interest> | null;
        englishSkills?: Array<EnglishSkill> | null;
        city?: string | null;
        country?: string | null;
        cambridgeEnglishLevel?: number | null;
        availabilityWeekdayFrom?: number | null;
        availabilityWeekdayUntil?: number | null;
        availabilityWeekendsFrom?: number | null;
        availabilityWeekendsUntil?: number | null;
        needSpeakingPartner?: boolean | null;
        occupation?: { id: string; name?: string | null } | null;
        availability?: Array<{ from: string; until: string }> | null;
      };
      matchDetails?: {
        commonInterests?: Array<Interest> | null;
        commonEnglishSkills?: Array<EnglishSkill> | null;
        sameCity?: boolean | null;
        availabilityMatch?: AvailabilityStatus | null;
      } | null;
      matchScores?: {
        interestScore: number;
        englishInterestScore: number;
        englishLevelScore: number;
        availabilityScore: number;
        eventParticipationScore: number;
        locationScore: number;
      } | null;
      matchWeights?: {
        interestScore: number;
        englishInterestScore: number;
        englishLevelScore: number;
        availabilityScore: number;
        eventParticipationScore: number;
        locationScore: number;
      } | null;
    }> | null;
  };
};

export type GetUserMatchingInfoQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetUserMatchingInfoQuery = {
  getUserMatchingInfo: {
    userId: string;
    defaultMatchScore?: number | null;
    user: {
      id: string;
      availabilityWeekdayFrom?: number | null;
      availabilityWeekdayUntil?: number | null;
      availabilityWeekendsFrom?: number | null;
      availabilityWeekendsUntil?: number | null;
      email: string;
      avatarUrl?: string | null;
      needSpeakingPartner?: boolean | null;
      cambridgeEnglishLevel?: number | null;
      firstName?: string | null;
      nationality?: string | null;
      otherLanguage?: string | null;
      isComplete?: boolean | null;
      badge?: UserBadge | null;
      createdAt: any;
      lastOnlineAt?: any | null;
      lastName?: string | null;
      city?: string | null;
      name: string;
      country?: string | null;
      story?: string | null;
      birthday?: any | null;
      englishSkills?: Array<EnglishSkill> | null;
      interests?: Array<Interest> | null;
      displayedStatistics?: DisplayedStatistics | null;
      showBirthday?: boolean | null;
      showBirthdayYear?: boolean | null;
      nativeLanguage?: string | null;
      completedPercentage: number;
      occupation?: { id: string; name?: string | null } | null;
      badges: Array<{ id: string; type: UserBadgeType; achievedDate?: any | null }>;
      engagementMetrics?: {
        publishedArticles?: number | null;
        totalHostedParticipants?: number | null;
        attendanceMetrics?: {
          totalHostedEvents?: number | null;
          totalParticipatedEvents?: number | null;
          totalParticipatedScheduledEvents?: number | null;
          totalParticipationDuration?: number | null;
          totalParticipatedOneToOnes?: number | null;
          totalParticipatedGroup?: number | null;
          totalParticipatedWebinars?: number | null;
          totalParticipatedOther?: number | null;
        } | null;
      } | null;
      followers?: Array<{ follower: { id: string; name: string } }> | null;
      availability?: Array<{ from: string; until: string }> | null;
      photos: Array<{ id: string; url: string }>;
    };
    matchDetails?: {
      availabilityMatch?: AvailabilityStatus | null;
      commonInterests?: Array<Interest> | null;
      commonEnglishSkills?: Array<EnglishSkill> | null;
      sameCity?: boolean | null;
    } | null;
  };
};

export type GetAvalibilityMatchForUsersQueryVariables = Exact<{
  input: AvailabilityForUsersInput;
}>;

export type GetAvalibilityMatchForUsersQuery = {
  getAvalibilityMatchForUsers: Array<{
    userId: string;
    availabilityMatch?: AvailabilityStatus | null;
  }>;
};

export type CompletedVerificationFormQueryVariables = Exact<{ [key: string]: never }>;

export type CompletedVerificationFormQuery = { completedVerificationForm: Array<{ id: string }> };

export type GetVideoCallQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetVideoCallQuery = {
  getVideoCall: {
    id: string;
    title: string;
    dailyRoomId: string;
    closedDoorAt?: any | null;
    endedAt?: any | null;
    participants: Array<{ id: string; isHost: boolean; userId: string }>;
  };
};

export type GetChannelVideoCallQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetChannelVideoCallQuery = {
  getChannelVideoCall?: {
    id: string;
    title: string;
    dailyRoomId: string;
    closedDoorAt?: any | null;
    endedAt?: any | null;
  } | null;
};

export type GetVideosQueryVariables = Exact<{ [key: string]: never }>;

export type GetVideosQuery = {
  getVideos: Array<{
    id: string;
    title?: string | null;
    videoURL?: string | null;
    thumbnail?: string | null;
    isActive: boolean;
    position: number;
    completedByMe: boolean;
    set: { id: string };
  }>;
};

export type CommentCreatedSubscriptionVariables = Exact<{
  targetId: Scalars["String"]["input"];
}>;

export type CommentCreatedSubscription = {
  commentCreated: {
    id: string;
    parentID?: string | null;
    message: string;
    createdAt: any;
    author?: { id: string; name: string; avatarUrl?: string | null } | null;
    reactions?: Array<{
      id: string;
      unifiedId: string;
      user: {
        id: string;
        avatarUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    }> | null;
    replies?: Array<{
      id: string;
      message: string;
      createdAt: any;
      author?: { id: string; name: string; avatarUrl?: string | null } | null;
      reactions?: Array<{
        id: string;
        unifiedId: string;
        user: {
          id: string;
          avatarUrl?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        };
      }> | null;
    }> | null;
  };
};

export type CommentDeletedSubscriptionVariables = Exact<{
  targetId: Scalars["String"]["input"];
}>;

export type CommentDeletedSubscription = {
  commentDeleted: { id: string; parentID?: string | null };
};

export type ReactionCreatedSubscriptionVariables = Exact<{
  targetId: Scalars["String"]["input"];
}>;

export type ReactionCreatedSubscription = {
  reactionCreated: {
    id: string;
    unifiedId: string;
    user: {
      id: string;
      avatarUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
    comment: { id: string; parentID?: string | null };
  };
};

export type ReactionDeletedSubscriptionVariables = Exact<{
  targetId: Scalars["String"]["input"];
}>;

export type ReactionDeletedSubscription = {
  reactionDeleted: {
    id: string;
    unifiedId: string;
    user: {
      id: string;
      avatarUrl?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
    comment: { id: string; parentID?: string | null };
  };
};

export type EventUpdatedSubscriptionVariables = Exact<{
  eventId: Scalars["String"]["input"];
}>;

export type EventUpdatedSubscription = {
  eventUpdated: {
    id: string;
    closedDoorAt?: any | null;
    noParticipants?: number | null;
    participants: Array<{
      id: string;
      avatarUrl?: string | null;
      createdAt: any;
      firstName?: string | null;
      lastName?: string | null;
      isVerified?: boolean | null;
    }>;
    invites?: Array<{
      id: string;
      isHost?: boolean | null;
      status?: InviteStatus | null;
      user?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: any;
        badge?: UserBadge | null;
        avatarUrl?: string | null;
      } | null;
    }> | null;
  };
};

export type LiveTableAboutToEndSubscriptionVariables = Exact<{
  liveTableId: Scalars["String"]["input"];
}>;

export type LiveTableAboutToEndSubscription = {
  liveTableAboutToEnd: { id: string; lastSingleUserAt?: any | null };
};

export type LiveTableHasEndedSubscriptionVariables = Exact<{
  liveTableId: Scalars["String"]["input"];
}>;

export type LiveTableHasEndedSubscription = {
  liveTableHasEnded: { id: string; endedAt?: any | null };
};

export type LiveTableUpdatedSubscriptionVariables = Exact<{
  liveTableId: Scalars["String"]["input"];
}>;

export type LiveTableUpdatedSubscription = {
  liveTableUpdated: { id: string; title: string; englishLevel?: EventEnglishLevelNamed | null };
};

export type LiveTableHasStartedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type LiveTableHasStartedSubscription = {
  liveTableHasStarted: {
    name?: string | null;
    avatarUrl?: string | null;
    tableId: string;
    tableTitle: string;
    tableCreatorId: string;
  };
};

export type NotificationsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotificationsSubscriptionSubscription = {
  notificationAdded: {
    id: string;
    type: NotificationType;
    createdAt: any;
    notificationSeen?: Array<{ id: string; seenAt?: any | null }> | null;
    chatMentionNotification?: {
      id: string;
      channelId: string;
      chatMessageId: string;
      parentChatMessageId?: string | null;
      chatMessage?: string | null;
      actor?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    } | null;
    commentNotification?: {
      id: string;
      commentAction: CommentAction;
      comment: {
        id: string;
        targetId: string;
        targetType: CommentTargetType;
        targetArticle?: { title?: string | null } | null;
        targetEvent?: { title?: string | null } | null;
        targetTopic?: { title: string } | null;
      };
      actor?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
    } | null;
    eventNotification?: {
      id: string;
      action: EventNotificationAction;
      actor?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
      event: {
        id: string;
        startDate?: any | null;
        title?: string | null;
        kind?: EventKind | null;
        isDeleted?: boolean | null;
        host?: { id: string; name: string } | null;
      };
      invite?: { id: string; status?: InviteStatus | null } | null;
    } | null;
    articleNotification?: {
      id: string;
      articleAction: ArticleAction;
      article: {
        id: string;
        title?: string | null;
        author?: {
          id: string;
          avatarUrl?: string | null;
          name: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      };
    } | null;
  };
};

export type PopupNotificationsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PopupNotificationsSubscriptionSubscription = {
  popupNotificationAdded: {
    id: string;
    type: NotificationType;
    createdAt: any;
    notificationSeen?: Array<{ id: string; seenAt?: any | null }> | null;
    badgeNotification?: {
      id: string;
      badge: { id: string; type: UserBadgeType; achievedDate?: any | null };
    } | null;
    eventNotification?: {
      id: string;
      action: EventNotificationAction;
      actor?: {
        id: string;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      } | null;
      event: {
        id: string;
        startDate?: any | null;
        title?: string | null;
        kind?: EventKind | null;
        isDeleted?: boolean | null;
        host?: { id: string; name: string } | null;
      };
      invite?: { id: string; status?: InviteStatus | null } | null;
    } | null;
    profileUpdateNotification?: { id: string; type: ProfileUpdateNotificationType } | null;
    monthlyReportNotification?: { id: string; type: MonthlyReportNotificationType } | null;
    friendJoinedNotification?: {
      id: string;
      type: FriendJoinedNotificationType;
      user: { id: string; firstName?: string | null; lastName?: string | null };
      friend: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        avatarUrl?: string | null;
      };
    } | null;
  };
};

export type SparkCreatedSubscriptionVariables = Exact<{
  eventId: Scalars["String"]["input"];
}>;

export type SparkCreatedSubscription = {
  sparkCreated: { id: string; event?: { id: string } | null; user: { id: string } };
};

export type CommunitySparkCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CommunitySparkCreatedSubscription = { communitySparkCreated: { id: string } };

export type VideoAskFormCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type VideoAskFormCreatedSubscription = { videoAskFormCreated: { id: string } };

export type VideoCallUpdatedSubscriptionVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type VideoCallUpdatedSubscription = {
  videoCallUpdated: {
    id: string;
    title: string;
    dailyRoomId: string;
    closedDoorAt?: any | null;
    endedAt?: any | null;
  };
};

export type ChannelVideoCallSubscriptionVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ChannelVideoCallSubscription = {
  channelVideoCall: {
    id: string;
    title: string;
    dailyRoomId: string;
    closedDoorAt?: any | null;
    endedAt?: any | null;
  };
};

export const CommentNotificationFieldsFragmentDoc = gql`
    fragment CommentNotificationFields on CommentNotificationModel {
  id
  comment {
    id
    targetId
    targetType
    targetArticle {
      title
    }
    targetEvent {
      title
    }
    targetTopic {
      title
    }
  }
  commentAction
  actor {
    id
    name
    firstName
    lastName
    avatarUrl
  }
}
    `;
export const EventNotificationFieldsFragmentDoc = gql`
    fragment EventNotificationFields on EventNotificationModel {
  id
  action
  actor {
    id
    name
    firstName
    lastName
    avatarUrl
  }
  event {
    id
    startDate
    title
    kind
    isDeleted
    host {
      id
      name
    }
  }
  invite {
    id
    status
  }
}
    `;
export const ChatMentionNotificationFieldsFragmentDoc = gql`
    fragment ChatMentionNotificationFields on ChatMentionNotificationModel {
  id
  channelId
  chatMessageId
  parentChatMessageId
  chatMessage
  actor {
    id
    name
    firstName
    lastName
    avatarUrl
  }
}
    `;
export const BadgeNotificationFieldsFragmentDoc = gql`
    fragment BadgeNotificationFields on BadgeNotificationModel {
  id
  badge {
    id
    type
    achievedDate
  }
}
    `;
export const ProfileUpdateNotificationFieldsFragmentDoc = gql`
    fragment ProfileUpdateNotificationFields on ProfileUpdateNotificationModel {
  id
  type
}
    `;
export const ArticleNotificationFieldsFragmentDoc = gql`
    fragment ArticleNotificationFields on ArticleNotificationModel {
  id
  articleAction
  article {
    id
    title
    author {
      id
      avatarUrl
      name
      firstName
      lastName
    }
  }
}
    `;
export const MonthlyReportNotificationFieldsFragmentDoc = gql`
    fragment MonthlyReportNotificationFields on MonthlyReportNotificationModel {
  id
  type
}
    `;
export const FriendJoinedNotificationFieldsFragmentDoc = gql`
    fragment FriendJoinedNotificationFields on FriendJoinedNotificationModel {
  id
  user {
    id
    firstName
    lastName
  }
  friend {
    id
    firstName
    lastName
    avatarUrl
  }
  type
}
    `;
export const InitiateAssessmentDocument = gql`
    mutation initiateAssessment {
  initiateAssessment
}
    `;
export type InitiateAssessmentMutationFn = Apollo.MutationFunction<
  InitiateAssessmentMutation,
  InitiateAssessmentMutationVariables
>;

/**
 * __useInitiateAssessmentMutation__
 *
 * To run a mutation, you first call `useInitiateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAssessmentMutation, { data, loading, error }] = useInitiateAssessmentMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateAssessmentMutation,
    InitiateAssessmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitiateAssessmentMutation, InitiateAssessmentMutationVariables>(
    InitiateAssessmentDocument,
    options,
  );
}
export type InitiateAssessmentMutationHookResult = ReturnType<typeof useInitiateAssessmentMutation>;
export type InitiateAssessmentMutationResult = Apollo.MutationResult<InitiateAssessmentMutation>;
export type InitiateAssessmentMutationOptions = Apollo.BaseMutationOptions<
  InitiateAssessmentMutation,
  InitiateAssessmentMutationVariables
>;
export const ChatCreateChannelDocument = gql`
    mutation chatCreateChannel($input: CreateChannelInput!) {
  chatCreateChannel(input: $input)
}
    `;
export type ChatCreateChannelMutationFn = Apollo.MutationFunction<
  ChatCreateChannelMutation,
  ChatCreateChannelMutationVariables
>;

/**
 * __useChatCreateChannelMutation__
 *
 * To run a mutation, you first call `useChatCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatCreateChannelMutation, { data, loading, error }] = useChatCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCreateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatCreateChannelMutation,
    ChatCreateChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChatCreateChannelMutation, ChatCreateChannelMutationVariables>(
    ChatCreateChannelDocument,
    options,
  );
}
export type ChatCreateChannelMutationHookResult = ReturnType<typeof useChatCreateChannelMutation>;
export type ChatCreateChannelMutationResult = Apollo.MutationResult<ChatCreateChannelMutation>;
export type ChatCreateChannelMutationOptions = Apollo.BaseMutationOptions<
  ChatCreateChannelMutation,
  ChatCreateChannelMutationVariables
>;
export const ChatAddMembersToChannelDocument = gql`
    mutation chatAddMembersToChannel($id: String!, $input: CreateChannelInput!) {
  chatAddMembersToChannel(id: $id, input: $input) {
    status
  }
}
    `;
export type ChatAddMembersToChannelMutationFn = Apollo.MutationFunction<
  ChatAddMembersToChannelMutation,
  ChatAddMembersToChannelMutationVariables
>;

/**
 * __useChatAddMembersToChannelMutation__
 *
 * To run a mutation, you first call `useChatAddMembersToChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatAddMembersToChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatAddMembersToChannelMutation, { data, loading, error }] = useChatAddMembersToChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatAddMembersToChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatAddMembersToChannelMutation,
    ChatAddMembersToChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatAddMembersToChannelMutation,
    ChatAddMembersToChannelMutationVariables
  >(ChatAddMembersToChannelDocument, options);
}
export type ChatAddMembersToChannelMutationHookResult = ReturnType<
  typeof useChatAddMembersToChannelMutation
>;
export type ChatAddMembersToChannelMutationResult =
  Apollo.MutationResult<ChatAddMembersToChannelMutation>;
export type ChatAddMembersToChannelMutationOptions = Apollo.BaseMutationOptions<
  ChatAddMembersToChannelMutation,
  ChatAddMembersToChannelMutationVariables
>;
export const ChatRemoveMembersFromChannelDocument = gql`
    mutation chatRemoveMembersFromChannel($id: String!, $input: CreateChannelInput!) {
  chatRemoveMembersFromChannel(id: $id, input: $input) {
    status
  }
}
    `;
export type ChatRemoveMembersFromChannelMutationFn = Apollo.MutationFunction<
  ChatRemoveMembersFromChannelMutation,
  ChatRemoveMembersFromChannelMutationVariables
>;

/**
 * __useChatRemoveMembersFromChannelMutation__
 *
 * To run a mutation, you first call `useChatRemoveMembersFromChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatRemoveMembersFromChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRemoveMembersFromChannelMutation, { data, loading, error }] = useChatRemoveMembersFromChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatRemoveMembersFromChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatRemoveMembersFromChannelMutation,
    ChatRemoveMembersFromChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatRemoveMembersFromChannelMutation,
    ChatRemoveMembersFromChannelMutationVariables
  >(ChatRemoveMembersFromChannelDocument, options);
}
export type ChatRemoveMembersFromChannelMutationHookResult = ReturnType<
  typeof useChatRemoveMembersFromChannelMutation
>;
export type ChatRemoveMembersFromChannelMutationResult =
  Apollo.MutationResult<ChatRemoveMembersFromChannelMutation>;
export type ChatRemoveMembersFromChannelMutationOptions = Apollo.BaseMutationOptions<
  ChatRemoveMembersFromChannelMutation,
  ChatRemoveMembersFromChannelMutationVariables
>;
export const ChatRenameChannelDocument = gql`
    mutation chatRenameChannel($id: String!, $channelName: String!) {
  chatRenameChannel(id: $id, channelName: $channelName) {
    status
  }
}
    `;
export type ChatRenameChannelMutationFn = Apollo.MutationFunction<
  ChatRenameChannelMutation,
  ChatRenameChannelMutationVariables
>;

/**
 * __useChatRenameChannelMutation__
 *
 * To run a mutation, you first call `useChatRenameChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatRenameChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRenameChannelMutation, { data, loading, error }] = useChatRenameChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      channelName: // value for 'channelName'
 *   },
 * });
 */
export function useChatRenameChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatRenameChannelMutation,
    ChatRenameChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChatRenameChannelMutation, ChatRenameChannelMutationVariables>(
    ChatRenameChannelDocument,
    options,
  );
}
export type ChatRenameChannelMutationHookResult = ReturnType<typeof useChatRenameChannelMutation>;
export type ChatRenameChannelMutationResult = Apollo.MutationResult<ChatRenameChannelMutation>;
export type ChatRenameChannelMutationOptions = Apollo.BaseMutationOptions<
  ChatRenameChannelMutation,
  ChatRenameChannelMutationVariables
>;
export const GenerateGroupPictureUploadUrlDocument = gql`
    mutation generateGroupPictureUploadUrl($input: UploadPictureInput!) {
  generateGroupPictureUploadUrl(input: $input) {
    contentURL
    uploadURL
  }
}
    `;
export type GenerateGroupPictureUploadUrlMutationFn = Apollo.MutationFunction<
  GenerateGroupPictureUploadUrlMutation,
  GenerateGroupPictureUploadUrlMutationVariables
>;

/**
 * __useGenerateGroupPictureUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateGroupPictureUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGroupPictureUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGroupPictureUploadUrlMutation, { data, loading, error }] = useGenerateGroupPictureUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateGroupPictureUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateGroupPictureUploadUrlMutation,
    GenerateGroupPictureUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateGroupPictureUploadUrlMutation,
    GenerateGroupPictureUploadUrlMutationVariables
  >(GenerateGroupPictureUploadUrlDocument, options);
}
export type GenerateGroupPictureUploadUrlMutationHookResult = ReturnType<
  typeof useGenerateGroupPictureUploadUrlMutation
>;
export type GenerateGroupPictureUploadUrlMutationResult =
  Apollo.MutationResult<GenerateGroupPictureUploadUrlMutation>;
export type GenerateGroupPictureUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateGroupPictureUploadUrlMutation,
  GenerateGroupPictureUploadUrlMutationVariables
>;
export const SetGroupPictureUrlDocument = gql`
    mutation setGroupPictureUrl($input: SetPictureInput!) {
  setGroupPictureUrl(input: $input) {
    status
  }
}
    `;
export type SetGroupPictureUrlMutationFn = Apollo.MutationFunction<
  SetGroupPictureUrlMutation,
  SetGroupPictureUrlMutationVariables
>;

/**
 * __useSetGroupPictureUrlMutation__
 *
 * To run a mutation, you first call `useSetGroupPictureUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGroupPictureUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGroupPictureUrlMutation, { data, loading, error }] = useSetGroupPictureUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGroupPictureUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGroupPictureUrlMutation,
    SetGroupPictureUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetGroupPictureUrlMutation, SetGroupPictureUrlMutationVariables>(
    SetGroupPictureUrlDocument,
    options,
  );
}
export type SetGroupPictureUrlMutationHookResult = ReturnType<typeof useSetGroupPictureUrlMutation>;
export type SetGroupPictureUrlMutationResult = Apollo.MutationResult<SetGroupPictureUrlMutation>;
export type SetGroupPictureUrlMutationOptions = Apollo.BaseMutationOptions<
  SetGroupPictureUrlMutation,
  SetGroupPictureUrlMutationVariables
>;
export const CreateVideoCallDocument = gql`
    mutation createVideoCall($input: CreateVideoCallInput!) {
  createVideoCall(input: $input) {
    id
    title
    chatRoomId
    dailyRoomId
  }
}
    `;
export type CreateVideoCallMutationFn = Apollo.MutationFunction<
  CreateVideoCallMutation,
  CreateVideoCallMutationVariables
>;

/**
 * __useCreateVideoCallMutation__
 *
 * To run a mutation, you first call `useCreateVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoCallMutation, { data, loading, error }] = useCreateVideoCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVideoCallMutation,
    CreateVideoCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVideoCallMutation, CreateVideoCallMutationVariables>(
    CreateVideoCallDocument,
    options,
  );
}
export type CreateVideoCallMutationHookResult = ReturnType<typeof useCreateVideoCallMutation>;
export type CreateVideoCallMutationResult = Apollo.MutationResult<CreateVideoCallMutation>;
export type CreateVideoCallMutationOptions = Apollo.BaseMutationOptions<
  CreateVideoCallMutation,
  CreateVideoCallMutationVariables
>;
export const ChatSendDirectMessageDocument = gql`
    mutation chatSendDirectMessage($input: SendDirectChatMessageInput!) {
  chatSendDirectMessage(input: $input) {
    status
  }
}
    `;
export type ChatSendDirectMessageMutationFn = Apollo.MutationFunction<
  ChatSendDirectMessageMutation,
  ChatSendDirectMessageMutationVariables
>;

/**
 * __useChatSendDirectMessageMutation__
 *
 * To run a mutation, you first call `useChatSendDirectMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatSendDirectMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatSendDirectMessageMutation, { data, loading, error }] = useChatSendDirectMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatSendDirectMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatSendDirectMessageMutation,
    ChatSendDirectMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChatSendDirectMessageMutation, ChatSendDirectMessageMutationVariables>(
    ChatSendDirectMessageDocument,
    options,
  );
}
export type ChatSendDirectMessageMutationHookResult = ReturnType<
  typeof useChatSendDirectMessageMutation
>;
export type ChatSendDirectMessageMutationResult =
  Apollo.MutationResult<ChatSendDirectMessageMutation>;
export type ChatSendDirectMessageMutationOptions = Apollo.BaseMutationOptions<
  ChatSendDirectMessageMutation,
  ChatSendDirectMessageMutationVariables
>;
export const CreateCommentDocument = gql`
    mutation createComment($targetId: String!, $targetType: CommentTargetType!, $input: CreateCommentInput!) {
  createComment(targetId: $targetId, targetType: $targetType, input: $input) {
    id
    createdAt
    message
    author {
      id
      name
      avatarUrl
    }
    replies {
      id
      createdAt
      message
      author {
        id
        name
        avatarUrl
      }
    }
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      targetType: // value for 'targetType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(
    CreateCommentDocument,
    options,
  );
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($commentID: String!) {
  deleteComment(commentID: $commentID) {
    status
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentID: // value for 'commentID'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(
    DeleteCommentDocument,
    options,
  );
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const CreateReactionDocument = gql`
    mutation createReaction($input: CreateReactionInput!) {
  createReaction(input: $input) {
    id
    unifiedId
  }
}
    `;
export type CreateReactionMutationFn = Apollo.MutationFunction<
  CreateReactionMutation,
  CreateReactionMutationVariables
>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReactionMutation, CreateReactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReactionMutation, CreateReactionMutationVariables>(
    CreateReactionDocument,
    options,
  );
}
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<CreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<
  CreateReactionMutation,
  CreateReactionMutationVariables
>;
export const DeleteReactionDocument = gql`
    mutation deleteReaction($id: String!) {
  deleteReaction(id: $id) {
    status
  }
}
    `;
export type DeleteReactionMutationFn = Apollo.MutationFunction<
  DeleteReactionMutation,
  DeleteReactionMutationVariables
>;

/**
 * __useDeleteReactionMutation__
 *
 * To run a mutation, you first call `useDeleteReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReactionMutation, { data, loading, error }] = useDeleteReactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteReactionMutation, DeleteReactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteReactionMutation, DeleteReactionMutationVariables>(
    DeleteReactionDocument,
    options,
  );
}
export type DeleteReactionMutationHookResult = ReturnType<typeof useDeleteReactionMutation>;
export type DeleteReactionMutationResult = Apollo.MutationResult<DeleteReactionMutation>;
export type DeleteReactionMutationOptions = Apollo.BaseMutationOptions<
  DeleteReactionMutation,
  DeleteReactionMutationVariables
>;
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const PublishEventDocument = gql`
    mutation publishEvent($id: String!) {
  publishEvent(id: $id) {
    id
  }
}
    `;
export type PublishEventMutationFn = Apollo.MutationFunction<
  PublishEventMutation,
  PublishEventMutationVariables
>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishEventMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishEventMutation, PublishEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishEventMutation, PublishEventMutationVariables>(
    PublishEventDocument,
    options,
  );
}
export type PublishEventMutationHookResult = ReturnType<typeof usePublishEventMutation>;
export type PublishEventMutationResult = Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<
  PublishEventMutation,
  PublishEventMutationVariables
>;
export const DuplicateEventDocument = gql`
    mutation duplicateEvent($id: String!) {
  duplicateEvent(id: $id) {
    id
  }
}
    `;
export type DuplicateEventMutationFn = Apollo.MutationFunction<
  DuplicateEventMutation,
  DuplicateEventMutationVariables
>;

/**
 * __useDuplicateEventMutation__
 *
 * To run a mutation, you first call `useDuplicateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEventMutation, { data, loading, error }] = useDuplicateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateEventMutation, DuplicateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateEventMutation, DuplicateEventMutationVariables>(
    DuplicateEventDocument,
    options,
  );
}
export type DuplicateEventMutationHookResult = ReturnType<typeof useDuplicateEventMutation>;
export type DuplicateEventMutationResult = Apollo.MutationResult<DuplicateEventMutation>;
export type DuplicateEventMutationOptions = Apollo.BaseMutationOptions<
  DuplicateEventMutation,
  DuplicateEventMutationVariables
>;
export const CreateInviteDocument = gql`
    mutation createInvite($input: CreateInviteInput!) {
  createInvite(input: $input) {
    id
    status
    isHost
    user {
      id
      name
      avatarUrl
    }
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<
  CreateInviteMutation,
  CreateInviteMutationVariables
>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(
    CreateInviteDocument,
    options,
  );
}
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<
  CreateInviteMutation,
  CreateInviteMutationVariables
>;
export const DeleteInviteDocument = gql`
    mutation deleteInvite($id: String!) {
  deleteInvite(inviteId: $id) {
    status
  }
}
    `;
export type DeleteInviteMutationFn = Apollo.MutationFunction<
  DeleteInviteMutation,
  DeleteInviteMutationVariables
>;

/**
 * __useDeleteInviteMutation__
 *
 * To run a mutation, you first call `useDeleteInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteMutation, { data, loading, error }] = useDeleteInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInviteMutation, DeleteInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(
    DeleteInviteDocument,
    options,
  );
}
export type DeleteInviteMutationHookResult = ReturnType<typeof useDeleteInviteMutation>;
export type DeleteInviteMutationResult = Apollo.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = Apollo.BaseMutationOptions<
  DeleteInviteMutation,
  DeleteInviteMutationVariables
>;
export const GenerateAttachmentUploadUrlDocument = gql`
    mutation generateAttachmentUploadUrl($filename: String!, $eventID: String!) {
  generateAttachmentUploadUrl(filename: $filename, eventID: $eventID) {
    contentURL
    uploadURL
  }
}
    `;
export type GenerateAttachmentUploadUrlMutationFn = Apollo.MutationFunction<
  GenerateAttachmentUploadUrlMutation,
  GenerateAttachmentUploadUrlMutationVariables
>;

/**
 * __useGenerateAttachmentUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateAttachmentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAttachmentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAttachmentUploadUrlMutation, { data, loading, error }] = useGenerateAttachmentUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      eventID: // value for 'eventID'
 *   },
 * });
 */
export function useGenerateAttachmentUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAttachmentUploadUrlMutation,
    GenerateAttachmentUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAttachmentUploadUrlMutation,
    GenerateAttachmentUploadUrlMutationVariables
  >(GenerateAttachmentUploadUrlDocument, options);
}
export type GenerateAttachmentUploadUrlMutationHookResult = ReturnType<
  typeof useGenerateAttachmentUploadUrlMutation
>;
export type GenerateAttachmentUploadUrlMutationResult =
  Apollo.MutationResult<GenerateAttachmentUploadUrlMutation>;
export type GenerateAttachmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateAttachmentUploadUrlMutation,
  GenerateAttachmentUploadUrlMutationVariables
>;
export const CreateAttachmentDocument = gql`
    mutation createAttachment($eventID: String!, $input: CreateEventAttachmentInput!) {
  createAttachment(eventID: $eventID, input: $input) {
    id
    url
    meta {
      name
      extension
      size
    }
  }
}
    `;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables
>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      eventID: // value for 'eventID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAttachmentMutation,
    CreateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(
    CreateAttachmentDocument,
    options,
  );
}
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables
>;
export const DeleteAttachmentDocument = gql`
    mutation deleteAttachment($attachmentID: String!) {
  deleteAttachment(attachmentID: $attachmentID) {
    id
  }
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      attachmentID: // value for 'attachmentID'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAttachmentMutation,
    DeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(
    DeleteAttachmentDocument,
    options,
  );
}
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables
>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: String!) {
  deleteEvent(id: $id) {
    status
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options,
  );
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const AttendEventDocument = gql`
    mutation attendEvent($input: AttendEventInput!) {
  attendEvent(input: $input) {
    id
    status
  }
}
    `;
export type AttendEventMutationFn = Apollo.MutationFunction<
  AttendEventMutation,
  AttendEventMutationVariables
>;

/**
 * __useAttendEventMutation__
 *
 * To run a mutation, you first call `useAttendEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendEventMutation, { data, loading, error }] = useAttendEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendEventMutation(
  baseOptions?: Apollo.MutationHookOptions<AttendEventMutation, AttendEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttendEventMutation, AttendEventMutationVariables>(
    AttendEventDocument,
    options,
  );
}
export type AttendEventMutationHookResult = ReturnType<typeof useAttendEventMutation>;
export type AttendEventMutationResult = Apollo.MutationResult<AttendEventMutation>;
export type AttendEventMutationOptions = Apollo.BaseMutationOptions<
  AttendEventMutation,
  AttendEventMutationVariables
>;
export const CancelAttendanceDocument = gql`
    mutation cancelAttendance($input: CancelAttendanceInput!) {
  cancelAttendance(input: $input) {
    id
    status
  }
}
    `;
export type CancelAttendanceMutationFn = Apollo.MutationFunction<
  CancelAttendanceMutation,
  CancelAttendanceMutationVariables
>;

/**
 * __useCancelAttendanceMutation__
 *
 * To run a mutation, you first call `useCancelAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAttendanceMutation, { data, loading, error }] = useCancelAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAttendanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAttendanceMutation,
    CancelAttendanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAttendanceMutation, CancelAttendanceMutationVariables>(
    CancelAttendanceDocument,
    options,
  );
}
export type CancelAttendanceMutationHookResult = ReturnType<typeof useCancelAttendanceMutation>;
export type CancelAttendanceMutationResult = Apollo.MutationResult<CancelAttendanceMutation>;
export type CancelAttendanceMutationOptions = Apollo.BaseMutationOptions<
  CancelAttendanceMutation,
  CancelAttendanceMutationVariables
>;
export const DeclineInviteDocument = gql`
    mutation declineInvite($input: DeclineInviteInput!) {
  declineInvite(input: $input) {
    id
    status
  }
}
    `;
export type DeclineInviteMutationFn = Apollo.MutationFunction<
  DeclineInviteMutation,
  DeclineInviteMutationVariables
>;

/**
 * __useDeclineInviteMutation__
 *
 * To run a mutation, you first call `useDeclineInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineInviteMutation, { data, loading, error }] = useDeclineInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineInviteMutation, DeclineInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineInviteMutation, DeclineInviteMutationVariables>(
    DeclineInviteDocument,
    options,
  );
}
export type DeclineInviteMutationHookResult = ReturnType<typeof useDeclineInviteMutation>;
export type DeclineInviteMutationResult = Apollo.MutationResult<DeclineInviteMutation>;
export type DeclineInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclineInviteMutation,
  DeclineInviteMutationVariables
>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $input: UpdateEventInput!) {
  updateEvent(id: $id, input: $input) {
    title
    description
    kind
    conversationKind
    startDate
    endDate
    host {
      id
      firstName
      lastName
      avatarUrl
    }
    topic {
      id
    }
    organizer {
      id
      firstName
      lastName
      avatarUrl
    }
    isPublic
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options,
  );
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const CloseDoorDocument = gql`
    mutation closeDoor($id: String!) {
  closeDoor(id: $id) {
    id
    closedDoorAt
  }
}
    `;
export type CloseDoorMutationFn = Apollo.MutationFunction<
  CloseDoorMutation,
  CloseDoorMutationVariables
>;

/**
 * __useCloseDoorMutation__
 *
 * To run a mutation, you first call `useCloseDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeDoorMutation, { data, loading, error }] = useCloseDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseDoorMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseDoorMutation, CloseDoorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseDoorMutation, CloseDoorMutationVariables>(
    CloseDoorDocument,
    options,
  );
}
export type CloseDoorMutationHookResult = ReturnType<typeof useCloseDoorMutation>;
export type CloseDoorMutationResult = Apollo.MutationResult<CloseDoorMutation>;
export type CloseDoorMutationOptions = Apollo.BaseMutationOptions<
  CloseDoorMutation,
  CloseDoorMutationVariables
>;
export const OpenDoorDocument = gql`
    mutation openDoor($id: String!) {
  openDoor(id: $id) {
    id
    closedDoorAt
  }
}
    `;
export type OpenDoorMutationFn = Apollo.MutationFunction<
  OpenDoorMutation,
  OpenDoorMutationVariables
>;

/**
 * __useOpenDoorMutation__
 *
 * To run a mutation, you first call `useOpenDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openDoorMutation, { data, loading, error }] = useOpenDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenDoorMutation(
  baseOptions?: Apollo.MutationHookOptions<OpenDoorMutation, OpenDoorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpenDoorMutation, OpenDoorMutationVariables>(OpenDoorDocument, options);
}
export type OpenDoorMutationHookResult = ReturnType<typeof useOpenDoorMutation>;
export type OpenDoorMutationResult = Apollo.MutationResult<OpenDoorMutation>;
export type OpenDoorMutationOptions = Apollo.BaseMutationOptions<
  OpenDoorMutation,
  OpenDoorMutationVariables
>;
export const AddEventToGoogleCalendarDocument = gql`
    mutation addEventToGoogleCalendar($input: AddEventCalendarInput!) {
  addEventToGoogleCalendar(input: $input) {
    status
    url
    reason
  }
}
    `;
export type AddEventToGoogleCalendarMutationFn = Apollo.MutationFunction<
  AddEventToGoogleCalendarMutation,
  AddEventToGoogleCalendarMutationVariables
>;

/**
 * __useAddEventToGoogleCalendarMutation__
 *
 * To run a mutation, you first call `useAddEventToGoogleCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventToGoogleCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventToGoogleCalendarMutation, { data, loading, error }] = useAddEventToGoogleCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventToGoogleCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventToGoogleCalendarMutation,
    AddEventToGoogleCalendarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventToGoogleCalendarMutation,
    AddEventToGoogleCalendarMutationVariables
  >(AddEventToGoogleCalendarDocument, options);
}
export type AddEventToGoogleCalendarMutationHookResult = ReturnType<
  typeof useAddEventToGoogleCalendarMutation
>;
export type AddEventToGoogleCalendarMutationResult =
  Apollo.MutationResult<AddEventToGoogleCalendarMutation>;
export type AddEventToGoogleCalendarMutationOptions = Apollo.BaseMutationOptions<
  AddEventToGoogleCalendarMutation,
  AddEventToGoogleCalendarMutationVariables
>;
export const EventMeetingTokenDocument = gql`
    mutation eventMeetingToken($input: DailyMeetingInput!) {
  eventMeetingToken(input: $input) {
    token
    exp
  }
}
    `;
export type EventMeetingTokenMutationFn = Apollo.MutationFunction<
  EventMeetingTokenMutation,
  EventMeetingTokenMutationVariables
>;

/**
 * __useEventMeetingTokenMutation__
 *
 * To run a mutation, you first call `useEventMeetingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventMeetingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventMeetingTokenMutation, { data, loading, error }] = useEventMeetingTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventMeetingTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventMeetingTokenMutation,
    EventMeetingTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventMeetingTokenMutation, EventMeetingTokenMutationVariables>(
    EventMeetingTokenDocument,
    options,
  );
}
export type EventMeetingTokenMutationHookResult = ReturnType<typeof useEventMeetingTokenMutation>;
export type EventMeetingTokenMutationResult = Apollo.MutationResult<EventMeetingTokenMutation>;
export type EventMeetingTokenMutationOptions = Apollo.BaseMutationOptions<
  EventMeetingTokenMutation,
  EventMeetingTokenMutationVariables
>;
export const MarkJoinEventDocument = gql`
    mutation markJoinEvent($eventId: String!) {
  markJoinEvent(eventId: $eventId) {
    status
  }
}
    `;
export type MarkJoinEventMutationFn = Apollo.MutationFunction<
  MarkJoinEventMutation,
  MarkJoinEventMutationVariables
>;

/**
 * __useMarkJoinEventMutation__
 *
 * To run a mutation, you first call `useMarkJoinEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkJoinEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markJoinEventMutation, { data, loading, error }] = useMarkJoinEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useMarkJoinEventMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkJoinEventMutation, MarkJoinEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkJoinEventMutation, MarkJoinEventMutationVariables>(
    MarkJoinEventDocument,
    options,
  );
}
export type MarkJoinEventMutationHookResult = ReturnType<typeof useMarkJoinEventMutation>;
export type MarkJoinEventMutationResult = Apollo.MutationResult<MarkJoinEventMutation>;
export type MarkJoinEventMutationOptions = Apollo.BaseMutationOptions<
  MarkJoinEventMutation,
  MarkJoinEventMutationVariables
>;
export const SaveParticipantSessionDocument = gql`
    mutation saveParticipantSession($participantId: String!, $meetingSessionId: String!) {
  saveParticipantSession(
    participantId: $participantId
    meetingSessionId: $meetingSessionId
  ) {
    status
  }
}
    `;
export type SaveParticipantSessionMutationFn = Apollo.MutationFunction<
  SaveParticipantSessionMutation,
  SaveParticipantSessionMutationVariables
>;

/**
 * __useSaveParticipantSessionMutation__
 *
 * To run a mutation, you first call `useSaveParticipantSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveParticipantSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveParticipantSessionMutation, { data, loading, error }] = useSaveParticipantSessionMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      meetingSessionId: // value for 'meetingSessionId'
 *   },
 * });
 */
export function useSaveParticipantSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveParticipantSessionMutation,
    SaveParticipantSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveParticipantSessionMutation,
    SaveParticipantSessionMutationVariables
  >(SaveParticipantSessionDocument, options);
}
export type SaveParticipantSessionMutationHookResult = ReturnType<
  typeof useSaveParticipantSessionMutation
>;
export type SaveParticipantSessionMutationResult =
  Apollo.MutationResult<SaveParticipantSessionMutation>;
export type SaveParticipantSessionMutationOptions = Apollo.BaseMutationOptions<
  SaveParticipantSessionMutation,
  SaveParticipantSessionMutationVariables
>;
export const UploadEventDescriptionImageDocument = gql`
    mutation uploadEventDescriptionImage($id: String!, $input: UploadEventImageInput!) {
  uploadEventDescriptionImage(id: $id, input: $input) {
    contentURL
  }
}
    `;
export type UploadEventDescriptionImageMutationFn = Apollo.MutationFunction<
  UploadEventDescriptionImageMutation,
  UploadEventDescriptionImageMutationVariables
>;

/**
 * __useUploadEventDescriptionImageMutation__
 *
 * To run a mutation, you first call `useUploadEventDescriptionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEventDescriptionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEventDescriptionImageMutation, { data, loading, error }] = useUploadEventDescriptionImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadEventDescriptionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadEventDescriptionImageMutation,
    UploadEventDescriptionImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadEventDescriptionImageMutation,
    UploadEventDescriptionImageMutationVariables
  >(UploadEventDescriptionImageDocument, options);
}
export type UploadEventDescriptionImageMutationHookResult = ReturnType<
  typeof useUploadEventDescriptionImageMutation
>;
export type UploadEventDescriptionImageMutationResult =
  Apollo.MutationResult<UploadEventDescriptionImageMutation>;
export type UploadEventDescriptionImageMutationOptions = Apollo.BaseMutationOptions<
  UploadEventDescriptionImageMutation,
  UploadEventDescriptionImageMutationVariables
>;
export const DeleteInvitesDocument = gql`
    mutation deleteInvites($input: DeleteInvitesInput!) {
  deleteInvites(input: $input) {
    status
  }
}
    `;
export type DeleteInvitesMutationFn = Apollo.MutationFunction<
  DeleteInvitesMutation,
  DeleteInvitesMutationVariables
>;

/**
 * __useDeleteInvitesMutation__
 *
 * To run a mutation, you first call `useDeleteInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitesMutation, { data, loading, error }] = useDeleteInvitesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvitesMutation, DeleteInvitesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitesMutation, DeleteInvitesMutationVariables>(
    DeleteInvitesDocument,
    options,
  );
}
export type DeleteInvitesMutationHookResult = ReturnType<typeof useDeleteInvitesMutation>;
export type DeleteInvitesMutationResult = Apollo.MutationResult<DeleteInvitesMutation>;
export type DeleteInvitesMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitesMutation,
  DeleteInvitesMutationVariables
>;
export const ConfirmInPersonEventDocument = gql`
    mutation confirmInPersonEvent($id: String!, $userIds: [String!]!) {
  confirmInPersonEvent(id: $id, userIds: $userIds) {
    status
  }
}
    `;
export type ConfirmInPersonEventMutationFn = Apollo.MutationFunction<
  ConfirmInPersonEventMutation,
  ConfirmInPersonEventMutationVariables
>;

/**
 * __useConfirmInPersonEventMutation__
 *
 * To run a mutation, you first call `useConfirmInPersonEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmInPersonEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmInPersonEventMutation, { data, loading, error }] = useConfirmInPersonEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useConfirmInPersonEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmInPersonEventMutation,
    ConfirmInPersonEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmInPersonEventMutation, ConfirmInPersonEventMutationVariables>(
    ConfirmInPersonEventDocument,
    options,
  );
}
export type ConfirmInPersonEventMutationHookResult = ReturnType<
  typeof useConfirmInPersonEventMutation
>;
export type ConfirmInPersonEventMutationResult =
  Apollo.MutationResult<ConfirmInPersonEventMutation>;
export type ConfirmInPersonEventMutationOptions = Apollo.BaseMutationOptions<
  ConfirmInPersonEventMutation,
  ConfirmInPersonEventMutationVariables
>;
export const ShareEventDocument = gql`
    mutation shareEvent($eventId: String!, $userIds: [String!]!) {
  shareEvent(eventId: $eventId, userIds: $userIds) {
    status
  }
}
    `;
export type ShareEventMutationFn = Apollo.MutationFunction<
  ShareEventMutation,
  ShareEventMutationVariables
>;

/**
 * __useShareEventMutation__
 *
 * To run a mutation, you first call `useShareEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareEventMutation, { data, loading, error }] = useShareEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useShareEventMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareEventMutation, ShareEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareEventMutation, ShareEventMutationVariables>(
    ShareEventDocument,
    options,
  );
}
export type ShareEventMutationHookResult = ReturnType<typeof useShareEventMutation>;
export type ShareEventMutationResult = Apollo.MutationResult<ShareEventMutation>;
export type ShareEventMutationOptions = Apollo.BaseMutationOptions<
  ShareEventMutation,
  ShareEventMutationVariables
>;
export const CreateLiveTableDocument = gql`
    mutation createLiveTable($input: CreateLiveTableInput!) {
  createLiveTable(input: $input) {
    id
    title
    dailyRoomId
  }
}
    `;
export type CreateLiveTableMutationFn = Apollo.MutationFunction<
  CreateLiveTableMutation,
  CreateLiveTableMutationVariables
>;

/**
 * __useCreateLiveTableMutation__
 *
 * To run a mutation, you first call `useCreateLiveTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveTableMutation, { data, loading, error }] = useCreateLiveTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLiveTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLiveTableMutation,
    CreateLiveTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLiveTableMutation, CreateLiveTableMutationVariables>(
    CreateLiveTableDocument,
    options,
  );
}
export type CreateLiveTableMutationHookResult = ReturnType<typeof useCreateLiveTableMutation>;
export type CreateLiveTableMutationResult = Apollo.MutationResult<CreateLiveTableMutation>;
export type CreateLiveTableMutationOptions = Apollo.BaseMutationOptions<
  CreateLiveTableMutation,
  CreateLiveTableMutationVariables
>;
export const UpdateLiveTableDocument = gql`
    mutation updateLiveTable($input: UpdateLiveTableInput!, $id: String!) {
  updateLiveTable(input: $input, id: $id) {
    status
  }
}
    `;
export type UpdateLiveTableMutationFn = Apollo.MutationFunction<
  UpdateLiveTableMutation,
  UpdateLiveTableMutationVariables
>;

/**
 * __useUpdateLiveTableMutation__
 *
 * To run a mutation, you first call `useUpdateLiveTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveTableMutation, { data, loading, error }] = useUpdateLiveTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLiveTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLiveTableMutation,
    UpdateLiveTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLiveTableMutation, UpdateLiveTableMutationVariables>(
    UpdateLiveTableDocument,
    options,
  );
}
export type UpdateLiveTableMutationHookResult = ReturnType<typeof useUpdateLiveTableMutation>;
export type UpdateLiveTableMutationResult = Apollo.MutationResult<UpdateLiveTableMutation>;
export type UpdateLiveTableMutationOptions = Apollo.BaseMutationOptions<
  UpdateLiveTableMutation,
  UpdateLiveTableMutationVariables
>;
export const LiveTableMeetingTokenDocument = gql`
    mutation liveTableMeetingToken($input: DailyMeetingInput!) {
  liveTableMeetingToken(input: $input) {
    token
    exp
  }
}
    `;
export type LiveTableMeetingTokenMutationFn = Apollo.MutationFunction<
  LiveTableMeetingTokenMutation,
  LiveTableMeetingTokenMutationVariables
>;

/**
 * __useLiveTableMeetingTokenMutation__
 *
 * To run a mutation, you first call `useLiveTableMeetingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLiveTableMeetingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [liveTableMeetingTokenMutation, { data, loading, error }] = useLiveTableMeetingTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLiveTableMeetingTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LiveTableMeetingTokenMutation,
    LiveTableMeetingTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LiveTableMeetingTokenMutation, LiveTableMeetingTokenMutationVariables>(
    LiveTableMeetingTokenDocument,
    options,
  );
}
export type LiveTableMeetingTokenMutationHookResult = ReturnType<
  typeof useLiveTableMeetingTokenMutation
>;
export type LiveTableMeetingTokenMutationResult =
  Apollo.MutationResult<LiveTableMeetingTokenMutation>;
export type LiveTableMeetingTokenMutationOptions = Apollo.BaseMutationOptions<
  LiveTableMeetingTokenMutation,
  LiveTableMeetingTokenMutationVariables
>;
export const SendLiveTablesFeedbackDocument = gql`
    mutation sendLiveTablesFeedback($input: LiveTablesFeedbackInput!) {
  sendLiveTablesFeedback(input: $input) {
    status
  }
}
    `;
export type SendLiveTablesFeedbackMutationFn = Apollo.MutationFunction<
  SendLiveTablesFeedbackMutation,
  SendLiveTablesFeedbackMutationVariables
>;

/**
 * __useSendLiveTablesFeedbackMutation__
 *
 * To run a mutation, you first call `useSendLiveTablesFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLiveTablesFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLiveTablesFeedbackMutation, { data, loading, error }] = useSendLiveTablesFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLiveTablesFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendLiveTablesFeedbackMutation,
    SendLiveTablesFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendLiveTablesFeedbackMutation,
    SendLiveTablesFeedbackMutationVariables
  >(SendLiveTablesFeedbackDocument, options);
}
export type SendLiveTablesFeedbackMutationHookResult = ReturnType<
  typeof useSendLiveTablesFeedbackMutation
>;
export type SendLiveTablesFeedbackMutationResult =
  Apollo.MutationResult<SendLiveTablesFeedbackMutation>;
export type SendLiveTablesFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SendLiveTablesFeedbackMutation,
  SendLiveTablesFeedbackMutationVariables
>;
export const NotifyAboutStartedLiveTableDocument = gql`
    mutation notifyAboutStartedLiveTable($input: LiveTableStartedMessageInput!) {
  notifyAboutStartedLiveTable(input: $input) {
    status
  }
}
    `;
export type NotifyAboutStartedLiveTableMutationFn = Apollo.MutationFunction<
  NotifyAboutStartedLiveTableMutation,
  NotifyAboutStartedLiveTableMutationVariables
>;

/**
 * __useNotifyAboutStartedLiveTableMutation__
 *
 * To run a mutation, you first call `useNotifyAboutStartedLiveTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyAboutStartedLiveTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyAboutStartedLiveTableMutation, { data, loading, error }] = useNotifyAboutStartedLiveTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyAboutStartedLiveTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyAboutStartedLiveTableMutation,
    NotifyAboutStartedLiveTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotifyAboutStartedLiveTableMutation,
    NotifyAboutStartedLiveTableMutationVariables
  >(NotifyAboutStartedLiveTableDocument, options);
}
export type NotifyAboutStartedLiveTableMutationHookResult = ReturnType<
  typeof useNotifyAboutStartedLiveTableMutation
>;
export type NotifyAboutStartedLiveTableMutationResult =
  Apollo.MutationResult<NotifyAboutStartedLiveTableMutation>;
export type NotifyAboutStartedLiveTableMutationOptions = Apollo.BaseMutationOptions<
  NotifyAboutStartedLiveTableMutation,
  NotifyAboutStartedLiveTableMutationVariables
>;
export const DeleteArticleDocument = gql`
    mutation deleteArticle($id: String!) {
  deleteArticle(id: $id) {
    id
  }
}
    `;
export type DeleteArticleMutationFn = Apollo.MutationFunction<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(
    DeleteArticleDocument,
    options,
  );
}
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export const CreateArticleDocument = gql`
    mutation createArticle {
  createArticle {
    id
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(
    CreateArticleDocument,
    options,
  );
}
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export const PublishOrScheduleArticleDocument = gql`
    mutation publishOrScheduleArticle($id: String!, $input: PublishArticleInput!) {
  publishOrScheduleArticle(id: $id, input: $input) {
    id
  }
}
    `;
export type PublishOrScheduleArticleMutationFn = Apollo.MutationFunction<
  PublishOrScheduleArticleMutation,
  PublishOrScheduleArticleMutationVariables
>;

/**
 * __usePublishOrScheduleArticleMutation__
 *
 * To run a mutation, you first call `usePublishOrScheduleArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOrScheduleArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOrScheduleArticleMutation, { data, loading, error }] = usePublishOrScheduleArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishOrScheduleArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishOrScheduleArticleMutation,
    PublishOrScheduleArticleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishOrScheduleArticleMutation,
    PublishOrScheduleArticleMutationVariables
  >(PublishOrScheduleArticleDocument, options);
}
export type PublishOrScheduleArticleMutationHookResult = ReturnType<
  typeof usePublishOrScheduleArticleMutation
>;
export type PublishOrScheduleArticleMutationResult =
  Apollo.MutationResult<PublishOrScheduleArticleMutation>;
export type PublishOrScheduleArticleMutationOptions = Apollo.BaseMutationOptions<
  PublishOrScheduleArticleMutation,
  PublishOrScheduleArticleMutationVariables
>;
export const SaveArticleDocument = gql`
    mutation saveArticle($id: String!, $input: SaveDraftArticleInput!) {
  saveArticle(id: $id, input: $input) {
    id
    tags
  }
}
    `;
export type SaveArticleMutationFn = Apollo.MutationFunction<
  SaveArticleMutation,
  SaveArticleMutationVariables
>;

/**
 * __useSaveArticleMutation__
 *
 * To run a mutation, you first call `useSaveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveArticleMutation, { data, loading, error }] = useSaveArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveArticleMutation, SaveArticleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveArticleMutation, SaveArticleMutationVariables>(
    SaveArticleDocument,
    options,
  );
}
export type SaveArticleMutationHookResult = ReturnType<typeof useSaveArticleMutation>;
export type SaveArticleMutationResult = Apollo.MutationResult<SaveArticleMutation>;
export type SaveArticleMutationOptions = Apollo.BaseMutationOptions<
  SaveArticleMutation,
  SaveArticleMutationVariables
>;
export const DeleteLikeDocument = gql`
    mutation deleteLike($id: String!) {
  deleteLike(id: $id) {
    status
  }
}
    `;
export type DeleteLikeMutationFn = Apollo.MutationFunction<
  DeleteLikeMutation,
  DeleteLikeMutationVariables
>;

/**
 * __useDeleteLikeMutation__
 *
 * To run a mutation, you first call `useDeleteLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLikeMutation, { data, loading, error }] = useDeleteLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLikeMutation, DeleteLikeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLikeMutation, DeleteLikeMutationVariables>(
    DeleteLikeDocument,
    options,
  );
}
export type DeleteLikeMutationHookResult = ReturnType<typeof useDeleteLikeMutation>;
export type DeleteLikeMutationResult = Apollo.MutationResult<DeleteLikeMutation>;
export type DeleteLikeMutationOptions = Apollo.BaseMutationOptions<
  DeleteLikeMutation,
  DeleteLikeMutationVariables
>;
export const CreateLikeDocument = gql`
    mutation createLike($input: CreateLikeInput!) {
  createLike(input: $input) {
    id
  }
}
    `;
export type CreateLikeMutationFn = Apollo.MutationFunction<
  CreateLikeMutation,
  CreateLikeMutationVariables
>;

/**
 * __useCreateLikeMutation__
 *
 * To run a mutation, you first call `useCreateLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLikeMutation, { data, loading, error }] = useCreateLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLikeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLikeMutation, CreateLikeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLikeMutation, CreateLikeMutationVariables>(
    CreateLikeDocument,
    options,
  );
}
export type CreateLikeMutationHookResult = ReturnType<typeof useCreateLikeMutation>;
export type CreateLikeMutationResult = Apollo.MutationResult<CreateLikeMutation>;
export type CreateLikeMutationOptions = Apollo.BaseMutationOptions<
  CreateLikeMutation,
  CreateLikeMutationVariables
>;
export const GenerateArticlesAttachmentUploadUrlDocument = gql`
    mutation generateArticlesAttachmentUploadUrl($id: String!, $filename: String!) {
  generateArticlesAttachmentUploadUrl(articleId: $id, filename: $filename) {
    contentURL
    uploadURL
  }
}
    `;
export type GenerateArticlesAttachmentUploadUrlMutationFn = Apollo.MutationFunction<
  GenerateArticlesAttachmentUploadUrlMutation,
  GenerateArticlesAttachmentUploadUrlMutationVariables
>;

/**
 * __useGenerateArticlesAttachmentUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateArticlesAttachmentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateArticlesAttachmentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateArticlesAttachmentUploadUrlMutation, { data, loading, error }] = useGenerateArticlesAttachmentUploadUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGenerateArticlesAttachmentUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateArticlesAttachmentUploadUrlMutation,
    GenerateArticlesAttachmentUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateArticlesAttachmentUploadUrlMutation,
    GenerateArticlesAttachmentUploadUrlMutationVariables
  >(GenerateArticlesAttachmentUploadUrlDocument, options);
}
export type GenerateArticlesAttachmentUploadUrlMutationHookResult = ReturnType<
  typeof useGenerateArticlesAttachmentUploadUrlMutation
>;
export type GenerateArticlesAttachmentUploadUrlMutationResult =
  Apollo.MutationResult<GenerateArticlesAttachmentUploadUrlMutation>;
export type GenerateArticlesAttachmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateArticlesAttachmentUploadUrlMutation,
  GenerateArticlesAttachmentUploadUrlMutationVariables
>;
export const CreateArticleAttachmentDocument = gql`
    mutation createArticleAttachment($id: String!, $input: CreateArticlesAttachmentInput!) {
  createArticleAttachment(articleId: $id, input: $input) {
    id
    url
  }
}
    `;
export type CreateArticleAttachmentMutationFn = Apollo.MutationFunction<
  CreateArticleAttachmentMutation,
  CreateArticleAttachmentMutationVariables
>;

/**
 * __useCreateArticleAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateArticleAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleAttachmentMutation, { data, loading, error }] = useCreateArticleAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleAttachmentMutation,
    CreateArticleAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleAttachmentMutation,
    CreateArticleAttachmentMutationVariables
  >(CreateArticleAttachmentDocument, options);
}
export type CreateArticleAttachmentMutationHookResult = ReturnType<
  typeof useCreateArticleAttachmentMutation
>;
export type CreateArticleAttachmentMutationResult =
  Apollo.MutationResult<CreateArticleAttachmentMutation>;
export type CreateArticleAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleAttachmentMutation,
  CreateArticleAttachmentMutationVariables
>;
export const DeleteArticleAttachmentDocument = gql`
    mutation deleteArticleAttachment($id: String!) {
  deleteArticleAttachment(attachmentId: $id) {
    id
  }
}
    `;
export type DeleteArticleAttachmentMutationFn = Apollo.MutationFunction<
  DeleteArticleAttachmentMutation,
  DeleteArticleAttachmentMutationVariables
>;

/**
 * __useDeleteArticleAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteArticleAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleAttachmentMutation, { data, loading, error }] = useDeleteArticleAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleAttachmentMutation,
    DeleteArticleAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleAttachmentMutation,
    DeleteArticleAttachmentMutationVariables
  >(DeleteArticleAttachmentDocument, options);
}
export type DeleteArticleAttachmentMutationHookResult = ReturnType<
  typeof useDeleteArticleAttachmentMutation
>;
export type DeleteArticleAttachmentMutationResult =
  Apollo.MutationResult<DeleteArticleAttachmentMutation>;
export type DeleteArticleAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleAttachmentMutation,
  DeleteArticleAttachmentMutationVariables
>;
export const UploadArticleImageDocument = gql`
    mutation uploadArticleImage($input: UploadImageArticleInput!) {
  uploadArticleImage(input: $input) {
    contentURL
  }
}
    `;
export type UploadArticleImageMutationFn = Apollo.MutationFunction<
  UploadArticleImageMutation,
  UploadArticleImageMutationVariables
>;

/**
 * __useUploadArticleImageMutation__
 *
 * To run a mutation, you first call `useUploadArticleImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadArticleImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadArticleImageMutation, { data, loading, error }] = useUploadArticleImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadArticleImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadArticleImageMutation,
    UploadArticleImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadArticleImageMutation, UploadArticleImageMutationVariables>(
    UploadArticleImageDocument,
    options,
  );
}
export type UploadArticleImageMutationHookResult = ReturnType<typeof useUploadArticleImageMutation>;
export type UploadArticleImageMutationResult = Apollo.MutationResult<UploadArticleImageMutation>;
export type UploadArticleImageMutationOptions = Apollo.BaseMutationOptions<
  UploadArticleImageMutation,
  UploadArticleImageMutationVariables
>;
export const NotificationSeenDocument = gql`
    mutation notificationSeen($id: String!) {
  notificationSeen(id: $id) {
    id
    notificationSeen {
      id
      seenAt
    }
  }
}
    `;
export type NotificationSeenMutationFn = Apollo.MutationFunction<
  NotificationSeenMutation,
  NotificationSeenMutationVariables
>;

/**
 * __useNotificationSeenMutation__
 *
 * To run a mutation, you first call `useNotificationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSeenMutation, { data, loading, error }] = useNotificationSeenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSeenMutation,
    NotificationSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotificationSeenMutation, NotificationSeenMutationVariables>(
    NotificationSeenDocument,
    options,
  );
}
export type NotificationSeenMutationHookResult = ReturnType<typeof useNotificationSeenMutation>;
export type NotificationSeenMutationResult = Apollo.MutationResult<NotificationSeenMutation>;
export type NotificationSeenMutationOptions = Apollo.BaseMutationOptions<
  NotificationSeenMutation,
  NotificationSeenMutationVariables
>;
export const MarkAllAsSeenDocument = gql`
    mutation markAllAsSeen {
  markAllAsSeen {
    status
  }
}
    `;
export type MarkAllAsSeenMutationFn = Apollo.MutationFunction<
  MarkAllAsSeenMutation,
  MarkAllAsSeenMutationVariables
>;

/**
 * __useMarkAllAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsSeenMutation, { data, loading, error }] = useMarkAllAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAllAsSeenMutation, MarkAllAsSeenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAllAsSeenMutation, MarkAllAsSeenMutationVariables>(
    MarkAllAsSeenDocument,
    options,
  );
}
export type MarkAllAsSeenMutationHookResult = ReturnType<typeof useMarkAllAsSeenMutation>;
export type MarkAllAsSeenMutationResult = Apollo.MutationResult<MarkAllAsSeenMutation>;
export type MarkAllAsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkAllAsSeenMutation,
  MarkAllAsSeenMutationVariables
>;
export const SubscribePaymentsDocument = gql`
    mutation subscribePayments($input: SubscribeInput!) {
  subscribe(input: $input)
}
    `;
export type SubscribePaymentsMutationFn = Apollo.MutationFunction<
  SubscribePaymentsMutation,
  SubscribePaymentsMutationVariables
>;

/**
 * __useSubscribePaymentsMutation__
 *
 * To run a mutation, you first call `useSubscribePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribePaymentsMutation, { data, loading, error }] = useSubscribePaymentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribePaymentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribePaymentsMutation,
    SubscribePaymentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribePaymentsMutation, SubscribePaymentsMutationVariables>(
    SubscribePaymentsDocument,
    options,
  );
}
export type SubscribePaymentsMutationHookResult = ReturnType<typeof useSubscribePaymentsMutation>;
export type SubscribePaymentsMutationResult = Apollo.MutationResult<SubscribePaymentsMutation>;
export type SubscribePaymentsMutationOptions = Apollo.BaseMutationOptions<
  SubscribePaymentsMutation,
  SubscribePaymentsMutationVariables
>;
export const GetHostedPageDocument = gql`
    mutation getHostedPage($id: String!) {
  getHostedPage(id: $id)
}
    `;
export type GetHostedPageMutationFn = Apollo.MutationFunction<
  GetHostedPageMutation,
  GetHostedPageMutationVariables
>;

/**
 * __useGetHostedPageMutation__
 *
 * To run a mutation, you first call `useGetHostedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetHostedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getHostedPageMutation, { data, loading, error }] = useGetHostedPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHostedPageMutation(
  baseOptions?: Apollo.MutationHookOptions<GetHostedPageMutation, GetHostedPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetHostedPageMutation, GetHostedPageMutationVariables>(
    GetHostedPageDocument,
    options,
  );
}
export type GetHostedPageMutationHookResult = ReturnType<typeof useGetHostedPageMutation>;
export type GetHostedPageMutationResult = Apollo.MutationResult<GetHostedPageMutation>;
export type GetHostedPageMutationOptions = Apollo.BaseMutationOptions<
  GetHostedPageMutation,
  GetHostedPageMutationVariables
>;
export const SwitchDocument = gql`
    mutation switch($input: SubscribeInput!) {
  switch(input: $input)
}
    `;
export type SwitchMutationFn = Apollo.MutationFunction<SwitchMutation, SwitchMutationVariables>;

/**
 * __useSwitchMutation__
 *
 * To run a mutation, you first call `useSwitchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchMutation, { data, loading, error }] = useSwitchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchMutation(
  baseOptions?: Apollo.MutationHookOptions<SwitchMutation, SwitchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwitchMutation, SwitchMutationVariables>(SwitchDocument, options);
}
export type SwitchMutationHookResult = ReturnType<typeof useSwitchMutation>;
export type SwitchMutationResult = Apollo.MutationResult<SwitchMutation>;
export type SwitchMutationOptions = Apollo.BaseMutationOptions<
  SwitchMutation,
  SwitchMutationVariables
>;
export const ChooseSubscriptionDocument = gql`
    mutation chooseSubscription($input: SubscribeInput!) {
  chooseSubscription(input: $input)
}
    `;
export type ChooseSubscriptionMutationFn = Apollo.MutationFunction<
  ChooseSubscriptionMutation,
  ChooseSubscriptionMutationVariables
>;

/**
 * __useChooseSubscriptionMutation__
 *
 * To run a mutation, you first call `useChooseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseSubscriptionMutation, { data, loading, error }] = useChooseSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChooseSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChooseSubscriptionMutation,
    ChooseSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChooseSubscriptionMutation, ChooseSubscriptionMutationVariables>(
    ChooseSubscriptionDocument,
    options,
  );
}
export type ChooseSubscriptionMutationHookResult = ReturnType<typeof useChooseSubscriptionMutation>;
export type ChooseSubscriptionMutationResult = Apollo.MutationResult<ChooseSubscriptionMutation>;
export type ChooseSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ChooseSubscriptionMutation,
  ChooseSubscriptionMutationVariables
>;
export const ScheduleCancelSubscriptionDocument = gql`
    mutation scheduleCancelSubscription($input: RequestRefundInput!) {
  scheduleCancelSubscription(input: $input) {
    status
  }
}
    `;
export type ScheduleCancelSubscriptionMutationFn = Apollo.MutationFunction<
  ScheduleCancelSubscriptionMutation,
  ScheduleCancelSubscriptionMutationVariables
>;

/**
 * __useScheduleCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useScheduleCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleCancelSubscriptionMutation, { data, loading, error }] = useScheduleCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleCancelSubscriptionMutation,
    ScheduleCancelSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduleCancelSubscriptionMutation,
    ScheduleCancelSubscriptionMutationVariables
  >(ScheduleCancelSubscriptionDocument, options);
}
export type ScheduleCancelSubscriptionMutationHookResult = ReturnType<
  typeof useScheduleCancelSubscriptionMutation
>;
export type ScheduleCancelSubscriptionMutationResult =
  Apollo.MutationResult<ScheduleCancelSubscriptionMutation>;
export type ScheduleCancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ScheduleCancelSubscriptionMutation,
  ScheduleCancelSubscriptionMutationVariables
>;
export const CancelScheduleCancelSubscriptionDocument = gql`
    mutation cancelScheduleCancelSubscription {
  cancelScheduleCancelSubscription {
    status
  }
}
    `;
export type CancelScheduleCancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelScheduleCancelSubscriptionMutation,
  CancelScheduleCancelSubscriptionMutationVariables
>;

/**
 * __useCancelScheduleCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelScheduleCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduleCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduleCancelSubscriptionMutation, { data, loading, error }] = useCancelScheduleCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelScheduleCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduleCancelSubscriptionMutation,
    CancelScheduleCancelSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelScheduleCancelSubscriptionMutation,
    CancelScheduleCancelSubscriptionMutationVariables
  >(CancelScheduleCancelSubscriptionDocument, options);
}
export type CancelScheduleCancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelScheduleCancelSubscriptionMutation
>;
export type CancelScheduleCancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelScheduleCancelSubscriptionMutation>;
export type CancelScheduleCancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelScheduleCancelSubscriptionMutation,
  CancelScheduleCancelSubscriptionMutationVariables
>;
export const RequestAssistanceDocument = gql`
    mutation requestAssistance($input: AssistanceReasonsInput!) {
  requestAssistance(input: $input) {
    status
  }
}
    `;
export type RequestAssistanceMutationFn = Apollo.MutationFunction<
  RequestAssistanceMutation,
  RequestAssistanceMutationVariables
>;

/**
 * __useRequestAssistanceMutation__
 *
 * To run a mutation, you first call `useRequestAssistanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAssistanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAssistanceMutation, { data, loading, error }] = useRequestAssistanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAssistanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestAssistanceMutation,
    RequestAssistanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestAssistanceMutation, RequestAssistanceMutationVariables>(
    RequestAssistanceDocument,
    options,
  );
}
export type RequestAssistanceMutationHookResult = ReturnType<typeof useRequestAssistanceMutation>;
export type RequestAssistanceMutationResult = Apollo.MutationResult<RequestAssistanceMutation>;
export type RequestAssistanceMutationOptions = Apollo.BaseMutationOptions<
  RequestAssistanceMutation,
  RequestAssistanceMutationVariables
>;
export const GetPrecancelPageDocument = gql`
    mutation getPrecancelPage {
  getPrecancelPage
}
    `;
export type GetPrecancelPageMutationFn = Apollo.MutationFunction<
  GetPrecancelPageMutation,
  GetPrecancelPageMutationVariables
>;

/**
 * __useGetPrecancelPageMutation__
 *
 * To run a mutation, you first call `useGetPrecancelPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPrecancelPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPrecancelPageMutation, { data, loading, error }] = useGetPrecancelPageMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetPrecancelPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPrecancelPageMutation,
    GetPrecancelPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetPrecancelPageMutation, GetPrecancelPageMutationVariables>(
    GetPrecancelPageDocument,
    options,
  );
}
export type GetPrecancelPageMutationHookResult = ReturnType<typeof useGetPrecancelPageMutation>;
export type GetPrecancelPageMutationResult = Apollo.MutationResult<GetPrecancelPageMutation>;
export type GetPrecancelPageMutationOptions = Apollo.BaseMutationOptions<
  GetPrecancelPageMutation,
  GetPrecancelPageMutationVariables
>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod {
  updatePaymentMethod
}
    `;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(
    UpdatePaymentMethodDocument,
    options,
  );
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;
export const ActivateTrialSubscriptionDocument = gql`
    mutation activateTrialSubscription {
  activateTrialSubscription {
    status
  }
}
    `;
export type ActivateTrialSubscriptionMutationFn = Apollo.MutationFunction<
  ActivateTrialSubscriptionMutation,
  ActivateTrialSubscriptionMutationVariables
>;

/**
 * __useActivateTrialSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateTrialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTrialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTrialSubscriptionMutation, { data, loading, error }] = useActivateTrialSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateTrialSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateTrialSubscriptionMutation,
    ActivateTrialSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateTrialSubscriptionMutation,
    ActivateTrialSubscriptionMutationVariables
  >(ActivateTrialSubscriptionDocument, options);
}
export type ActivateTrialSubscriptionMutationHookResult = ReturnType<
  typeof useActivateTrialSubscriptionMutation
>;
export type ActivateTrialSubscriptionMutationResult =
  Apollo.MutationResult<ActivateTrialSubscriptionMutation>;
export type ActivateTrialSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ActivateTrialSubscriptionMutation,
  ActivateTrialSubscriptionMutationVariables
>;
export const CreateSparkDocument = gql`
    mutation createSpark($input: CreateSparkInput!) {
  createSpark(input: $input) {
    status
  }
}
    `;
export type CreateSparkMutationFn = Apollo.MutationFunction<
  CreateSparkMutation,
  CreateSparkMutationVariables
>;

/**
 * __useCreateSparkMutation__
 *
 * To run a mutation, you first call `useCreateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSparkMutation, { data, loading, error }] = useCreateSparkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSparkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSparkMutation, CreateSparkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSparkMutation, CreateSparkMutationVariables>(
    CreateSparkDocument,
    options,
  );
}
export type CreateSparkMutationHookResult = ReturnType<typeof useCreateSparkMutation>;
export type CreateSparkMutationResult = Apollo.MutationResult<CreateSparkMutation>;
export type CreateSparkMutationOptions = Apollo.BaseMutationOptions<
  CreateSparkMutation,
  CreateSparkMutationVariables
>;
export const ActivateUserDocument = gql`
    mutation activateUser($input: ActivateUserInput!) {
  activateUser(input: $input) {
    status
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(
    ActivateUserDocument,
    options,
  );
}
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;
export const ResendConfirmationEmailDocument = gql`
    mutation resendConfirmationEmail($input: ResendConfirmationEmailInput!) {
  resendConfirmationEmail(input: $input) {
    status
  }
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >(ResendConfirmationEmailDocument, options);
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<
  typeof useResendConfirmationEmailMutation
>;
export type ResendConfirmationEmailMutationResult =
  Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const VerifyUserDocument = gql`
    mutation verifyUser($id: String!, $input: VerifyUserInput!) {
  verifyUser(id: $id, input: $input) {
    status
  }
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<
  VerifyUserMutation,
  VerifyUserMutationVariables
>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(
    VerifyUserDocument,
    options,
  );
}
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserMutation,
  VerifyUserMutationVariables
>;
export const HeartbeatDocument = gql`
    mutation heartbeat($input: UpdateUserInput!) {
  heartbeat(input: $input) {
    status
  }
}
    `;
export type HeartbeatMutationFn = Apollo.MutationFunction<
  HeartbeatMutation,
  HeartbeatMutationVariables
>;

/**
 * __useHeartbeatMutation__
 *
 * To run a mutation, you first call `useHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [heartbeatMutation, { data, loading, error }] = useHeartbeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHeartbeatMutation(
  baseOptions?: Apollo.MutationHookOptions<HeartbeatMutation, HeartbeatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HeartbeatMutation, HeartbeatMutationVariables>(
    HeartbeatDocument,
    options,
  );
}
export type HeartbeatMutationHookResult = ReturnType<typeof useHeartbeatMutation>;
export type HeartbeatMutationResult = Apollo.MutationResult<HeartbeatMutation>;
export type HeartbeatMutationOptions = Apollo.BaseMutationOptions<
  HeartbeatMutation,
  HeartbeatMutationVariables
>;
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateUserInput!) {
  updateMe(input: $input) {
    id
    avatarUrl
    needSpeakingPartner
    otherLanguage
    cambridgeEnglishLevel
    firstName
    englishSkills
    interests
    occupation {
      id
    }
    isComplete
    lastName
    city
    country
    story
    birthday
    showBirthday
    nativeLanguage
    wantsNewsletter
    completedPercentage
    availability {
      from
      until
    }
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;
export const GenerateUserUploadUrlDocument = gql`
    mutation generateUserUploadUrl($input: UploadPhotoInput!) {
  generateUserUploadUrl(input: $input) {
    contentURL
    uploadURL
  }
}
    `;
export type GenerateUserUploadUrlMutationFn = Apollo.MutationFunction<
  GenerateUserUploadUrlMutation,
  GenerateUserUploadUrlMutationVariables
>;

/**
 * __useGenerateUserUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUserUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserUploadUrlMutation, { data, loading, error }] = useGenerateUserUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateUserUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateUserUploadUrlMutation,
    GenerateUserUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateUserUploadUrlMutation, GenerateUserUploadUrlMutationVariables>(
    GenerateUserUploadUrlDocument,
    options,
  );
}
export type GenerateUserUploadUrlMutationHookResult = ReturnType<
  typeof useGenerateUserUploadUrlMutation
>;
export type GenerateUserUploadUrlMutationResult =
  Apollo.MutationResult<GenerateUserUploadUrlMutation>;
export type GenerateUserUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateUserUploadUrlMutation,
  GenerateUserUploadUrlMutationVariables
>;
export const CreateUserPhotoDocument = gql`
    mutation createUserPhoto($input: UpdatePhotoInput!) {
  createUserPhoto(input: $input) {
    id
    url
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserPhotoMutationFn = Apollo.MutationFunction<
  CreateUserPhotoMutation,
  CreateUserPhotoMutationVariables
>;

/**
 * __useCreateUserPhotoMutation__
 *
 * To run a mutation, you first call `useCreateUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPhotoMutation, { data, loading, error }] = useCreateUserPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserPhotoMutation,
    CreateUserPhotoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserPhotoMutation, CreateUserPhotoMutationVariables>(
    CreateUserPhotoDocument,
    options,
  );
}
export type CreateUserPhotoMutationHookResult = ReturnType<typeof useCreateUserPhotoMutation>;
export type CreateUserPhotoMutationResult = Apollo.MutationResult<CreateUserPhotoMutation>;
export type CreateUserPhotoMutationOptions = Apollo.BaseMutationOptions<
  CreateUserPhotoMutation,
  CreateUserPhotoMutationVariables
>;
export const DeleteUserPhotoDocument = gql`
    mutation deleteUserPhoto($input: DeletePhotoInput!) {
  deleteUserPhoto(input: $input) {
    id
  }
}
    `;
export type DeleteUserPhotoMutationFn = Apollo.MutationFunction<
  DeleteUserPhotoMutation,
  DeleteUserPhotoMutationVariables
>;

/**
 * __useDeleteUserPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPhotoMutation, { data, loading, error }] = useDeleteUserPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserPhotoMutation,
    DeleteUserPhotoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserPhotoMutation, DeleteUserPhotoMutationVariables>(
    DeleteUserPhotoDocument,
    options,
  );
}
export type DeleteUserPhotoMutationHookResult = ReturnType<typeof useDeleteUserPhotoMutation>;
export type DeleteUserPhotoMutationResult = Apollo.MutationResult<DeleteUserPhotoMutation>;
export type DeleteUserPhotoMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserPhotoMutation,
  DeleteUserPhotoMutationVariables
>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($input: RequestPasswordInput!) {
  requestResetPassword(input: $input) {
    status
  }
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(
    RequestResetPasswordDocument,
    options,
  );
}
export type RequestResetPasswordMutationHookResult = ReturnType<
  typeof useRequestResetPasswordMutation
>;
export type RequestResetPasswordMutationResult =
  Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
    mutation updateUserSettings($input: UpdateSettingsInput!) {
  updateUserSettings(input: $input) {
    status
  }
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    status
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(
    UpdatePasswordDocument,
    options,
  );
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const DeactivateDocument = gql`
    mutation deactivate($input: DeactivateInput!) {
  deactivate(input: $input) {
    reason
    status
  }
}
    `;
export type DeactivateMutationFn = Apollo.MutationFunction<
  DeactivateMutation,
  DeactivateMutationVariables
>;

/**
 * __useDeactivateMutation__
 *
 * To run a mutation, you first call `useDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMutation, { data, loading, error }] = useDeactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateMutation, DeactivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateMutation, DeactivateMutationVariables>(
    DeactivateDocument,
    options,
  );
}
export type DeactivateMutationHookResult = ReturnType<typeof useDeactivateMutation>;
export type DeactivateMutationResult = Apollo.MutationResult<DeactivateMutation>;
export type DeactivateMutationOptions = Apollo.BaseMutationOptions<
  DeactivateMutation,
  DeactivateMutationVariables
>;
export const RecoverAccountDocument = gql`
    mutation recoverAccount {
  recoverAccount {
    status
  }
}
    `;
export type RecoverAccountMutationFn = Apollo.MutationFunction<
  RecoverAccountMutation,
  RecoverAccountMutationVariables
>;

/**
 * __useRecoverAccountMutation__
 *
 * To run a mutation, you first call `useRecoverAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverAccountMutation, { data, loading, error }] = useRecoverAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecoverAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<RecoverAccountMutation, RecoverAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RecoverAccountMutation, RecoverAccountMutationVariables>(
    RecoverAccountDocument,
    options,
  );
}
export type RecoverAccountMutationHookResult = ReturnType<typeof useRecoverAccountMutation>;
export type RecoverAccountMutationResult = Apollo.MutationResult<RecoverAccountMutation>;
export type RecoverAccountMutationOptions = Apollo.BaseMutationOptions<
  RecoverAccountMutation,
  RecoverAccountMutationVariables
>;
export const CreateAbuseDocument = gql`
    mutation createAbuse($input: ReportAbuseInput!) {
  createAbuse(input: $input) {
    reason
    description
  }
}
    `;
export type CreateAbuseMutationFn = Apollo.MutationFunction<
  CreateAbuseMutation,
  CreateAbuseMutationVariables
>;

/**
 * __useCreateAbuseMutation__
 *
 * To run a mutation, you first call `useCreateAbuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAbuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAbuseMutation, { data, loading, error }] = useCreateAbuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAbuseMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAbuseMutation, CreateAbuseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAbuseMutation, CreateAbuseMutationVariables>(
    CreateAbuseDocument,
    options,
  );
}
export type CreateAbuseMutationHookResult = ReturnType<typeof useCreateAbuseMutation>;
export type CreateAbuseMutationResult = Apollo.MutationResult<CreateAbuseMutation>;
export type CreateAbuseMutationOptions = Apollo.BaseMutationOptions<
  CreateAbuseMutation,
  CreateAbuseMutationVariables
>;
export const ContactUsDocument = gql`
    mutation contactUs($input: ContactUsInput!) {
  contactUs(input: $input) {
    status
  }
}
    `;
export type ContactUsMutationFn = Apollo.MutationFunction<
  ContactUsMutation,
  ContactUsMutationVariables
>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactUsMutation(
  baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(
    ContactUsDocument,
    options,
  );
}
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<
  ContactUsMutation,
  ContactUsMutationVariables
>;
export const AddZoomLicenseToUserDocument = gql`
    mutation addZoomLicenseToUser($userID: String!) {
  addZoomLicenseToUser(userID: $userID) {
    status
  }
}
    `;
export type AddZoomLicenseToUserMutationFn = Apollo.MutationFunction<
  AddZoomLicenseToUserMutation,
  AddZoomLicenseToUserMutationVariables
>;

/**
 * __useAddZoomLicenseToUserMutation__
 *
 * To run a mutation, you first call `useAddZoomLicenseToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddZoomLicenseToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addZoomLicenseToUserMutation, { data, loading, error }] = useAddZoomLicenseToUserMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useAddZoomLicenseToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddZoomLicenseToUserMutation,
    AddZoomLicenseToUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddZoomLicenseToUserMutation, AddZoomLicenseToUserMutationVariables>(
    AddZoomLicenseToUserDocument,
    options,
  );
}
export type AddZoomLicenseToUserMutationHookResult = ReturnType<
  typeof useAddZoomLicenseToUserMutation
>;
export type AddZoomLicenseToUserMutationResult =
  Apollo.MutationResult<AddZoomLicenseToUserMutation>;
export type AddZoomLicenseToUserMutationOptions = Apollo.BaseMutationOptions<
  AddZoomLicenseToUserMutation,
  AddZoomLicenseToUserMutationVariables
>;
export const FollowDocument = gql`
    mutation follow($input: FollowInput!) {
  follow(input: $input) {
    status
  }
}
    `;
export type FollowMutationFn = Apollo.MutationFunction<FollowMutation, FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowMutation, FollowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowMutation, FollowMutationVariables>(FollowDocument, options);
}
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<
  FollowMutation,
  FollowMutationVariables
>;
export const UnFollowDocument = gql`
    mutation unFollow($input: FollowInput!) {
  unFollow(input: $input) {
    status
  }
}
    `;
export type UnFollowMutationFn = Apollo.MutationFunction<
  UnFollowMutation,
  UnFollowMutationVariables
>;

/**
 * __useUnFollowMutation__
 *
 * To run a mutation, you first call `useUnFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowMutation, { data, loading, error }] = useUnFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<UnFollowMutation, UnFollowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnFollowMutation, UnFollowMutationVariables>(UnFollowDocument, options);
}
export type UnFollowMutationHookResult = ReturnType<typeof useUnFollowMutation>;
export type UnFollowMutationResult = Apollo.MutationResult<UnFollowMutation>;
export type UnFollowMutationOptions = Apollo.BaseMutationOptions<
  UnFollowMutation,
  UnFollowMutationVariables
>;
export const PauseDocument = gql`
    mutation pause {
  pause {
    status
  }
}
    `;
export type PauseMutationFn = Apollo.MutationFunction<PauseMutation, PauseMutationVariables>;

/**
 * __usePauseMutation__
 *
 * To run a mutation, you first call `usePauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseMutation, { data, loading, error }] = usePauseMutation({
 *   variables: {
 *   },
 * });
 */
export function usePauseMutation(
  baseOptions?: Apollo.MutationHookOptions<PauseMutation, PauseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PauseMutation, PauseMutationVariables>(PauseDocument, options);
}
export type PauseMutationHookResult = ReturnType<typeof usePauseMutation>;
export type PauseMutationResult = Apollo.MutationResult<PauseMutation>;
export type PauseMutationOptions = Apollo.BaseMutationOptions<
  PauseMutation,
  PauseMutationVariables
>;
export const ResumeDocument = gql`
    mutation resume {
  resume {
    status
  }
}
    `;
export type ResumeMutationFn = Apollo.MutationFunction<ResumeMutation, ResumeMutationVariables>;

/**
 * __useResumeMutation__
 *
 * To run a mutation, you first call `useResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeMutation, { data, loading, error }] = useResumeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResumeMutation(
  baseOptions?: Apollo.MutationHookOptions<ResumeMutation, ResumeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResumeMutation, ResumeMutationVariables>(ResumeDocument, options);
}
export type ResumeMutationHookResult = ReturnType<typeof useResumeMutation>;
export type ResumeMutationResult = Apollo.MutationResult<ResumeMutation>;
export type ResumeMutationOptions = Apollo.BaseMutationOptions<
  ResumeMutation,
  ResumeMutationVariables
>;
export const SubscribeDocument = gql`
    mutation subscribe($input: SubscribeInput!) {
  subscribe(input: $input)
}
    `;
export type SubscribeMutationFn = Apollo.MutationFunction<
  SubscribeMutation,
  SubscribeMutationVariables
>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
    options,
  );
}
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<
  SubscribeMutation,
  SubscribeMutationVariables
>;
export const SetTokenDocument = gql`
    mutation setToken($input: String!) {
  setToken(input: $input) {
    status
  }
}
    `;
export type SetTokenMutationFn = Apollo.MutationFunction<
  SetTokenMutation,
  SetTokenMutationVariables
>;

/**
 * __useSetTokenMutation__
 *
 * To run a mutation, you first call `useSetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTokenMutation, { data, loading, error }] = useSetTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SetTokenMutation, SetTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetTokenMutation, SetTokenMutationVariables>(SetTokenDocument, options);
}
export type SetTokenMutationHookResult = ReturnType<typeof useSetTokenMutation>;
export type SetTokenMutationResult = Apollo.MutationResult<SetTokenMutation>;
export type SetTokenMutationOptions = Apollo.BaseMutationOptions<
  SetTokenMutation,
  SetTokenMutationVariables
>;
export const MarkReportAsSeenDocument = gql`
    mutation markReportAsSeen {
  markReportAsSeen {
    status
  }
}
    `;
export type MarkReportAsSeenMutationFn = Apollo.MutationFunction<
  MarkReportAsSeenMutation,
  MarkReportAsSeenMutationVariables
>;

/**
 * __useMarkReportAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkReportAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReportAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReportAsSeenMutation, { data, loading, error }] = useMarkReportAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkReportAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkReportAsSeenMutation,
    MarkReportAsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkReportAsSeenMutation, MarkReportAsSeenMutationVariables>(
    MarkReportAsSeenDocument,
    options,
  );
}
export type MarkReportAsSeenMutationHookResult = ReturnType<typeof useMarkReportAsSeenMutation>;
export type MarkReportAsSeenMutationResult = Apollo.MutationResult<MarkReportAsSeenMutation>;
export type MarkReportAsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkReportAsSeenMutation,
  MarkReportAsSeenMutationVariables
>;
export const AssignCompleteOnboardingQuizBadgeDocument = gql`
    mutation assignCompleteOnboardingQuizBadge {
  assignCompleteOnboardingQuizBadge {
    status
  }
}
    `;
export type AssignCompleteOnboardingQuizBadgeMutationFn = Apollo.MutationFunction<
  AssignCompleteOnboardingQuizBadgeMutation,
  AssignCompleteOnboardingQuizBadgeMutationVariables
>;

/**
 * __useAssignCompleteOnboardingQuizBadgeMutation__
 *
 * To run a mutation, you first call `useAssignCompleteOnboardingQuizBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCompleteOnboardingQuizBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCompleteOnboardingQuizBadgeMutation, { data, loading, error }] = useAssignCompleteOnboardingQuizBadgeMutation({
 *   variables: {
 *   },
 * });
 */
export function useAssignCompleteOnboardingQuizBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCompleteOnboardingQuizBadgeMutation,
    AssignCompleteOnboardingQuizBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignCompleteOnboardingQuizBadgeMutation,
    AssignCompleteOnboardingQuizBadgeMutationVariables
  >(AssignCompleteOnboardingQuizBadgeDocument, options);
}
export type AssignCompleteOnboardingQuizBadgeMutationHookResult = ReturnType<
  typeof useAssignCompleteOnboardingQuizBadgeMutation
>;
export type AssignCompleteOnboardingQuizBadgeMutationResult =
  Apollo.MutationResult<AssignCompleteOnboardingQuizBadgeMutation>;
export type AssignCompleteOnboardingQuizBadgeMutationOptions = Apollo.BaseMutationOptions<
  AssignCompleteOnboardingQuizBadgeMutation,
  AssignCompleteOnboardingQuizBadgeMutationVariables
>;
export const SeenCompletedBasicsDocument = gql`
    mutation seenCompletedBasics {
  seenCompletedBasics {
    status
  }
}
    `;
export type SeenCompletedBasicsMutationFn = Apollo.MutationFunction<
  SeenCompletedBasicsMutation,
  SeenCompletedBasicsMutationVariables
>;

/**
 * __useSeenCompletedBasicsMutation__
 *
 * To run a mutation, you first call `useSeenCompletedBasicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeenCompletedBasicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seenCompletedBasicsMutation, { data, loading, error }] = useSeenCompletedBasicsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSeenCompletedBasicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SeenCompletedBasicsMutation,
    SeenCompletedBasicsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SeenCompletedBasicsMutation, SeenCompletedBasicsMutationVariables>(
    SeenCompletedBasicsDocument,
    options,
  );
}
export type SeenCompletedBasicsMutationHookResult = ReturnType<
  typeof useSeenCompletedBasicsMutation
>;
export type SeenCompletedBasicsMutationResult = Apollo.MutationResult<SeenCompletedBasicsMutation>;
export type SeenCompletedBasicsMutationOptions = Apollo.BaseMutationOptions<
  SeenCompletedBasicsMutation,
  SeenCompletedBasicsMutationVariables
>;
export const SendLinkDocument = gql`
    mutation sendLink($input: FriendInput!) {
  sendLink(input: $input) {
    status
  }
}
    `;
export type SendLinkMutationFn = Apollo.MutationFunction<
  SendLinkMutation,
  SendLinkMutationVariables
>;

/**
 * __useSendLinkMutation__
 *
 * To run a mutation, you first call `useSendLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLinkMutation, { data, loading, error }] = useSendLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<SendLinkMutation, SendLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendLinkMutation, SendLinkMutationVariables>(SendLinkDocument, options);
}
export type SendLinkMutationHookResult = ReturnType<typeof useSendLinkMutation>;
export type SendLinkMutationResult = Apollo.MutationResult<SendLinkMutation>;
export type SendLinkMutationOptions = Apollo.BaseMutationOptions<
  SendLinkMutation,
  SendLinkMutationVariables
>;
export const CloseVideoCallDoorDocument = gql`
    mutation closeVideoCallDoor($id: String!) {
  closeVideoCallDoor(id: $id) {
    id
    closedDoorAt
  }
}
    `;
export type CloseVideoCallDoorMutationFn = Apollo.MutationFunction<
  CloseVideoCallDoorMutation,
  CloseVideoCallDoorMutationVariables
>;

/**
 * __useCloseVideoCallDoorMutation__
 *
 * To run a mutation, you first call `useCloseVideoCallDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseVideoCallDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeVideoCallDoorMutation, { data, loading, error }] = useCloseVideoCallDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseVideoCallDoorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseVideoCallDoorMutation,
    CloseVideoCallDoorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseVideoCallDoorMutation, CloseVideoCallDoorMutationVariables>(
    CloseVideoCallDoorDocument,
    options,
  );
}
export type CloseVideoCallDoorMutationHookResult = ReturnType<typeof useCloseVideoCallDoorMutation>;
export type CloseVideoCallDoorMutationResult = Apollo.MutationResult<CloseVideoCallDoorMutation>;
export type CloseVideoCallDoorMutationOptions = Apollo.BaseMutationOptions<
  CloseVideoCallDoorMutation,
  CloseVideoCallDoorMutationVariables
>;
export const OpenVideoCallDoorDocument = gql`
    mutation openVideoCallDoor($id: String!) {
  openVideoCallDoor(id: $id) {
    id
    closedDoorAt
  }
}
    `;
export type OpenVideoCallDoorMutationFn = Apollo.MutationFunction<
  OpenVideoCallDoorMutation,
  OpenVideoCallDoorMutationVariables
>;

/**
 * __useOpenVideoCallDoorMutation__
 *
 * To run a mutation, you first call `useOpenVideoCallDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenVideoCallDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openVideoCallDoorMutation, { data, loading, error }] = useOpenVideoCallDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpenVideoCallDoorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OpenVideoCallDoorMutation,
    OpenVideoCallDoorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpenVideoCallDoorMutation, OpenVideoCallDoorMutationVariables>(
    OpenVideoCallDoorDocument,
    options,
  );
}
export type OpenVideoCallDoorMutationHookResult = ReturnType<typeof useOpenVideoCallDoorMutation>;
export type OpenVideoCallDoorMutationResult = Apollo.MutationResult<OpenVideoCallDoorMutation>;
export type OpenVideoCallDoorMutationOptions = Apollo.BaseMutationOptions<
  OpenVideoCallDoorMutation,
  OpenVideoCallDoorMutationVariables
>;
export const VideoMeetingTokenDocument = gql`
    mutation videoMeetingToken($input: DailyMeetingInput!) {
  videoMeetingToken(input: $input) {
    token
    exp
  }
}
    `;
export type VideoMeetingTokenMutationFn = Apollo.MutationFunction<
  VideoMeetingTokenMutation,
  VideoMeetingTokenMutationVariables
>;

/**
 * __useVideoMeetingTokenMutation__
 *
 * To run a mutation, you first call `useVideoMeetingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoMeetingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoMeetingTokenMutation, { data, loading, error }] = useVideoMeetingTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoMeetingTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoMeetingTokenMutation,
    VideoMeetingTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoMeetingTokenMutation, VideoMeetingTokenMutationVariables>(
    VideoMeetingTokenDocument,
    options,
  );
}
export type VideoMeetingTokenMutationHookResult = ReturnType<typeof useVideoMeetingTokenMutation>;
export type VideoMeetingTokenMutationResult = Apollo.MutationResult<VideoMeetingTokenMutation>;
export type VideoMeetingTokenMutationOptions = Apollo.BaseMutationOptions<
  VideoMeetingTokenMutation,
  VideoMeetingTokenMutationVariables
>;
export const CompleteVideoDocument = gql`
    mutation completeVideo($id: String!) {
  completeVideo(id: $id) {
    id
  }
}
    `;
export type CompleteVideoMutationFn = Apollo.MutationFunction<
  CompleteVideoMutation,
  CompleteVideoMutationVariables
>;

/**
 * __useCompleteVideoMutation__
 *
 * To run a mutation, you first call `useCompleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVideoMutation, { data, loading, error }] = useCompleteVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteVideoMutation, CompleteVideoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteVideoMutation, CompleteVideoMutationVariables>(
    CompleteVideoDocument,
    options,
  );
}
export type CompleteVideoMutationHookResult = ReturnType<typeof useCompleteVideoMutation>;
export type CompleteVideoMutationResult = Apollo.MutationResult<CompleteVideoMutation>;
export type CompleteVideoMutationOptions = Apollo.BaseMutationOptions<
  CompleteVideoMutation,
  CompleteVideoMutationVariables
>;
export const GetAssessmentChargesDocument = gql`
    query getAssessmentCharges {
  me {
    id
    availableAssessmentCharges
  }
}
    `;

/**
 * __useGetAssessmentChargesQuery__
 *
 * To run a query within a React component, call `useGetAssessmentChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentChargesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssessmentChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssessmentChargesQuery,
    GetAssessmentChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssessmentChargesQuery, GetAssessmentChargesQueryVariables>(
    GetAssessmentChargesDocument,
    options,
  );
}
export function useGetAssessmentChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentChargesQuery,
    GetAssessmentChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssessmentChargesQuery, GetAssessmentChargesQueryVariables>(
    GetAssessmentChargesDocument,
    options,
  );
}
export function useGetAssessmentChargesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAssessmentChargesQuery,
        GetAssessmentChargesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAssessmentChargesQuery, GetAssessmentChargesQueryVariables>(
    GetAssessmentChargesDocument,
    options,
  );
}
export type GetAssessmentChargesQueryHookResult = ReturnType<typeof useGetAssessmentChargesQuery>;
export type GetAssessmentChargesLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentChargesLazyQuery
>;
export type GetAssessmentChargesSuspenseQueryHookResult = ReturnType<
  typeof useGetAssessmentChargesSuspenseQuery
>;
export type GetAssessmentChargesQueryResult = Apollo.QueryResult<
  GetAssessmentChargesQuery,
  GetAssessmentChargesQueryVariables
>;
export const GetCurrentBannerDocument = gql`
    query getCurrentBanner {
  getCurrentBanner {
    id
    title
    isActive
    sliders {
      id
      title
      subtitle
      buttonText
      buttonURL
      background {
        mobile
        desktop
      }
      featureImage
      titleStyle
      textColour
      imageAlignment
    }
  }
}
    `;

/**
 * __useGetCurrentBannerQuery__
 *
 * To run a query within a React component, call `useGetCurrentBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentBannerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>(
    GetCurrentBannerDocument,
    options,
  );
}
export function useGetCurrentBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>(
    GetCurrentBannerDocument,
    options,
  );
}
export function useGetCurrentBannerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCurrentBannerQuery, GetCurrentBannerQueryVariables>(
    GetCurrentBannerDocument,
    options,
  );
}
export type GetCurrentBannerQueryHookResult = ReturnType<typeof useGetCurrentBannerQuery>;
export type GetCurrentBannerLazyQueryHookResult = ReturnType<typeof useGetCurrentBannerLazyQuery>;
export type GetCurrentBannerSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentBannerSuspenseQuery
>;
export type GetCurrentBannerQueryResult = Apollo.QueryResult<
  GetCurrentBannerQuery,
  GetCurrentBannerQueryVariables
>;
export const ChatCreateUserTokenDocument = gql`
    query chatCreateUserToken {
  chatCreateUserToken
}
    `;

/**
 * __useChatCreateUserTokenQuery__
 *
 * To run a query within a React component, call `useChatCreateUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCreateUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCreateUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatCreateUserTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChatCreateUserTokenQuery,
    ChatCreateUserTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatCreateUserTokenQuery, ChatCreateUserTokenQueryVariables>(
    ChatCreateUserTokenDocument,
    options,
  );
}
export function useChatCreateUserTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatCreateUserTokenQuery,
    ChatCreateUserTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatCreateUserTokenQuery, ChatCreateUserTokenQueryVariables>(
    ChatCreateUserTokenDocument,
    options,
  );
}
export function useChatCreateUserTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChatCreateUserTokenQuery, ChatCreateUserTokenQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatCreateUserTokenQuery, ChatCreateUserTokenQueryVariables>(
    ChatCreateUserTokenDocument,
    options,
  );
}
export type ChatCreateUserTokenQueryHookResult = ReturnType<typeof useChatCreateUserTokenQuery>;
export type ChatCreateUserTokenLazyQueryHookResult = ReturnType<
  typeof useChatCreateUserTokenLazyQuery
>;
export type ChatCreateUserTokenSuspenseQueryHookResult = ReturnType<
  typeof useChatCreateUserTokenSuspenseQuery
>;
export type ChatCreateUserTokenQueryResult = Apollo.QueryResult<
  ChatCreateUserTokenQuery,
  ChatCreateUserTokenQueryVariables
>;
export const ListPaginatedEventsDocument = gql`
    query listPaginatedEvents($filters: FilterPaginatedEventInput!, $order: OrderEventInput) {
  listPaginatedEvents(filters: $filters, order: $order) {
    items {
      id
      kind
      title
      description
      isOnline
      conversationKind
      coverUrl
      illustrationUrl
      englishLevel
      startDate
      endDate
      isForUnverified
      isFull
      topic {
        id
      }
      maxParticipants
      noParticipants
      participants {
        id
        avatarUrl
      }
      cohosts {
        id
        firstName
        lastName
        avatarUrl
        kind
      }
      host {
        id
        name
        firstName
        lastName
        avatarUrl
        kind
      }
      organizer {
        id
        name
        kind
      }
      invites {
        id
        status
        user {
          id
        }
      }
      isDraft
      permissions
      occupations {
        id
      }
    }
    total
  }
}
    `;

/**
 * __useListPaginatedEventsQuery__
 *
 * To run a query within a React component, call `useListPaginatedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaginatedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaginatedEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListPaginatedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListPaginatedEventsQuery,
    ListPaginatedEventsQueryVariables
  > &
    ({ variables: ListPaginatedEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPaginatedEventsQuery, ListPaginatedEventsQueryVariables>(
    ListPaginatedEventsDocument,
    options,
  );
}
export function useListPaginatedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPaginatedEventsQuery,
    ListPaginatedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPaginatedEventsQuery, ListPaginatedEventsQueryVariables>(
    ListPaginatedEventsDocument,
    options,
  );
}
export function useListPaginatedEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListPaginatedEventsQuery, ListPaginatedEventsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPaginatedEventsQuery, ListPaginatedEventsQueryVariables>(
    ListPaginatedEventsDocument,
    options,
  );
}
export type ListPaginatedEventsQueryHookResult = ReturnType<typeof useListPaginatedEventsQuery>;
export type ListPaginatedEventsLazyQueryHookResult = ReturnType<
  typeof useListPaginatedEventsLazyQuery
>;
export type ListPaginatedEventsSuspenseQueryHookResult = ReturnType<
  typeof useListPaginatedEventsSuspenseQuery
>;
export type ListPaginatedEventsQueryResult = Apollo.QueryResult<
  ListPaginatedEventsQuery,
  ListPaginatedEventsQueryVariables
>;
export const UserHostedEventsDocument = gql`
    query userHostedEvents($filters: FilterPaginatedEventInput!, $order: OrderEventInput) {
  userHostedEvents(filters: $filters, order: $order) {
    items {
      id
      title
      isFull
      startDate
    }
    total
  }
}
    `;

/**
 * __useUserHostedEventsQuery__
 *
 * To run a query within a React component, call `useUserHostedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHostedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHostedEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserHostedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<UserHostedEventsQuery, UserHostedEventsQueryVariables> &
    ({ variables: UserHostedEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHostedEventsQuery, UserHostedEventsQueryVariables>(
    UserHostedEventsDocument,
    options,
  );
}
export function useUserHostedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHostedEventsQuery, UserHostedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHostedEventsQuery, UserHostedEventsQueryVariables>(
    UserHostedEventsDocument,
    options,
  );
}
export function useUserHostedEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserHostedEventsQuery, UserHostedEventsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserHostedEventsQuery, UserHostedEventsQueryVariables>(
    UserHostedEventsDocument,
    options,
  );
}
export type UserHostedEventsQueryHookResult = ReturnType<typeof useUserHostedEventsQuery>;
export type UserHostedEventsLazyQueryHookResult = ReturnType<typeof useUserHostedEventsLazyQuery>;
export type UserHostedEventsSuspenseQueryHookResult = ReturnType<
  typeof useUserHostedEventsSuspenseQuery
>;
export type UserHostedEventsQueryResult = Apollo.QueryResult<
  UserHostedEventsQuery,
  UserHostedEventsQueryVariables
>;
export const UserHostedEventsToShareCountDocument = gql`
    query userHostedEventsToShareCount {
  userHostedEventsToShareCount
}
    `;

/**
 * __useUserHostedEventsToShareCountQuery__
 *
 * To run a query within a React component, call `useUserHostedEventsToShareCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHostedEventsToShareCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHostedEventsToShareCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHostedEventsToShareCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserHostedEventsToShareCountQuery,
    UserHostedEventsToShareCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserHostedEventsToShareCountQuery,
    UserHostedEventsToShareCountQueryVariables
  >(UserHostedEventsToShareCountDocument, options);
}
export function useUserHostedEventsToShareCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserHostedEventsToShareCountQuery,
    UserHostedEventsToShareCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserHostedEventsToShareCountQuery,
    UserHostedEventsToShareCountQueryVariables
  >(UserHostedEventsToShareCountDocument, options);
}
export function useUserHostedEventsToShareCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserHostedEventsToShareCountQuery,
        UserHostedEventsToShareCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserHostedEventsToShareCountQuery,
    UserHostedEventsToShareCountQueryVariables
  >(UserHostedEventsToShareCountDocument, options);
}
export type UserHostedEventsToShareCountQueryHookResult = ReturnType<
  typeof useUserHostedEventsToShareCountQuery
>;
export type UserHostedEventsToShareCountLazyQueryHookResult = ReturnType<
  typeof useUserHostedEventsToShareCountLazyQuery
>;
export type UserHostedEventsToShareCountSuspenseQueryHookResult = ReturnType<
  typeof useUserHostedEventsToShareCountSuspenseQuery
>;
export type UserHostedEventsToShareCountQueryResult = Apollo.QueryResult<
  UserHostedEventsToShareCountQuery,
  UserHostedEventsToShareCountQueryVariables
>;
export const EventDocument = gql`
    query event($id: String!) {
  getEvent(id: $id) {
    id
    coverUrl
    illustrationUrl
    createdAt
    conversationKind
    englishLevel
    englishSkills
    eventCategory
    duplicateOf
    isForConfidentUsers
    isForFoundation
    isForUnverified
    isForRise
    description
    endDate
    isDraft
    isOnline
    isPublic
    isDeleted
    isConfirmed
    interests
    kind
    meta
    location
    locationId
    locationLat
    locationLong
    noParticipants
    occupations {
      id
      name
    }
    conversationKind
    recurring
    startDate
    title
    updatedAt
    closedDoorAt
    videoUrl
    maxParticipants
    eventLessonKind
    permissions
    ideasToDiscuss
    customTopic
    createdFromTopic
    links {
      url
      description
    }
    organizer {
      id
      name
      firstName
      lastName
      avatarUrl
      createdAt
      badge
      kind
    }
    host {
      id
      name
      firstName
      lastName
      avatarUrl
      createdAt
      badge
      kind
      city
      country
      interests
    }
    cohosts {
      id
      name
      firstName
      lastName
      avatarUrl
      createdAt
      badge
      kind
      city
      country
      interests
    }
    topic {
      id
      title
      illustration {
        id
        url
      }
      ideasToDiscuss
      lessons {
        id
        kind
        deepDiveKind
      }
      theme {
        id
        title
        isTopicLibrary
      }
    }
    participants {
      id
      avatarUrl
      createdAt
      firstName
      lastName
      isVerified
      kind
      city
      country
      interests
    }
    invites {
      id
      isHost
      status
      isConfirmed
      user {
        id
        name
        firstName
        lastName
        createdAt
        badge
        avatarUrl
      }
    }
    attachments {
      id
      url
      meta {
        extension
        name
        size
      }
    }
    comments {
      id
      createdAt
      message
      author {
        id
        name
        avatarUrl
      }
      reactions {
        id
        unifiedId
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      replies {
        id
        createdAt
        message
        author {
          id
          name
          avatarUrl
        }
        reactions {
          id
          unifiedId
          user {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> &
    ({ variables: EventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export function useEventSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<typeof useEventSuspenseQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const GetParticipationStatusDocument = gql`
    query getParticipationStatus($input: ParticipationStatusInput!) {
  getParticipationStatus(input: $input) {
    status
  }
}
    `;

/**
 * __useGetParticipationStatusQuery__
 *
 * To run a query within a React component, call `useGetParticipationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetParticipationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipationStatusQuery,
    GetParticipationStatusQueryVariables
  > &
    ({ variables: GetParticipationStatusQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParticipationStatusQuery, GetParticipationStatusQueryVariables>(
    GetParticipationStatusDocument,
    options,
  );
}
export function useGetParticipationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipationStatusQuery,
    GetParticipationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParticipationStatusQuery, GetParticipationStatusQueryVariables>(
    GetParticipationStatusDocument,
    options,
  );
}
export function useGetParticipationStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetParticipationStatusQuery,
        GetParticipationStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetParticipationStatusQuery, GetParticipationStatusQueryVariables>(
    GetParticipationStatusDocument,
    options,
  );
}
export type GetParticipationStatusQueryHookResult = ReturnType<
  typeof useGetParticipationStatusQuery
>;
export type GetParticipationStatusLazyQueryHookResult = ReturnType<
  typeof useGetParticipationStatusLazyQuery
>;
export type GetParticipationStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetParticipationStatusSuspenseQuery
>;
export type GetParticipationStatusQueryResult = Apollo.QueryResult<
  GetParticipationStatusQuery,
  GetParticipationStatusQueryVariables
>;
export const TopicsAndThemesDocument = gql`
    query topicsAndThemes {
  listThemes(filters: {}) {
    id
    title
    createdAt
    description
    isCurrent
    isStandard
    isTopicLibrary
    isGrammarLibrary
    title
    updatedAt
    cover {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
    topics {
      id
      title
      illustration {
        id
        title
        url
      }
    }
  }
}
    `;

/**
 * __useTopicsAndThemesQuery__
 *
 * To run a query within a React component, call `useTopicsAndThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsAndThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsAndThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopicsAndThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>(
    TopicsAndThemesDocument,
    options,
  );
}
export function useTopicsAndThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>(
    TopicsAndThemesDocument,
    options,
  );
}
export function useTopicsAndThemesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopicsAndThemesQuery, TopicsAndThemesQueryVariables>(
    TopicsAndThemesDocument,
    options,
  );
}
export type TopicsAndThemesQueryHookResult = ReturnType<typeof useTopicsAndThemesQuery>;
export type TopicsAndThemesLazyQueryHookResult = ReturnType<typeof useTopicsAndThemesLazyQuery>;
export type TopicsAndThemesSuspenseQueryHookResult = ReturnType<
  typeof useTopicsAndThemesSuspenseQuery
>;
export type TopicsAndThemesQueryResult = Apollo.QueryResult<
  TopicsAndThemesQuery,
  TopicsAndThemesQueryVariables
>;
export const GetLiveTablesDocument = gql`
    query getLiveTables {
  getLiveTables {
    table {
      id
      title
      englishLevel
      maxParticipants
      dailyRoomId
    }
    participants {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetLiveTablesQuery__
 *
 * To run a query within a React component, call `useGetLiveTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLiveTablesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLiveTablesQuery, GetLiveTablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiveTablesQuery, GetLiveTablesQueryVariables>(
    GetLiveTablesDocument,
    options,
  );
}
export function useGetLiveTablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLiveTablesQuery, GetLiveTablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLiveTablesQuery, GetLiveTablesQueryVariables>(
    GetLiveTablesDocument,
    options,
  );
}
export function useGetLiveTablesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLiveTablesQuery, GetLiveTablesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLiveTablesQuery, GetLiveTablesQueryVariables>(
    GetLiveTablesDocument,
    options,
  );
}
export type GetLiveTablesQueryHookResult = ReturnType<typeof useGetLiveTablesQuery>;
export type GetLiveTablesLazyQueryHookResult = ReturnType<typeof useGetLiveTablesLazyQuery>;
export type GetLiveTablesSuspenseQueryHookResult = ReturnType<typeof useGetLiveTablesSuspenseQuery>;
export type GetLiveTablesQueryResult = Apollo.QueryResult<
  GetLiveTablesQuery,
  GetLiveTablesQueryVariables
>;
export const GetLiveTableByIdDocument = gql`
    query getLiveTableById($id: String!) {
  getLiveTableById(id: $id) {
    id
    title
    englishLevel
    dailyRoomId
    isActive
    endedAt
    creatorId
  }
}
    `;

/**
 * __useGetLiveTableByIdQuery__
 *
 * To run a query within a React component, call `useGetLiveTableByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveTableByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveTableByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveTableByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables> &
    ({ variables: GetLiveTableByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables>(
    GetLiveTableByIdDocument,
    options,
  );
}
export function useGetLiveTableByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables>(
    GetLiveTableByIdDocument,
    options,
  );
}
export function useGetLiveTableByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLiveTableByIdQuery, GetLiveTableByIdQueryVariables>(
    GetLiveTableByIdDocument,
    options,
  );
}
export type GetLiveTableByIdQueryHookResult = ReturnType<typeof useGetLiveTableByIdQuery>;
export type GetLiveTableByIdLazyQueryHookResult = ReturnType<typeof useGetLiveTableByIdLazyQuery>;
export type GetLiveTableByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetLiveTableByIdSuspenseQuery
>;
export type GetLiveTableByIdQueryResult = Apollo.QueryResult<
  GetLiveTableByIdQuery,
  GetLiveTableByIdQueryVariables
>;
export const MemberzineDocument = gql`
    query memberzine {
  getCurrentZine {
    coverUrl
  }
  listFeatured: listArticles(filters: {limit: 3, category: Featured}) {
    items {
      id
      coverUrl
      title
      isFeatured
      noComments
      noLikes
      author {
        id
        name
        firstName
        lastName
        avatarUrl
      }
    }
    total
  }
  followingAuthorsArticles: listArticles(filters: {limit: 6, byAuthor: Follow}) {
    items {
      id
      coverUrl
      title
      isFeatured
      noComments
      noLikes
      author {
        id
        name
        firstName
        lastName
        avatarUrl
      }
    }
    total
  }
  recommendedArticles: listArticles(filters: {limit: 3, category: Recommended}) {
    items {
      id
      coverUrl
      title
      isFeatured
      noComments
      noLikes
      author {
        id
        name
        firstName
        lastName
        avatarUrl
      }
    }
  }
  allArticles: listArticles(filters: {limit: 6}) {
    items {
      id
      coverUrl
      title
      isFeatured
      noComments
      noLikes
      author {
        id
        name
        firstName
        lastName
        avatarUrl
      }
    }
    total
  }
}
    `;

/**
 * __useMemberzineQuery__
 *
 * To run a query within a React component, call `useMemberzineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberzineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberzineQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberzineQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberzineQuery, MemberzineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberzineQuery, MemberzineQueryVariables>(MemberzineDocument, options);
}
export function useMemberzineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberzineQuery, MemberzineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberzineQuery, MemberzineQueryVariables>(
    MemberzineDocument,
    options,
  );
}
export function useMemberzineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MemberzineQuery, MemberzineQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberzineQuery, MemberzineQueryVariables>(
    MemberzineDocument,
    options,
  );
}
export type MemberzineQueryHookResult = ReturnType<typeof useMemberzineQuery>;
export type MemberzineLazyQueryHookResult = ReturnType<typeof useMemberzineLazyQuery>;
export type MemberzineSuspenseQueryHookResult = ReturnType<typeof useMemberzineSuspenseQuery>;
export type MemberzineQueryResult = Apollo.QueryResult<MemberzineQuery, MemberzineQueryVariables>;
export const UploadArticleCoverDocument = gql`
    mutation uploadArticleCover($input: UploadImageArticleInput!) {
  uploadArticleCover(input: $input) {
    id
    coverUrl
  }
}
    `;
export type UploadArticleCoverMutationFn = Apollo.MutationFunction<
  UploadArticleCoverMutation,
  UploadArticleCoverMutationVariables
>;

/**
 * __useUploadArticleCoverMutation__
 *
 * To run a mutation, you first call `useUploadArticleCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadArticleCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadArticleCoverMutation, { data, loading, error }] = useUploadArticleCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadArticleCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadArticleCoverMutation,
    UploadArticleCoverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadArticleCoverMutation, UploadArticleCoverMutationVariables>(
    UploadArticleCoverDocument,
    options,
  );
}
export type UploadArticleCoverMutationHookResult = ReturnType<typeof useUploadArticleCoverMutation>;
export type UploadArticleCoverMutationResult = Apollo.MutationResult<UploadArticleCoverMutation>;
export type UploadArticleCoverMutationOptions = Apollo.BaseMutationOptions<
  UploadArticleCoverMutation,
  UploadArticleCoverMutationVariables
>;
export const GetArticleDocument = gql`
    query getArticle($id: String!) {
  getArticle(id: $id) {
    likedByMe {
      id
    }
    noComments
    noLikes
    author {
      id
      name
      avatarUrl
    }
    attachments {
      id
      description
      url
      meta {
        name
        size
        extension
      }
      createdAt
      updatedAt
    }
    comments {
      id
      createdAt
      message
      author {
        id
        name
        avatarUrl
      }
      reactions {
        id
        unifiedId
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      replies {
        id
        createdAt
        message
        author {
          id
          name
          avatarUrl
        }
        reactions {
          id
          unifiedId
          user {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
    content
    coverUrl
    createdAt
    id
    isDraft
    isFeatured
    publishedAt
    scheduledFor
    tags
    myEnglishJourney
    title
    theme
    updatedAt
  }
}
    `;

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleQuery(
  baseOptions: Apollo.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables> &
    ({ variables: GetArticleQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options);
}
export function useGetArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(
    GetArticleDocument,
    options,
  );
}
export function useGetArticleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleQuery, GetArticleQueryVariables>(
    GetArticleDocument,
    options,
  );
}
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>;
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>;
export type GetArticleSuspenseQueryHookResult = ReturnType<typeof useGetArticleSuspenseQuery>;
export type GetArticleQueryResult = Apollo.QueryResult<GetArticleQuery, GetArticleQueryVariables>;
export const ListArticlesDocument = gql`
    query listArticles($filters: FilterArticlesInput!) {
  listArticles(filters: $filters) {
    items {
      id
      coverUrl
      title
      isFeatured
      isDraft
      publishedAt
      noComments
      noLikes
      author {
        id
        firstName
        lastName
        name
        avatarUrl
      }
    }
    total
  }
}
    `;

/**
 * __useListArticlesQuery__
 *
 * To run a query within a React component, call `useListArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<ListArticlesQuery, ListArticlesQueryVariables> &
    ({ variables: ListArticlesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListArticlesQuery, ListArticlesQueryVariables>(
    ListArticlesDocument,
    options,
  );
}
export function useListArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListArticlesQuery, ListArticlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListArticlesQuery, ListArticlesQueryVariables>(
    ListArticlesDocument,
    options,
  );
}
export function useListArticlesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListArticlesQuery, ListArticlesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListArticlesQuery, ListArticlesQueryVariables>(
    ListArticlesDocument,
    options,
  );
}
export type ListArticlesQueryHookResult = ReturnType<typeof useListArticlesQuery>;
export type ListArticlesLazyQueryHookResult = ReturnType<typeof useListArticlesLazyQuery>;
export type ListArticlesSuspenseQueryHookResult = ReturnType<typeof useListArticlesSuspenseQuery>;
export type ListArticlesQueryResult = Apollo.QueryResult<
  ListArticlesQuery,
  ListArticlesQueryVariables
>;
export const ListNotificationsForUserDocument = gql`
    query listNotificationsForUser($cursor: NotificationCursorPaginatedInput!, $unreadOnly: Boolean!) {
  listNotificationsForUser(cursor: $cursor, unreadOnly: $unreadOnly) {
    items {
      id
      type
      createdAt
      notificationSeen {
        id
        seenAt
      }
      chatMentionNotification {
        ...ChatMentionNotificationFields
      }
      commentNotification {
        ...CommentNotificationFields
      }
      eventNotification {
        ...EventNotificationFields
      }
      articleNotification {
        ...ArticleNotificationFields
      }
    }
    total
    nextCursor
  }
}
    ${ChatMentionNotificationFieldsFragmentDoc}
${CommentNotificationFieldsFragmentDoc}
${EventNotificationFieldsFragmentDoc}
${ArticleNotificationFieldsFragmentDoc}`;

/**
 * __useListNotificationsForUserQuery__
 *
 * To run a query within a React component, call `useListNotificationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotificationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotificationsForUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      unreadOnly: // value for 'unreadOnly'
 *   },
 * });
 */
export function useListNotificationsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListNotificationsForUserQuery,
    ListNotificationsForUserQueryVariables
  > &
    ({ variables: ListNotificationsForUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListNotificationsForUserQuery, ListNotificationsForUserQueryVariables>(
    ListNotificationsForUserDocument,
    options,
  );
}
export function useListNotificationsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNotificationsForUserQuery,
    ListNotificationsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListNotificationsForUserQuery, ListNotificationsForUserQueryVariables>(
    ListNotificationsForUserDocument,
    options,
  );
}
export function useListNotificationsForUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ListNotificationsForUserQuery,
        ListNotificationsForUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNotificationsForUserQuery,
    ListNotificationsForUserQueryVariables
  >(ListNotificationsForUserDocument, options);
}
export type ListNotificationsForUserQueryHookResult = ReturnType<
  typeof useListNotificationsForUserQuery
>;
export type ListNotificationsForUserLazyQueryHookResult = ReturnType<
  typeof useListNotificationsForUserLazyQuery
>;
export type ListNotificationsForUserSuspenseQueryHookResult = ReturnType<
  typeof useListNotificationsForUserSuspenseQuery
>;
export type ListNotificationsForUserQueryResult = Apollo.QueryResult<
  ListNotificationsForUserQuery,
  ListNotificationsForUserQueryVariables
>;
export const GetPopupNotificationsDocument = gql`
    query getPopupNotifications {
  getPopupNotifications {
    id
    type
    createdAt
    notificationSeen {
      id
      seenAt
    }
    badgeNotification {
      ...BadgeNotificationFields
    }
    eventNotification {
      ...EventNotificationFields
    }
    profileUpdateNotification {
      ...ProfileUpdateNotificationFields
    }
    monthlyReportNotification {
      ...MonthlyReportNotificationFields
    }
    friendJoinedNotification {
      ...FriendJoinedNotificationFields
    }
  }
}
    ${BadgeNotificationFieldsFragmentDoc}
${EventNotificationFieldsFragmentDoc}
${ProfileUpdateNotificationFieldsFragmentDoc}
${MonthlyReportNotificationFieldsFragmentDoc}
${FriendJoinedNotificationFieldsFragmentDoc}`;

/**
 * __useGetPopupNotificationsQuery__
 *
 * To run a query within a React component, call `useGetPopupNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopupNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopupNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPopupNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPopupNotificationsQuery,
    GetPopupNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPopupNotificationsQuery, GetPopupNotificationsQueryVariables>(
    GetPopupNotificationsDocument,
    options,
  );
}
export function useGetPopupNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPopupNotificationsQuery,
    GetPopupNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPopupNotificationsQuery, GetPopupNotificationsQueryVariables>(
    GetPopupNotificationsDocument,
    options,
  );
}
export function useGetPopupNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPopupNotificationsQuery,
        GetPopupNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPopupNotificationsQuery, GetPopupNotificationsQueryVariables>(
    GetPopupNotificationsDocument,
    options,
  );
}
export type GetPopupNotificationsQueryHookResult = ReturnType<typeof useGetPopupNotificationsQuery>;
export type GetPopupNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetPopupNotificationsLazyQuery
>;
export type GetPopupNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetPopupNotificationsSuspenseQuery
>;
export type GetPopupNotificationsQueryResult = Apollo.QueryResult<
  GetPopupNotificationsQuery,
  GetPopupNotificationsQueryVariables
>;
export const GetUserByReferralCodeDocument = gql`
    query getUserByReferralCode($codeId: String!) {
  getUserByReferralCode(codeId: $codeId) {
    firstName
    avatarUrl
  }
}
    `;

/**
 * __useGetUserByReferralCodeQuery__
 *
 * To run a query within a React component, call `useGetUserByReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByReferralCodeQuery({
 *   variables: {
 *      codeId: // value for 'codeId'
 *   },
 * });
 */
export function useGetUserByReferralCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByReferralCodeQuery,
    GetUserByReferralCodeQueryVariables
  > &
    ({ variables: GetUserByReferralCodeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByReferralCodeQuery, GetUserByReferralCodeQueryVariables>(
    GetUserByReferralCodeDocument,
    options,
  );
}
export function useGetUserByReferralCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByReferralCodeQuery,
    GetUserByReferralCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByReferralCodeQuery, GetUserByReferralCodeQueryVariables>(
    GetUserByReferralCodeDocument,
    options,
  );
}
export function useGetUserByReferralCodeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserByReferralCodeQuery,
        GetUserByReferralCodeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserByReferralCodeQuery, GetUserByReferralCodeQueryVariables>(
    GetUserByReferralCodeDocument,
    options,
  );
}
export type GetUserByReferralCodeQueryHookResult = ReturnType<typeof useGetUserByReferralCodeQuery>;
export type GetUserByReferralCodeLazyQueryHookResult = ReturnType<
  typeof useGetUserByReferralCodeLazyQuery
>;
export type GetUserByReferralCodeSuspenseQueryHookResult = ReturnType<
  typeof useGetUserByReferralCodeSuspenseQuery
>;
export type GetUserByReferralCodeQueryResult = Apollo.QueryResult<
  GetUserByReferralCodeQuery,
  GetUserByReferralCodeQueryVariables
>;
export const GetInvitedFriendsCountDocument = gql`
    query getInvitedFriendsCount {
  getInvitedFriendsCount
}
    `;

/**
 * __useGetInvitedFriendsCountQuery__
 *
 * To run a query within a React component, call `useGetInvitedFriendsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedFriendsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedFriendsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvitedFriendsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvitedFriendsCountQuery,
    GetInvitedFriendsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitedFriendsCountQuery, GetInvitedFriendsCountQueryVariables>(
    GetInvitedFriendsCountDocument,
    options,
  );
}
export function useGetInvitedFriendsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitedFriendsCountQuery,
    GetInvitedFriendsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitedFriendsCountQuery, GetInvitedFriendsCountQueryVariables>(
    GetInvitedFriendsCountDocument,
    options,
  );
}
export function useGetInvitedFriendsCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetInvitedFriendsCountQuery,
        GetInvitedFriendsCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInvitedFriendsCountQuery, GetInvitedFriendsCountQueryVariables>(
    GetInvitedFriendsCountDocument,
    options,
  );
}
export type GetInvitedFriendsCountQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsCountQuery
>;
export type GetInvitedFriendsCountLazyQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsCountLazyQuery
>;
export type GetInvitedFriendsCountSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitedFriendsCountSuspenseQuery
>;
export type GetInvitedFriendsCountQueryResult = Apollo.QueryResult<
  GetInvitedFriendsCountQuery,
  GetInvitedFriendsCountQueryVariables
>;
export const SearchUsersDocument = gql`
    query searchUsers($query: String!, $eventId: String, $isVerified: Boolean) {
  searchUsers(query: $query, eventId: $eventId, isVerified: $isVerified) {
    item {
      id
      name
      avatarUrl
    }
    matches {
      indices
      value
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      eventId: // value for 'eventId'
 *      isVerified: // value for 'isVerified'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables> &
    ({ variables: SearchUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
}
export function useSearchUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options,
  );
}
export function useSearchUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options,
  );
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersSuspenseQueryHookResult = ReturnType<typeof useSearchUsersSuspenseQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<
  SearchUsersQuery,
  SearchUsersQueryVariables
>;
export const SearchMemberzineDocument = gql`
    query searchMemberzine($query: String!) {
  searchMemberzine(query: $query) {
    item {
      id
      title
      author {
        id
        name
        avatarUrl
      }
    }
    matches {
      indices
      value
      key
    }
  }
}
    `;

/**
 * __useSearchMemberzineQuery__
 *
 * To run a query within a React component, call `useSearchMemberzineQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMemberzineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMemberzineQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchMemberzineQuery(
  baseOptions: Apollo.QueryHookOptions<SearchMemberzineQuery, SearchMemberzineQueryVariables> &
    ({ variables: SearchMemberzineQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchMemberzineQuery, SearchMemberzineQueryVariables>(
    SearchMemberzineDocument,
    options,
  );
}
export function useSearchMemberzineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchMemberzineQuery, SearchMemberzineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchMemberzineQuery, SearchMemberzineQueryVariables>(
    SearchMemberzineDocument,
    options,
  );
}
export function useSearchMemberzineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchMemberzineQuery, SearchMemberzineQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchMemberzineQuery, SearchMemberzineQueryVariables>(
    SearchMemberzineDocument,
    options,
  );
}
export type SearchMemberzineQueryHookResult = ReturnType<typeof useSearchMemberzineQuery>;
export type SearchMemberzineLazyQueryHookResult = ReturnType<typeof useSearchMemberzineLazyQuery>;
export type SearchMemberzineSuspenseQueryHookResult = ReturnType<
  typeof useSearchMemberzineSuspenseQuery
>;
export type SearchMemberzineQueryResult = Apollo.QueryResult<
  SearchMemberzineQuery,
  SearchMemberzineQueryVariables
>;
export const SearchTopicsDocument = gql`
    query searchTopics($query: String!) {
  searchTopics(query: $query) {
    item {
      id
      title
    }
    matches {
      indices
      value
      key
    }
  }
}
    `;

/**
 * __useSearchTopicsQuery__
 *
 * To run a query within a React component, call `useSearchTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTopicsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchTopicsQuery, SearchTopicsQueryVariables> &
    ({ variables: SearchTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTopicsQuery, SearchTopicsQueryVariables>(
    SearchTopicsDocument,
    options,
  );
}
export function useSearchTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchTopicsQuery, SearchTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTopicsQuery, SearchTopicsQueryVariables>(
    SearchTopicsDocument,
    options,
  );
}
export function useSearchTopicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SearchTopicsQuery, SearchTopicsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchTopicsQuery, SearchTopicsQueryVariables>(
    SearchTopicsDocument,
    options,
  );
}
export type SearchTopicsQueryHookResult = ReturnType<typeof useSearchTopicsQuery>;
export type SearchTopicsLazyQueryHookResult = ReturnType<typeof useSearchTopicsLazyQuery>;
export type SearchTopicsSuspenseQueryHookResult = ReturnType<typeof useSearchTopicsSuspenseQuery>;
export type SearchTopicsQueryResult = Apollo.QueryResult<
  SearchTopicsQuery,
  SearchTopicsQueryVariables
>;
export const GetUserSignUpDocument = gql`
    query getUserSignUp($id: String!) {
  getUserSignUp(id: $id) {
    id
    firstName
    lastName
    email
    hasVerifiedEmail
  }
}
    `;

/**
 * __useGetUserSignUpQuery__
 *
 * To run a query within a React component, call `useGetUserSignUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSignUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSignUpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserSignUpQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSignUpQuery, GetUserSignUpQueryVariables> &
    ({ variables: GetUserSignUpQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSignUpQuery, GetUserSignUpQueryVariables>(
    GetUserSignUpDocument,
    options,
  );
}
export function useGetUserSignUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSignUpQuery, GetUserSignUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSignUpQuery, GetUserSignUpQueryVariables>(
    GetUserSignUpDocument,
    options,
  );
}
export function useGetUserSignUpSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserSignUpQuery, GetUserSignUpQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserSignUpQuery, GetUserSignUpQueryVariables>(
    GetUserSignUpDocument,
    options,
  );
}
export type GetUserSignUpQueryHookResult = ReturnType<typeof useGetUserSignUpQuery>;
export type GetUserSignUpLazyQueryHookResult = ReturnType<typeof useGetUserSignUpLazyQuery>;
export type GetUserSignUpSuspenseQueryHookResult = ReturnType<typeof useGetUserSignUpSuspenseQuery>;
export type GetUserSignUpQueryResult = Apollo.QueryResult<
  GetUserSignUpQuery,
  GetUserSignUpQueryVariables
>;
export const GetTotalDistinctSparksDocument = gql`
    query getTotalDistinctSparks {
  getTotalDistinctSparks
}
    `;

/**
 * __useGetTotalDistinctSparksQuery__
 *
 * To run a query within a React component, call `useGetTotalDistinctSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalDistinctSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalDistinctSparksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalDistinctSparksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTotalDistinctSparksQuery,
    GetTotalDistinctSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTotalDistinctSparksQuery, GetTotalDistinctSparksQueryVariables>(
    GetTotalDistinctSparksDocument,
    options,
  );
}
export function useGetTotalDistinctSparksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalDistinctSparksQuery,
    GetTotalDistinctSparksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTotalDistinctSparksQuery, GetTotalDistinctSparksQueryVariables>(
    GetTotalDistinctSparksDocument,
    options,
  );
}
export function useGetTotalDistinctSparksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTotalDistinctSparksQuery,
        GetTotalDistinctSparksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTotalDistinctSparksQuery, GetTotalDistinctSparksQueryVariables>(
    GetTotalDistinctSparksDocument,
    options,
  );
}
export type GetTotalDistinctSparksQueryHookResult = ReturnType<
  typeof useGetTotalDistinctSparksQuery
>;
export type GetTotalDistinctSparksLazyQueryHookResult = ReturnType<
  typeof useGetTotalDistinctSparksLazyQuery
>;
export type GetTotalDistinctSparksSuspenseQueryHookResult = ReturnType<
  typeof useGetTotalDistinctSparksSuspenseQuery
>;
export type GetTotalDistinctSparksQueryResult = Apollo.QueryResult<
  GetTotalDistinctSparksQuery,
  GetTotalDistinctSparksQueryVariables
>;
export const UserGaveSparkInEventDocument = gql`
    query userGaveSparkInEvent($eventId: String!) {
  userGaveSparkInEvent(eventId: $eventId)
}
    `;

/**
 * __useUserGaveSparkInEventQuery__
 *
 * To run a query within a React component, call `useUserGaveSparkInEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGaveSparkInEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGaveSparkInEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUserGaveSparkInEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserGaveSparkInEventQuery,
    UserGaveSparkInEventQueryVariables
  > &
    ({ variables: UserGaveSparkInEventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserGaveSparkInEventQuery, UserGaveSparkInEventQueryVariables>(
    UserGaveSparkInEventDocument,
    options,
  );
}
export function useUserGaveSparkInEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserGaveSparkInEventQuery,
    UserGaveSparkInEventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserGaveSparkInEventQuery, UserGaveSparkInEventQueryVariables>(
    UserGaveSparkInEventDocument,
    options,
  );
}
export function useUserGaveSparkInEventSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserGaveSparkInEventQuery,
        UserGaveSparkInEventQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserGaveSparkInEventQuery, UserGaveSparkInEventQueryVariables>(
    UserGaveSparkInEventDocument,
    options,
  );
}
export type UserGaveSparkInEventQueryHookResult = ReturnType<typeof useUserGaveSparkInEventQuery>;
export type UserGaveSparkInEventLazyQueryHookResult = ReturnType<
  typeof useUserGaveSparkInEventLazyQuery
>;
export type UserGaveSparkInEventSuspenseQueryHookResult = ReturnType<
  typeof useUserGaveSparkInEventSuspenseQuery
>;
export type UserGaveSparkInEventQueryResult = Apollo.QueryResult<
  UserGaveSparkInEventQuery,
  UserGaveSparkInEventQueryVariables
>;
export const ThemesDocument = gql`
    query themes($filters: FilterThemesInput!) {
  listThemes(filters: $filters) {
    id
    title
    createdAt
    description
    isCurrent
    isStandard
    isTopicLibrary
    isGrammarLibrary
    isResource
    title
    updatedAt
    cover {
      id
      title
      primary {
        mobile
        desktop
      }
      secondary {
        mobile
        desktop
      }
      tertiary {
        mobile
        desktop
      }
    }
    topics {
      id
      title
      status
      illustration {
        id
        title
        url
      }
    }
  }
}
    `;

/**
 * __useThemesQuery__
 *
 * To run a query within a React component, call `useThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useThemesQuery(
  baseOptions: Apollo.QueryHookOptions<ThemesQuery, ThemesQueryVariables> &
    ({ variables: ThemesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, options);
}
export function useThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThemesQuery, ThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, options);
}
export function useThemesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ThemesQuery, ThemesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, options);
}
export type ThemesQueryHookResult = ReturnType<typeof useThemesQuery>;
export type ThemesLazyQueryHookResult = ReturnType<typeof useThemesLazyQuery>;
export type ThemesSuspenseQueryHookResult = ReturnType<typeof useThemesSuspenseQuery>;
export type ThemesQueryResult = Apollo.QueryResult<ThemesQuery, ThemesQueryVariables>;
export const ThemeDocument = gql`
    query theme($id: String!) {
  getTheme(id: $id) {
    id
    isResource
    topics {
      id
      title
      illustration {
        id
        title
        url
      }
    }
  }
}
    `;

/**
 * __useThemeQuery__
 *
 * To run a query within a React component, call `useThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThemeQuery(
  baseOptions: Apollo.QueryHookOptions<ThemeQuery, ThemeQueryVariables> &
    ({ variables: ThemeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
}
export function useThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThemeQuery, ThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
}
export function useThemeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThemeQuery, ThemeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
}
export type ThemeQueryHookResult = ReturnType<typeof useThemeQuery>;
export type ThemeLazyQueryHookResult = ReturnType<typeof useThemeLazyQuery>;
export type ThemeSuspenseQueryHookResult = ReturnType<typeof useThemeSuspenseQuery>;
export type ThemeQueryResult = Apollo.QueryResult<ThemeQuery, ThemeQueryVariables>;
export const TopicsDocument = gql`
    query topics($filters: FilterTopicsInput!) {
  listTopics(filters: $filters) {
    id
    title
    illustration {
      id
      title
      url
    }
  }
}
    `;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables> &
    ({ variables: TopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
}
export function useTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
}
export function useTopicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TopicsQuery, TopicsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
}
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsSuspenseQueryHookResult = ReturnType<typeof useTopicsSuspenseQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const TopicDocument = gql`
    query topic($id: String!) {
  getTopic(id: $id) {
    id
    stars
    title
    ideasToDiscuss
    comments {
      id
      createdAt
      message
      author {
        id
        name
        avatarUrl
      }
      reactions {
        id
        unifiedId
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      replies {
        id
        createdAt
        message
        author {
          id
          name
          avatarUrl
        }
        reactions {
          id
          unifiedId
          user {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
    theme {
      id
      isResource
      isGrammarLibrary
      cover {
        id
        primary {
          desktop
          mobile
        }
        secondary {
          desktop
          mobile
        }
        tertiary {
          desktop
          mobile
        }
      }
    }
    illustration {
      id
      title
      url
    }
    lessons {
      id
      kind
      title
      sections {
        id
        order
        elements {
          kind
          value
          meta
        }
      }
    }
  }
}
    `;

/**
 * __useTopicQuery__
 *
 * To run a query within a React component, call `useTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopicQuery(
  baseOptions: Apollo.QueryHookOptions<TopicQuery, TopicQueryVariables> &
    ({ variables: TopicQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
}
export function useTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicQuery, TopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
}
export function useTopicSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopicQuery, TopicQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
}
export type TopicQueryHookResult = ReturnType<typeof useTopicQuery>;
export type TopicLazyQueryHookResult = ReturnType<typeof useTopicLazyQuery>;
export type TopicSuspenseQueryHookResult = ReturnType<typeof useTopicSuspenseQuery>;
export type TopicQueryResult = Apollo.QueryResult<TopicQuery, TopicQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    subscriptionTier
    avatarUrl
    isActive
    isVerified
    firstName
    isComplete
    completedPercentage
    completedHomeTour
    badge
    createdAt
    lastName
    name
    hasActivePlan
    kind
    timezone
    hasGoogleRefreshToken
    badges {
      id
      type
      achievedDate
    }
    following {
      id
    }
    followers {
      id
    }
    city
    country
    cityId
    countryId
    lat
    long
    cambridgeEnglishLevel
    birthday
    showBirthday
    showBirthdayYear
    needSpeakingPartner
    englishSkills
    interests
    displayedStatistics
    occupation {
      id
      name
    }
    story
    availabilityWeekdayFrom
    availabilityWeekdayUntil
    availabilityWeekendsFrom
    availabilityWeekendsUntil
    availability {
      from
      until
    }
    planSlug
    deletedAt
    referralCouponId
    promotionalCredits
    impersonated
    seenCompletedAt
    stepsCompleted
    lastOnlineAt
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyProfileDocument = gql`
    query myProfile {
  me {
    id
    email
    avatarUrl
    subscriptionTier
    needSpeakingPartner
    cambridgeEnglishLevel
    completedPercentage
    isVerified
    firstName
    nationality
    otherLanguage
    isComplete
    badge
    createdAt
    lastName
    city
    country
    cityId
    countryId
    lat
    long
    story
    birthday
    englishSkills
    interests
    displayedStatistics
    occupation {
      id
      name
    }
    badges {
      id
      type
      achievedDate
    }
    engagementMetrics {
      attendanceMetrics {
        totalHostedEvents
        totalParticipatedEvents
        totalParticipatedScheduledEvents
        totalParticipationDuration
        totalParticipatedOneToOnes
        totalParticipatedGroup
        totalParticipatedWebinars
        totalParticipatedInPerson
        totalParticipatedOther
      }
      publishedArticles
      totalHostedParticipants
    }
    showBirthday
    showBirthdayYear
    nativeLanguage
    name
    wantsNewsletter
    completedPercentage
    completedHomeTour
    speakingPartners
    hasActivePlan
    kind
    timezone
    hasGoogleRefreshToken
    following {
      id
    }
    followers {
      id
    }
    availabilityWeekdayFrom
    availabilityWeekdayUntil
    availabilityWeekendsFrom
    availabilityWeekendsUntil
    availability {
      from
      until
    }
    photos {
      id
      url
    }
    planSlug
    deletedAt
    referralCouponId
    promotionalCredits
    impersonated
    seenCompletedAt
    stepsCompleted
  }
}
    `;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export function useMyProfileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileSuspenseQueryHookResult = ReturnType<typeof useMyProfileSuspenseQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const GetUserSettingsDocument = gql`
    query getUserSettings {
  getUserSettings {
    id
    receiveEventUpdatesAndInvitationsNotifications
    receiveEventUpdatesAndInvitationsEmails
    receiveEventRemindersNotifications
    receiveEventRemindersEmails
    receiveEventRsvpNotifications
    receiveEventRsvpEmails
    receiveFollowsNotifications
    receiveFollowsEmails
    receiveMentionsAndCommentsNotifications
    receiveMentionsAndCommentsEmails
    receiveChatMentionsNotifications
    receiveChatMentionsEmails
    receiveLiveTableStartedNotifications
    receivePlatformStatusEmails
    receiveSubscriptionChangesEmails
    emailNotifications
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export function useGetUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export function useGetUserSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSettingsSuspenseQuery
>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables
>;
export const UserDocument = gql`
    query user($id: String!) {
  getUser(id: $id) {
    id
    email
    avatarUrl
    needSpeakingPartner
    cambridgeEnglishLevel
    firstName
    otherLanguage
    isComplete
    badge
    createdAt
    lastOnlineAt
    lastName
    city
    name
    country
    story
    birthday
    englishSkills
    interests
    occupation {
      id
      name
    }
    showBirthday
    nativeLanguage
    completedPercentage
    followers {
      follower {
        id
        name
      }
    }
    availability {
      from
      until
    }
    photos {
      id
      url
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SimpleUserDocument = gql`
    query simpleUser($id: String!) {
  getUser(id: $id) {
    id
    firstName
  }
}
    `;

/**
 * __useSimpleUserQuery__
 *
 * To run a query within a React component, call `useSimpleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimpleUserQuery(
  baseOptions: Apollo.QueryHookOptions<SimpleUserQuery, SimpleUserQueryVariables> &
    ({ variables: SimpleUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleUserQuery, SimpleUserQueryVariables>(SimpleUserDocument, options);
}
export function useSimpleUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleUserQuery, SimpleUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleUserQuery, SimpleUserQueryVariables>(
    SimpleUserDocument,
    options,
  );
}
export function useSimpleUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SimpleUserQuery, SimpleUserQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleUserQuery, SimpleUserQueryVariables>(
    SimpleUserDocument,
    options,
  );
}
export type SimpleUserQueryHookResult = ReturnType<typeof useSimpleUserQuery>;
export type SimpleUserLazyQueryHookResult = ReturnType<typeof useSimpleUserLazyQuery>;
export type SimpleUserSuspenseQueryHookResult = ReturnType<typeof useSimpleUserSuspenseQuery>;
export type SimpleUserQueryResult = Apollo.QueryResult<SimpleUserQuery, SimpleUserQueryVariables>;
export const PlanDetailsAndPaymentMethodDocument = gql`
    query planDetailsAndPaymentMethod {
  planDetails {
    meta
    interval
    intervalCount
    name
    amount
    userAmount
    start
    end
    status
    resumesAt
    cancelledAt
  }
  paymentMethod {
    last4Digits
    expYear
    expMonth
    type
    email
  }
  invoices {
    id
    created
    description
    amount
    currency
    downloadUrl
    status
  }
}
    `;

/**
 * __usePlanDetailsAndPaymentMethodQuery__
 *
 * To run a query within a React component, call `usePlanDetailsAndPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanDetailsAndPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanDetailsAndPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanDetailsAndPaymentMethodQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlanDetailsAndPaymentMethodQuery,
    PlanDetailsAndPaymentMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlanDetailsAndPaymentMethodQuery,
    PlanDetailsAndPaymentMethodQueryVariables
  >(PlanDetailsAndPaymentMethodDocument, options);
}
export function usePlanDetailsAndPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanDetailsAndPaymentMethodQuery,
    PlanDetailsAndPaymentMethodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlanDetailsAndPaymentMethodQuery,
    PlanDetailsAndPaymentMethodQueryVariables
  >(PlanDetailsAndPaymentMethodDocument, options);
}
export function usePlanDetailsAndPaymentMethodSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PlanDetailsAndPaymentMethodQuery,
        PlanDetailsAndPaymentMethodQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PlanDetailsAndPaymentMethodQuery,
    PlanDetailsAndPaymentMethodQueryVariables
  >(PlanDetailsAndPaymentMethodDocument, options);
}
export type PlanDetailsAndPaymentMethodQueryHookResult = ReturnType<
  typeof usePlanDetailsAndPaymentMethodQuery
>;
export type PlanDetailsAndPaymentMethodLazyQueryHookResult = ReturnType<
  typeof usePlanDetailsAndPaymentMethodLazyQuery
>;
export type PlanDetailsAndPaymentMethodSuspenseQueryHookResult = ReturnType<
  typeof usePlanDetailsAndPaymentMethodSuspenseQuery
>;
export type PlanDetailsAndPaymentMethodQueryResult = Apollo.QueryResult<
  PlanDetailsAndPaymentMethodQuery,
  PlanDetailsAndPaymentMethodQueryVariables
>;
export const CanPauseDocument = gql`
    query canPause {
  canPause {
    canPause
    pauseCredits
  }
}
    `;

/**
 * __useCanPauseQuery__
 *
 * To run a query within a React component, call `useCanPauseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanPauseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanPauseQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanPauseQuery(
  baseOptions?: Apollo.QueryHookOptions<CanPauseQuery, CanPauseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanPauseQuery, CanPauseQueryVariables>(CanPauseDocument, options);
}
export function useCanPauseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CanPauseQuery, CanPauseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanPauseQuery, CanPauseQueryVariables>(CanPauseDocument, options);
}
export function useCanPauseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CanPauseQuery, CanPauseQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CanPauseQuery, CanPauseQueryVariables>(CanPauseDocument, options);
}
export type CanPauseQueryHookResult = ReturnType<typeof useCanPauseQuery>;
export type CanPauseLazyQueryHookResult = ReturnType<typeof useCanPauseLazyQuery>;
export type CanPauseSuspenseQueryHookResult = ReturnType<typeof useCanPauseSuspenseQuery>;
export type CanPauseQueryResult = Apollo.QueryResult<CanPauseQuery, CanPauseQueryVariables>;
export const GetOccupationsDocument = gql`
    query getOccupations {
  getOccupations {
    id
    name
  }
}
    `;

/**
 * __useGetOccupationsQuery__
 *
 * To run a query within a React component, call `useGetOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOccupationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOccupationsQuery, GetOccupationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOccupationsQuery, GetOccupationsQueryVariables>(
    GetOccupationsDocument,
    options,
  );
}
export function useGetOccupationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOccupationsQuery, GetOccupationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOccupationsQuery, GetOccupationsQueryVariables>(
    GetOccupationsDocument,
    options,
  );
}
export function useGetOccupationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetOccupationsQuery, GetOccupationsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOccupationsQuery, GetOccupationsQueryVariables>(
    GetOccupationsDocument,
    options,
  );
}
export type GetOccupationsQueryHookResult = ReturnType<typeof useGetOccupationsQuery>;
export type GetOccupationsLazyQueryHookResult = ReturnType<typeof useGetOccupationsLazyQuery>;
export type GetOccupationsSuspenseQueryHookResult = ReturnType<
  typeof useGetOccupationsSuspenseQuery
>;
export type GetOccupationsQueryResult = Apollo.QueryResult<
  GetOccupationsQuery,
  GetOccupationsQueryVariables
>;
export const UserHasNoAcceptedInvitesDocument = gql`
    query userHasNoAcceptedInvites {
  userHasNoAcceptedInvites
}
    `;

/**
 * __useUserHasNoAcceptedInvitesQuery__
 *
 * To run a query within a React component, call `useUserHasNoAcceptedInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasNoAcceptedInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasNoAcceptedInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasNoAcceptedInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserHasNoAcceptedInvitesQuery,
    UserHasNoAcceptedInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHasNoAcceptedInvitesQuery, UserHasNoAcceptedInvitesQueryVariables>(
    UserHasNoAcceptedInvitesDocument,
    options,
  );
}
export function useUserHasNoAcceptedInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserHasNoAcceptedInvitesQuery,
    UserHasNoAcceptedInvitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHasNoAcceptedInvitesQuery, UserHasNoAcceptedInvitesQueryVariables>(
    UserHasNoAcceptedInvitesDocument,
    options,
  );
}
export function useUserHasNoAcceptedInvitesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserHasNoAcceptedInvitesQuery,
        UserHasNoAcceptedInvitesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserHasNoAcceptedInvitesQuery,
    UserHasNoAcceptedInvitesQueryVariables
  >(UserHasNoAcceptedInvitesDocument, options);
}
export type UserHasNoAcceptedInvitesQueryHookResult = ReturnType<
  typeof useUserHasNoAcceptedInvitesQuery
>;
export type UserHasNoAcceptedInvitesLazyQueryHookResult = ReturnType<
  typeof useUserHasNoAcceptedInvitesLazyQuery
>;
export type UserHasNoAcceptedInvitesSuspenseQueryHookResult = ReturnType<
  typeof useUserHasNoAcceptedInvitesSuspenseQuery
>;
export type UserHasNoAcceptedInvitesQueryResult = Apollo.QueryResult<
  UserHasNoAcceptedInvitesQuery,
  UserHasNoAcceptedInvitesQueryVariables
>;
export const AttendedEventsDocument = gql`
    query attendedEvents($input: StatisticsInput!) {
  attendedEvents(input: $input) {
    oneToOnes
    groupEvents
    webinars
    inPersonEvents
    other
  }
}
    `;

/**
 * __useAttendedEventsQuery__
 *
 * To run a query within a React component, call `useAttendedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<AttendedEventsQuery, AttendedEventsQueryVariables> &
    ({ variables: AttendedEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendedEventsQuery, AttendedEventsQueryVariables>(
    AttendedEventsDocument,
    options,
  );
}
export function useAttendedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendedEventsQuery, AttendedEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendedEventsQuery, AttendedEventsQueryVariables>(
    AttendedEventsDocument,
    options,
  );
}
export function useAttendedEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AttendedEventsQuery, AttendedEventsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AttendedEventsQuery, AttendedEventsQueryVariables>(
    AttendedEventsDocument,
    options,
  );
}
export type AttendedEventsQueryHookResult = ReturnType<typeof useAttendedEventsQuery>;
export type AttendedEventsLazyQueryHookResult = ReturnType<typeof useAttendedEventsLazyQuery>;
export type AttendedEventsSuspenseQueryHookResult = ReturnType<
  typeof useAttendedEventsSuspenseQuery
>;
export type AttendedEventsQueryResult = Apollo.QueryResult<
  AttendedEventsQuery,
  AttendedEventsQueryVariables
>;
export const AttendanceRecordDocument = gql`
    query attendanceRecord($input: StatisticsInput!) {
  attendanceRecord(input: $input) {
    attendanceRatio
    totalRsvps
    attended
  }
}
    `;

/**
 * __useAttendanceRecordQuery__
 *
 * To run a query within a React component, call `useAttendanceRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceRecordQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendanceRecordQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceRecordQuery, AttendanceRecordQueryVariables> &
    ({ variables: AttendanceRecordQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceRecordQuery, AttendanceRecordQueryVariables>(
    AttendanceRecordDocument,
    options,
  );
}
export function useAttendanceRecordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceRecordQuery, AttendanceRecordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceRecordQuery, AttendanceRecordQueryVariables>(
    AttendanceRecordDocument,
    options,
  );
}
export function useAttendanceRecordSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AttendanceRecordQuery, AttendanceRecordQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AttendanceRecordQuery, AttendanceRecordQueryVariables>(
    AttendanceRecordDocument,
    options,
  );
}
export type AttendanceRecordQueryHookResult = ReturnType<typeof useAttendanceRecordQuery>;
export type AttendanceRecordLazyQueryHookResult = ReturnType<typeof useAttendanceRecordLazyQuery>;
export type AttendanceRecordSuspenseQueryHookResult = ReturnType<
  typeof useAttendanceRecordSuspenseQuery
>;
export type AttendanceRecordQueryResult = Apollo.QueryResult<
  AttendanceRecordQuery,
  AttendanceRecordQueryVariables
>;
export const ParticipationTimeDocument = gql`
    query participationTime($input: StatisticsInput!) {
  participationTime(input: $input)
}
    `;

/**
 * __useParticipationTimeQuery__
 *
 * To run a query within a React component, call `useParticipationTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipationTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationTimeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParticipationTimeQuery(
  baseOptions: Apollo.QueryHookOptions<ParticipationTimeQuery, ParticipationTimeQueryVariables> &
    ({ variables: ParticipationTimeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ParticipationTimeQuery, ParticipationTimeQueryVariables>(
    ParticipationTimeDocument,
    options,
  );
}
export function useParticipationTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipationTimeQuery,
    ParticipationTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ParticipationTimeQuery, ParticipationTimeQueryVariables>(
    ParticipationTimeDocument,
    options,
  );
}
export function useParticipationTimeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ParticipationTimeQuery, ParticipationTimeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ParticipationTimeQuery, ParticipationTimeQueryVariables>(
    ParticipationTimeDocument,
    options,
  );
}
export type ParticipationTimeQueryHookResult = ReturnType<typeof useParticipationTimeQuery>;
export type ParticipationTimeLazyQueryHookResult = ReturnType<typeof useParticipationTimeLazyQuery>;
export type ParticipationTimeSuspenseQueryHookResult = ReturnType<
  typeof useParticipationTimeSuspenseQuery
>;
export type ParticipationTimeQueryResult = Apollo.QueryResult<
  ParticipationTimeQuery,
  ParticipationTimeQueryVariables
>;
export const GlobalNetworkDocument = gql`
    query globalNetwork($input: StatisticsInput!) {
  globalNetwork(input: $input) {
    lat
    long
  }
}
    `;

/**
 * __useGlobalNetworkQuery__
 *
 * To run a query within a React component, call `useGlobalNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalNetworkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGlobalNetworkQuery(
  baseOptions: Apollo.QueryHookOptions<GlobalNetworkQuery, GlobalNetworkQueryVariables> &
    ({ variables: GlobalNetworkQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalNetworkQuery, GlobalNetworkQueryVariables>(
    GlobalNetworkDocument,
    options,
  );
}
export function useGlobalNetworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalNetworkQuery, GlobalNetworkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalNetworkQuery, GlobalNetworkQueryVariables>(
    GlobalNetworkDocument,
    options,
  );
}
export function useGlobalNetworkSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GlobalNetworkQuery, GlobalNetworkQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GlobalNetworkQuery, GlobalNetworkQueryVariables>(
    GlobalNetworkDocument,
    options,
  );
}
export type GlobalNetworkQueryHookResult = ReturnType<typeof useGlobalNetworkQuery>;
export type GlobalNetworkLazyQueryHookResult = ReturnType<typeof useGlobalNetworkLazyQuery>;
export type GlobalNetworkSuspenseQueryHookResult = ReturnType<typeof useGlobalNetworkSuspenseQuery>;
export type GlobalNetworkQueryResult = Apollo.QueryResult<
  GlobalNetworkQuery,
  GlobalNetworkQueryVariables
>;
export const FriendlyFacesDocument = gql`
    query friendlyFaces($input: StatisticsInput!) {
  friendlyFaces(input: $input) {
    userId
    firstName
    lastName
    avatarUrl
  }
}
    `;

/**
 * __useFriendlyFacesQuery__
 *
 * To run a query within a React component, call `useFriendlyFacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendlyFacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendlyFacesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFriendlyFacesQuery(
  baseOptions: Apollo.QueryHookOptions<FriendlyFacesQuery, FriendlyFacesQueryVariables> &
    ({ variables: FriendlyFacesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FriendlyFacesQuery, FriendlyFacesQueryVariables>(
    FriendlyFacesDocument,
    options,
  );
}
export function useFriendlyFacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FriendlyFacesQuery, FriendlyFacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FriendlyFacesQuery, FriendlyFacesQueryVariables>(
    FriendlyFacesDocument,
    options,
  );
}
export function useFriendlyFacesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FriendlyFacesQuery, FriendlyFacesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FriendlyFacesQuery, FriendlyFacesQueryVariables>(
    FriendlyFacesDocument,
    options,
  );
}
export type FriendlyFacesQueryHookResult = ReturnType<typeof useFriendlyFacesQuery>;
export type FriendlyFacesLazyQueryHookResult = ReturnType<typeof useFriendlyFacesLazyQuery>;
export type FriendlyFacesSuspenseQueryHookResult = ReturnType<typeof useFriendlyFacesSuspenseQuery>;
export type FriendlyFacesQueryResult = Apollo.QueryResult<
  FriendlyFacesQuery,
  FriendlyFacesQueryVariables
>;
export const ArticlesWrittenDocument = gql`
    query articlesWritten($input: StatisticsInput!) {
  articlesWritten(input: $input)
}
    `;

/**
 * __useArticlesWrittenQuery__
 *
 * To run a query within a React component, call `useArticlesWrittenQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesWrittenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesWrittenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticlesWrittenQuery(
  baseOptions: Apollo.QueryHookOptions<ArticlesWrittenQuery, ArticlesWrittenQueryVariables> &
    ({ variables: ArticlesWrittenQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesWrittenQuery, ArticlesWrittenQueryVariables>(
    ArticlesWrittenDocument,
    options,
  );
}
export function useArticlesWrittenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticlesWrittenQuery, ArticlesWrittenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesWrittenQuery, ArticlesWrittenQueryVariables>(
    ArticlesWrittenDocument,
    options,
  );
}
export function useArticlesWrittenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ArticlesWrittenQuery, ArticlesWrittenQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ArticlesWrittenQuery, ArticlesWrittenQueryVariables>(
    ArticlesWrittenDocument,
    options,
  );
}
export type ArticlesWrittenQueryHookResult = ReturnType<typeof useArticlesWrittenQuery>;
export type ArticlesWrittenLazyQueryHookResult = ReturnType<typeof useArticlesWrittenLazyQuery>;
export type ArticlesWrittenSuspenseQueryHookResult = ReturnType<
  typeof useArticlesWrittenSuspenseQuery
>;
export type ArticlesWrittenQueryResult = Apollo.QueryResult<
  ArticlesWrittenQuery,
  ArticlesWrittenQueryVariables
>;
export const DailyEventsDocument = gql`
    query dailyEvents($input: StatisticsInput!) {
  dailyEvents(input: $input) {
    day
    count
  }
}
    `;

/**
 * __useDailyEventsQuery__
 *
 * To run a query within a React component, call `useDailyEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDailyEventsQuery(
  baseOptions: Apollo.QueryHookOptions<DailyEventsQuery, DailyEventsQueryVariables> &
    ({ variables: DailyEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyEventsQuery, DailyEventsQueryVariables>(DailyEventsDocument, options);
}
export function useDailyEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyEventsQuery, DailyEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyEventsQuery, DailyEventsQueryVariables>(
    DailyEventsDocument,
    options,
  );
}
export function useDailyEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DailyEventsQuery, DailyEventsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyEventsQuery, DailyEventsQueryVariables>(
    DailyEventsDocument,
    options,
  );
}
export type DailyEventsQueryHookResult = ReturnType<typeof useDailyEventsQuery>;
export type DailyEventsLazyQueryHookResult = ReturnType<typeof useDailyEventsLazyQuery>;
export type DailyEventsSuspenseQueryHookResult = ReturnType<typeof useDailyEventsSuspenseQuery>;
export type DailyEventsQueryResult = Apollo.QueryResult<
  DailyEventsQuery,
  DailyEventsQueryVariables
>;
export const HostedEventsStatisticsDocument = gql`
    query hostedEventsStatistics($input: StatisticsInput!, $prevInput: StatisticsInput!) {
  hostedEvents(input: $input)
  previousHostedEvents: hostedEvents(input: $prevInput)
  ladiesAttended(input: $input)
  userHostedDistinctSparks(input: $input)
}
    `;

/**
 * __useHostedEventsStatisticsQuery__
 *
 * To run a query within a React component, call `useHostedEventsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHostedEventsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHostedEventsStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      prevInput: // value for 'prevInput'
 *   },
 * });
 */
export function useHostedEventsStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HostedEventsStatisticsQuery,
    HostedEventsStatisticsQueryVariables
  > &
    ({ variables: HostedEventsStatisticsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HostedEventsStatisticsQuery, HostedEventsStatisticsQueryVariables>(
    HostedEventsStatisticsDocument,
    options,
  );
}
export function useHostedEventsStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HostedEventsStatisticsQuery,
    HostedEventsStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HostedEventsStatisticsQuery, HostedEventsStatisticsQueryVariables>(
    HostedEventsStatisticsDocument,
    options,
  );
}
export function useHostedEventsStatisticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HostedEventsStatisticsQuery,
        HostedEventsStatisticsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HostedEventsStatisticsQuery, HostedEventsStatisticsQueryVariables>(
    HostedEventsStatisticsDocument,
    options,
  );
}
export type HostedEventsStatisticsQueryHookResult = ReturnType<
  typeof useHostedEventsStatisticsQuery
>;
export type HostedEventsStatisticsLazyQueryHookResult = ReturnType<
  typeof useHostedEventsStatisticsLazyQuery
>;
export type HostedEventsStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useHostedEventsStatisticsSuspenseQuery
>;
export type HostedEventsStatisticsQueryResult = Apollo.QueryResult<
  HostedEventsStatisticsQuery,
  HostedEventsStatisticsQueryVariables
>;
export const MonthlyRecapStepsDocument = gql`
    query monthlyRecapSteps($input: StatisticsInput!) {
  monthlyRecapSteps(input: $input)
}
    `;

/**
 * __useMonthlyRecapStepsQuery__
 *
 * To run a query within a React component, call `useMonthlyRecapStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyRecapStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyRecapStepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMonthlyRecapStepsQuery(
  baseOptions: Apollo.QueryHookOptions<MonthlyRecapStepsQuery, MonthlyRecapStepsQueryVariables> &
    ({ variables: MonthlyRecapStepsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MonthlyRecapStepsQuery, MonthlyRecapStepsQueryVariables>(
    MonthlyRecapStepsDocument,
    options,
  );
}
export function useMonthlyRecapStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonthlyRecapStepsQuery,
    MonthlyRecapStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MonthlyRecapStepsQuery, MonthlyRecapStepsQueryVariables>(
    MonthlyRecapStepsDocument,
    options,
  );
}
export function useMonthlyRecapStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MonthlyRecapStepsQuery, MonthlyRecapStepsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MonthlyRecapStepsQuery, MonthlyRecapStepsQueryVariables>(
    MonthlyRecapStepsDocument,
    options,
  );
}
export type MonthlyRecapStepsQueryHookResult = ReturnType<typeof useMonthlyRecapStepsQuery>;
export type MonthlyRecapStepsLazyQueryHookResult = ReturnType<typeof useMonthlyRecapStepsLazyQuery>;
export type MonthlyRecapStepsSuspenseQueryHookResult = ReturnType<
  typeof useMonthlyRecapStepsSuspenseQuery
>;
export type MonthlyRecapStepsQueryResult = Apollo.QueryResult<
  MonthlyRecapStepsQuery,
  MonthlyRecapStepsQueryVariables
>;
export const SeenMonthlyReportDocument = gql`
    query seenMonthlyReport {
  seenMonthlyReport
}
    `;

/**
 * __useSeenMonthlyReportQuery__
 *
 * To run a query within a React component, call `useSeenMonthlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeenMonthlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeenMonthlyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeenMonthlyReportQuery(
  baseOptions?: Apollo.QueryHookOptions<SeenMonthlyReportQuery, SeenMonthlyReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeenMonthlyReportQuery, SeenMonthlyReportQueryVariables>(
    SeenMonthlyReportDocument,
    options,
  );
}
export function useSeenMonthlyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SeenMonthlyReportQuery,
    SeenMonthlyReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeenMonthlyReportQuery, SeenMonthlyReportQueryVariables>(
    SeenMonthlyReportDocument,
    options,
  );
}
export function useSeenMonthlyReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SeenMonthlyReportQuery, SeenMonthlyReportQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SeenMonthlyReportQuery, SeenMonthlyReportQueryVariables>(
    SeenMonthlyReportDocument,
    options,
  );
}
export type SeenMonthlyReportQueryHookResult = ReturnType<typeof useSeenMonthlyReportQuery>;
export type SeenMonthlyReportLazyQueryHookResult = ReturnType<typeof useSeenMonthlyReportLazyQuery>;
export type SeenMonthlyReportSuspenseQueryHookResult = ReturnType<
  typeof useSeenMonthlyReportSuspenseQuery
>;
export type SeenMonthlyReportQueryResult = Apollo.QueryResult<
  SeenMonthlyReportQuery,
  SeenMonthlyReportQueryVariables
>;
export const GetGroupedOccupationsDocument = gql`
    query getGroupedOccupations {
  getGroupedOccupations {
    id
    name
    industry {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGroupedOccupationsQuery__
 *
 * To run a query within a React component, call `useGetGroupedOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupedOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupedOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupedOccupationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGroupedOccupationsQuery,
    GetGroupedOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupedOccupationsQuery, GetGroupedOccupationsQueryVariables>(
    GetGroupedOccupationsDocument,
    options,
  );
}
export function useGetGroupedOccupationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupedOccupationsQuery,
    GetGroupedOccupationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupedOccupationsQuery, GetGroupedOccupationsQueryVariables>(
    GetGroupedOccupationsDocument,
    options,
  );
}
export function useGetGroupedOccupationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetGroupedOccupationsQuery,
        GetGroupedOccupationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGroupedOccupationsQuery, GetGroupedOccupationsQueryVariables>(
    GetGroupedOccupationsDocument,
    options,
  );
}
export type GetGroupedOccupationsQueryHookResult = ReturnType<typeof useGetGroupedOccupationsQuery>;
export type GetGroupedOccupationsLazyQueryHookResult = ReturnType<
  typeof useGetGroupedOccupationsLazyQuery
>;
export type GetGroupedOccupationsSuspenseQueryHookResult = ReturnType<
  typeof useGetGroupedOccupationsSuspenseQuery
>;
export type GetGroupedOccupationsQueryResult = Apollo.QueryResult<
  GetGroupedOccupationsQuery,
  GetGroupedOccupationsQueryVariables
>;
export const GetSparksDocument = gql`
    query getSparks($input: StatisticsInput!) {
  userDistinctSparks(input: $input)
  userAttendedDistinctSparks(input: $input)
  communityDistinctSparks(input: $input)
}
    `;

/**
 * __useGetSparksQuery__
 *
 * To run a query within a React component, call `useGetSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSparksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSparksQuery(
  baseOptions: Apollo.QueryHookOptions<GetSparksQuery, GetSparksQueryVariables> &
    ({ variables: GetSparksQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSparksQuery, GetSparksQueryVariables>(GetSparksDocument, options);
}
export function useGetSparksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSparksQuery, GetSparksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSparksQuery, GetSparksQueryVariables>(GetSparksDocument, options);
}
export function useGetSparksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSparksQuery, GetSparksQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSparksQuery, GetSparksQueryVariables>(
    GetSparksDocument,
    options,
  );
}
export type GetSparksQueryHookResult = ReturnType<typeof useGetSparksQuery>;
export type GetSparksLazyQueryHookResult = ReturnType<typeof useGetSparksLazyQuery>;
export type GetSparksSuspenseQueryHookResult = ReturnType<typeof useGetSparksSuspenseQuery>;
export type GetSparksQueryResult = Apollo.QueryResult<GetSparksQuery, GetSparksQueryVariables>;
export const ListRecommendedUsersDocument = gql`
    query listRecommendedUsers {
  listRecommendedUsers {
    userId
    user {
      id
      firstName
      lastName
      avatarUrl
      city
      country
      story
    }
  }
}
    `;

/**
 * __useListRecommendedUsersQuery__
 *
 * To run a query within a React component, call `useListRecommendedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecommendedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecommendedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRecommendedUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRecommendedUsersQuery,
    ListRecommendedUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListRecommendedUsersQuery, ListRecommendedUsersQueryVariables>(
    ListRecommendedUsersDocument,
    options,
  );
}
export function useListRecommendedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRecommendedUsersQuery,
    ListRecommendedUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListRecommendedUsersQuery, ListRecommendedUsersQueryVariables>(
    ListRecommendedUsersDocument,
    options,
  );
}
export function useListRecommendedUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ListRecommendedUsersQuery,
        ListRecommendedUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListRecommendedUsersQuery, ListRecommendedUsersQueryVariables>(
    ListRecommendedUsersDocument,
    options,
  );
}
export type ListRecommendedUsersQueryHookResult = ReturnType<typeof useListRecommendedUsersQuery>;
export type ListRecommendedUsersLazyQueryHookResult = ReturnType<
  typeof useListRecommendedUsersLazyQuery
>;
export type ListRecommendedUsersSuspenseQueryHookResult = ReturnType<
  typeof useListRecommendedUsersSuspenseQuery
>;
export type ListRecommendedUsersQueryResult = Apollo.QueryResult<
  ListRecommendedUsersQuery,
  ListRecommendedUsersQueryVariables
>;
export const ListMatchedUsersDocument = gql`
    query listMatchedUsers($filters: FilterUsersInput!) {
  listMatchedUsers(filters: $filters) {
    items {
      userId
      user {
        id
        firstName
        lastName
        badge
        createdAt
        lastOnlineAt
        avatarUrl
        interests
        englishSkills
        occupation {
          id
          name
        }
        city
        country
        cambridgeEnglishLevel
        availabilityWeekdayFrom
        availabilityWeekdayUntil
        availabilityWeekendsFrom
        availabilityWeekendsUntil
        needSpeakingPartner
        availability {
          from
          until
        }
      }
      matchDetails {
        commonInterests
        commonEnglishSkills
        sameCity
        availabilityMatch
      }
      matchScores {
        interestScore
        englishInterestScore
        englishLevelScore
        availabilityScore
        eventParticipationScore
        locationScore
      }
      matchWeights {
        interestScore
        englishInterestScore
        englishLevelScore
        availabilityScore
        eventParticipationScore
        locationScore
      }
      score
      defaultMatchScore
    }
    total
  }
}
    `;

/**
 * __useListMatchedUsersQuery__
 *
 * To run a query within a React component, call `useListMatchedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMatchedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMatchedUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListMatchedUsersQuery(
  baseOptions: Apollo.QueryHookOptions<ListMatchedUsersQuery, ListMatchedUsersQueryVariables> &
    ({ variables: ListMatchedUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMatchedUsersQuery, ListMatchedUsersQueryVariables>(
    ListMatchedUsersDocument,
    options,
  );
}
export function useListMatchedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMatchedUsersQuery, ListMatchedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMatchedUsersQuery, ListMatchedUsersQueryVariables>(
    ListMatchedUsersDocument,
    options,
  );
}
export function useListMatchedUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ListMatchedUsersQuery, ListMatchedUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListMatchedUsersQuery, ListMatchedUsersQueryVariables>(
    ListMatchedUsersDocument,
    options,
  );
}
export type ListMatchedUsersQueryHookResult = ReturnType<typeof useListMatchedUsersQuery>;
export type ListMatchedUsersLazyQueryHookResult = ReturnType<typeof useListMatchedUsersLazyQuery>;
export type ListMatchedUsersSuspenseQueryHookResult = ReturnType<
  typeof useListMatchedUsersSuspenseQuery
>;
export type ListMatchedUsersQueryResult = Apollo.QueryResult<
  ListMatchedUsersQuery,
  ListMatchedUsersQueryVariables
>;
export const GetUserMatchingInfoDocument = gql`
    query getUserMatchingInfo($id: String!) {
  getUserMatchingInfo(id: $id) {
    userId
    user {
      id
      availabilityWeekdayFrom
      availabilityWeekdayUntil
      availabilityWeekendsFrom
      availabilityWeekendsUntil
      email
      avatarUrl
      needSpeakingPartner
      cambridgeEnglishLevel
      firstName
      nationality
      otherLanguage
      isComplete
      badge
      createdAt
      lastOnlineAt
      lastName
      city
      name
      country
      story
      birthday
      englishSkills
      interests
      occupation {
        id
        name
      }
      badges {
        id
        type
        achievedDate
      }
      displayedStatistics
      engagementMetrics {
        attendanceMetrics {
          totalHostedEvents
          totalParticipatedEvents
          totalParticipatedScheduledEvents
          totalParticipationDuration
          totalParticipatedOneToOnes
          totalParticipatedGroup
          totalParticipatedWebinars
          totalParticipatedOther
        }
        publishedArticles
        totalHostedParticipants
      }
      showBirthday
      showBirthdayYear
      nativeLanguage
      completedPercentage
      followers {
        follower {
          id
          name
        }
      }
      availability {
        from
        until
      }
      photos {
        id
        url
      }
    }
    matchDetails {
      availabilityMatch
      commonInterests
      commonEnglishSkills
      sameCity
    }
    defaultMatchScore
  }
}
    `;

/**
 * __useGetUserMatchingInfoQuery__
 *
 * To run a query within a React component, call `useGetUserMatchingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMatchingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMatchingInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserMatchingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserMatchingInfoQuery,
    GetUserMatchingInfoQueryVariables
  > &
    ({ variables: GetUserMatchingInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserMatchingInfoQuery, GetUserMatchingInfoQueryVariables>(
    GetUserMatchingInfoDocument,
    options,
  );
}
export function useGetUserMatchingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMatchingInfoQuery,
    GetUserMatchingInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserMatchingInfoQuery, GetUserMatchingInfoQueryVariables>(
    GetUserMatchingInfoDocument,
    options,
  );
}
export function useGetUserMatchingInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserMatchingInfoQuery, GetUserMatchingInfoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserMatchingInfoQuery, GetUserMatchingInfoQueryVariables>(
    GetUserMatchingInfoDocument,
    options,
  );
}
export type GetUserMatchingInfoQueryHookResult = ReturnType<typeof useGetUserMatchingInfoQuery>;
export type GetUserMatchingInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserMatchingInfoLazyQuery
>;
export type GetUserMatchingInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetUserMatchingInfoSuspenseQuery
>;
export type GetUserMatchingInfoQueryResult = Apollo.QueryResult<
  GetUserMatchingInfoQuery,
  GetUserMatchingInfoQueryVariables
>;
export const GetAvalibilityMatchForUsersDocument = gql`
    query getAvalibilityMatchForUsers($input: AvailabilityForUsersInput!) {
  getAvalibilityMatchForUsers(input: $input) {
    userId
    availabilityMatch
  }
}
    `;

/**
 * __useGetAvalibilityMatchForUsersQuery__
 *
 * To run a query within a React component, call `useGetAvalibilityMatchForUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvalibilityMatchForUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvalibilityMatchForUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAvalibilityMatchForUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvalibilityMatchForUsersQuery,
    GetAvalibilityMatchForUsersQueryVariables
  > &
    ({ variables: GetAvalibilityMatchForUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvalibilityMatchForUsersQuery,
    GetAvalibilityMatchForUsersQueryVariables
  >(GetAvalibilityMatchForUsersDocument, options);
}
export function useGetAvalibilityMatchForUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvalibilityMatchForUsersQuery,
    GetAvalibilityMatchForUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvalibilityMatchForUsersQuery,
    GetAvalibilityMatchForUsersQueryVariables
  >(GetAvalibilityMatchForUsersDocument, options);
}
export function useGetAvalibilityMatchForUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAvalibilityMatchForUsersQuery,
        GetAvalibilityMatchForUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvalibilityMatchForUsersQuery,
    GetAvalibilityMatchForUsersQueryVariables
  >(GetAvalibilityMatchForUsersDocument, options);
}
export type GetAvalibilityMatchForUsersQueryHookResult = ReturnType<
  typeof useGetAvalibilityMatchForUsersQuery
>;
export type GetAvalibilityMatchForUsersLazyQueryHookResult = ReturnType<
  typeof useGetAvalibilityMatchForUsersLazyQuery
>;
export type GetAvalibilityMatchForUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetAvalibilityMatchForUsersSuspenseQuery
>;
export type GetAvalibilityMatchForUsersQueryResult = Apollo.QueryResult<
  GetAvalibilityMatchForUsersQuery,
  GetAvalibilityMatchForUsersQueryVariables
>;
export const CompletedVerificationFormDocument = gql`
    query completedVerificationForm {
  completedVerificationForm {
    id
  }
}
    `;

/**
 * __useCompletedVerificationFormQuery__
 *
 * To run a query within a React component, call `useCompletedVerificationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedVerificationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedVerificationFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedVerificationFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletedVerificationFormQuery,
    CompletedVerificationFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompletedVerificationFormQuery, CompletedVerificationFormQueryVariables>(
    CompletedVerificationFormDocument,
    options,
  );
}
export function useCompletedVerificationFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedVerificationFormQuery,
    CompletedVerificationFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompletedVerificationFormQuery,
    CompletedVerificationFormQueryVariables
  >(CompletedVerificationFormDocument, options);
}
export function useCompletedVerificationFormSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompletedVerificationFormQuery,
        CompletedVerificationFormQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompletedVerificationFormQuery,
    CompletedVerificationFormQueryVariables
  >(CompletedVerificationFormDocument, options);
}
export type CompletedVerificationFormQueryHookResult = ReturnType<
  typeof useCompletedVerificationFormQuery
>;
export type CompletedVerificationFormLazyQueryHookResult = ReturnType<
  typeof useCompletedVerificationFormLazyQuery
>;
export type CompletedVerificationFormSuspenseQueryHookResult = ReturnType<
  typeof useCompletedVerificationFormSuspenseQuery
>;
export type CompletedVerificationFormQueryResult = Apollo.QueryResult<
  CompletedVerificationFormQuery,
  CompletedVerificationFormQueryVariables
>;
export const GetVideoCallDocument = gql`
    query getVideoCall($id: String!) {
  getVideoCall(id: $id) {
    id
    title
    dailyRoomId
    closedDoorAt
    endedAt
    participants {
      id
      isHost
      userId
    }
  }
}
    `;

/**
 * __useGetVideoCallQuery__
 *
 * To run a query within a React component, call `useGetVideoCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoCallQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideoCallQuery, GetVideoCallQueryVariables> &
    ({ variables: GetVideoCallQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideoCallQuery, GetVideoCallQueryVariables>(
    GetVideoCallDocument,
    options,
  );
}
export function useGetVideoCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideoCallQuery, GetVideoCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideoCallQuery, GetVideoCallQueryVariables>(
    GetVideoCallDocument,
    options,
  );
}
export function useGetVideoCallSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVideoCallQuery, GetVideoCallQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideoCallQuery, GetVideoCallQueryVariables>(
    GetVideoCallDocument,
    options,
  );
}
export type GetVideoCallQueryHookResult = ReturnType<typeof useGetVideoCallQuery>;
export type GetVideoCallLazyQueryHookResult = ReturnType<typeof useGetVideoCallLazyQuery>;
export type GetVideoCallSuspenseQueryHookResult = ReturnType<typeof useGetVideoCallSuspenseQuery>;
export type GetVideoCallQueryResult = Apollo.QueryResult<
  GetVideoCallQuery,
  GetVideoCallQueryVariables
>;
export const GetChannelVideoCallDocument = gql`
    query getChannelVideoCall($id: String!) {
  getChannelVideoCall(id: $id) {
    id
    title
    dailyRoomId
    closedDoorAt
    endedAt
  }
}
    `;

/**
 * __useGetChannelVideoCallQuery__
 *
 * To run a query within a React component, call `useGetChannelVideoCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelVideoCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelVideoCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChannelVideoCallQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChannelVideoCallQuery,
    GetChannelVideoCallQueryVariables
  > &
    ({ variables: GetChannelVideoCallQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChannelVideoCallQuery, GetChannelVideoCallQueryVariables>(
    GetChannelVideoCallDocument,
    options,
  );
}
export function useGetChannelVideoCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelVideoCallQuery,
    GetChannelVideoCallQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChannelVideoCallQuery, GetChannelVideoCallQueryVariables>(
    GetChannelVideoCallDocument,
    options,
  );
}
export function useGetChannelVideoCallSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChannelVideoCallQuery, GetChannelVideoCallQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChannelVideoCallQuery, GetChannelVideoCallQueryVariables>(
    GetChannelVideoCallDocument,
    options,
  );
}
export type GetChannelVideoCallQueryHookResult = ReturnType<typeof useGetChannelVideoCallQuery>;
export type GetChannelVideoCallLazyQueryHookResult = ReturnType<
  typeof useGetChannelVideoCallLazyQuery
>;
export type GetChannelVideoCallSuspenseQueryHookResult = ReturnType<
  typeof useGetChannelVideoCallSuspenseQuery
>;
export type GetChannelVideoCallQueryResult = Apollo.QueryResult<
  GetChannelVideoCallQuery,
  GetChannelVideoCallQueryVariables
>;
export const GetVideosDocument = gql`
    query getVideos {
  getVideos {
    id
    title
    videoURL
    thumbnail
    isActive
    position
    completedByMe
    set {
      id
    }
  }
}
    `;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
}
export function useGetVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
}
export function useGetVideosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    options,
  );
}
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosSuspenseQueryHookResult = ReturnType<typeof useGetVideosSuspenseQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const CommentCreatedDocument = gql`
    subscription commentCreated($targetId: String!) {
  commentCreated(targetId: $targetId) {
    id
    parentID
    message
    createdAt
    author {
      id
      name
      avatarUrl
    }
    reactions {
      id
      unifiedId
      user {
        id
        avatarUrl
        firstName
        lastName
      }
    }
    replies {
      id
      message
      createdAt
      author {
        id
        name
        avatarUrl
      }
      reactions {
        id
        unifiedId
        user {
          id
          avatarUrl
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useCommentCreatedSubscription__
 *
 * To run a query within a React component, call `useCommentCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentCreatedSubscription({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useCommentCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentCreatedSubscription,
    CommentCreatedSubscriptionVariables
  > &
    ({ variables: CommentCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<CommentCreatedSubscription, CommentCreatedSubscriptionVariables>(
    CommentCreatedDocument,
    options,
  );
}
export type CommentCreatedSubscriptionHookResult = ReturnType<typeof useCommentCreatedSubscription>;
export type CommentCreatedSubscriptionResult =
  Apollo.SubscriptionResult<CommentCreatedSubscription>;
export const CommentDeletedDocument = gql`
    subscription commentDeleted($targetId: String!) {
  commentDeleted(targetId: $targetId) {
    id
    parentID
  }
}
    `;

/**
 * __useCommentDeletedSubscription__
 *
 * To run a query within a React component, call `useCommentDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentDeletedSubscription({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useCommentDeletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentDeletedSubscription,
    CommentDeletedSubscriptionVariables
  > &
    ({ variables: CommentDeletedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<CommentDeletedSubscription, CommentDeletedSubscriptionVariables>(
    CommentDeletedDocument,
    options,
  );
}
export type CommentDeletedSubscriptionHookResult = ReturnType<typeof useCommentDeletedSubscription>;
export type CommentDeletedSubscriptionResult =
  Apollo.SubscriptionResult<CommentDeletedSubscription>;
export const ReactionCreatedDocument = gql`
    subscription reactionCreated($targetId: String!) {
  reactionCreated(targetId: $targetId) {
    id
    unifiedId
    user {
      id
      avatarUrl
      firstName
      lastName
    }
    comment {
      id
      parentID
    }
  }
}
    `;

/**
 * __useReactionCreatedSubscription__
 *
 * To run a query within a React component, call `useReactionCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReactionCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionCreatedSubscription({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useReactionCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ReactionCreatedSubscription,
    ReactionCreatedSubscriptionVariables
  > &
    ({ variables: ReactionCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<ReactionCreatedSubscription, ReactionCreatedSubscriptionVariables>(
    ReactionCreatedDocument,
    options,
  );
}
export type ReactionCreatedSubscriptionHookResult = ReturnType<
  typeof useReactionCreatedSubscription
>;
export type ReactionCreatedSubscriptionResult =
  Apollo.SubscriptionResult<ReactionCreatedSubscription>;
export const ReactionDeletedDocument = gql`
    subscription reactionDeleted($targetId: String!) {
  reactionDeleted(targetId: $targetId) {
    id
    unifiedId
    user {
      id
      avatarUrl
      firstName
      lastName
    }
    comment {
      id
      parentID
    }
  }
}
    `;

/**
 * __useReactionDeletedSubscription__
 *
 * To run a query within a React component, call `useReactionDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReactionDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionDeletedSubscription({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useReactionDeletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ReactionDeletedSubscription,
    ReactionDeletedSubscriptionVariables
  > &
    ({ variables: ReactionDeletedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<ReactionDeletedSubscription, ReactionDeletedSubscriptionVariables>(
    ReactionDeletedDocument,
    options,
  );
}
export type ReactionDeletedSubscriptionHookResult = ReturnType<
  typeof useReactionDeletedSubscription
>;
export type ReactionDeletedSubscriptionResult =
  Apollo.SubscriptionResult<ReactionDeletedSubscription>;
export const EventUpdatedDocument = gql`
    subscription eventUpdated($eventId: String!) {
  eventUpdated(eventId: $eventId) {
    id
    closedDoorAt
    noParticipants
    participants {
      id
      avatarUrl
      createdAt
      firstName
      lastName
      isVerified
    }
    invites {
      id
      isHost
      status
      user {
        id
        name
        firstName
        lastName
        createdAt
        badge
        avatarUrl
      }
    }
  }
}
    `;

/**
 * __useEventUpdatedSubscription__
 *
 * To run a query within a React component, call `useEventUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventUpdatedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventUpdatedSubscription,
    EventUpdatedSubscriptionVariables
  > &
    ({ variables: EventUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<EventUpdatedSubscription, EventUpdatedSubscriptionVariables>(
    EventUpdatedDocument,
    options,
  );
}
export type EventUpdatedSubscriptionHookResult = ReturnType<typeof useEventUpdatedSubscription>;
export type EventUpdatedSubscriptionResult = Apollo.SubscriptionResult<EventUpdatedSubscription>;
export const LiveTableAboutToEndDocument = gql`
    subscription liveTableAboutToEnd($liveTableId: String!) {
  liveTableAboutToEnd(liveTableId: $liveTableId) {
    id
    lastSingleUserAt
  }
}
    `;

/**
 * __useLiveTableAboutToEndSubscription__
 *
 * To run a query within a React component, call `useLiveTableAboutToEndSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveTableAboutToEndSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveTableAboutToEndSubscription({
 *   variables: {
 *      liveTableId: // value for 'liveTableId'
 *   },
 * });
 */
export function useLiveTableAboutToEndSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LiveTableAboutToEndSubscription,
    LiveTableAboutToEndSubscriptionVariables
  > &
    ({ variables: LiveTableAboutToEndSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LiveTableAboutToEndSubscription,
    LiveTableAboutToEndSubscriptionVariables
  >(LiveTableAboutToEndDocument, options);
}
export type LiveTableAboutToEndSubscriptionHookResult = ReturnType<
  typeof useLiveTableAboutToEndSubscription
>;
export type LiveTableAboutToEndSubscriptionResult =
  Apollo.SubscriptionResult<LiveTableAboutToEndSubscription>;
export const LiveTableHasEndedDocument = gql`
    subscription liveTableHasEnded($liveTableId: String!) {
  liveTableHasEnded(liveTableId: $liveTableId) {
    id
    endedAt
  }
}
    `;

/**
 * __useLiveTableHasEndedSubscription__
 *
 * To run a query within a React component, call `useLiveTableHasEndedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveTableHasEndedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveTableHasEndedSubscription({
 *   variables: {
 *      liveTableId: // value for 'liveTableId'
 *   },
 * });
 */
export function useLiveTableHasEndedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LiveTableHasEndedSubscription,
    LiveTableHasEndedSubscriptionVariables
  > &
    ({ variables: LiveTableHasEndedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LiveTableHasEndedSubscription,
    LiveTableHasEndedSubscriptionVariables
  >(LiveTableHasEndedDocument, options);
}
export type LiveTableHasEndedSubscriptionHookResult = ReturnType<
  typeof useLiveTableHasEndedSubscription
>;
export type LiveTableHasEndedSubscriptionResult =
  Apollo.SubscriptionResult<LiveTableHasEndedSubscription>;
export const LiveTableUpdatedDocument = gql`
    subscription liveTableUpdated($liveTableId: String!) {
  liveTableUpdated(liveTableId: $liveTableId) {
    id
    title
    englishLevel
  }
}
    `;

/**
 * __useLiveTableUpdatedSubscription__
 *
 * To run a query within a React component, call `useLiveTableUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveTableUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveTableUpdatedSubscription({
 *   variables: {
 *      liveTableId: // value for 'liveTableId'
 *   },
 * });
 */
export function useLiveTableUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LiveTableUpdatedSubscription,
    LiveTableUpdatedSubscriptionVariables
  > &
    ({ variables: LiveTableUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LiveTableUpdatedSubscription,
    LiveTableUpdatedSubscriptionVariables
  >(LiveTableUpdatedDocument, options);
}
export type LiveTableUpdatedSubscriptionHookResult = ReturnType<
  typeof useLiveTableUpdatedSubscription
>;
export type LiveTableUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<LiveTableUpdatedSubscription>;
export const LiveTableHasStartedDocument = gql`
    subscription liveTableHasStarted {
  liveTableHasStarted {
    name
    avatarUrl
    tableId
    tableTitle
    tableCreatorId
  }
}
    `;

/**
 * __useLiveTableHasStartedSubscription__
 *
 * To run a query within a React component, call `useLiveTableHasStartedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveTableHasStartedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveTableHasStartedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLiveTableHasStartedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    LiveTableHasStartedSubscription,
    LiveTableHasStartedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LiveTableHasStartedSubscription,
    LiveTableHasStartedSubscriptionVariables
  >(LiveTableHasStartedDocument, options);
}
export type LiveTableHasStartedSubscriptionHookResult = ReturnType<
  typeof useLiveTableHasStartedSubscription
>;
export type LiveTableHasStartedSubscriptionResult =
  Apollo.SubscriptionResult<LiveTableHasStartedSubscription>;
export const NotificationsSubscriptionDocument = gql`
    subscription notificationsSubscription {
  notificationAdded {
    id
    type
    createdAt
    notificationSeen {
      id
      seenAt
    }
    chatMentionNotification {
      ...ChatMentionNotificationFields
    }
    commentNotification {
      ...CommentNotificationFields
    }
    eventNotification {
      ...EventNotificationFields
    }
    articleNotification {
      ...ArticleNotificationFields
    }
  }
}
    ${ChatMentionNotificationFieldsFragmentDoc}
${CommentNotificationFieldsFragmentDoc}
${EventNotificationFieldsFragmentDoc}
${ArticleNotificationFieldsFragmentDoc}`;

/**
 * __useNotificationsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationsSubscriptionSubscription,
    NotificationsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationsSubscriptionSubscription,
    NotificationsSubscriptionSubscriptionVariables
  >(NotificationsSubscriptionDocument, options);
}
export type NotificationsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationsSubscriptionSubscription
>;
export type NotificationsSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<NotificationsSubscriptionSubscription>;
export const PopupNotificationsSubscriptionDocument = gql`
    subscription popupNotificationsSubscription {
  popupNotificationAdded {
    id
    type
    createdAt
    notificationSeen {
      id
      seenAt
    }
    badgeNotification {
      ...BadgeNotificationFields
    }
    eventNotification {
      ...EventNotificationFields
    }
    profileUpdateNotification {
      ...ProfileUpdateNotificationFields
    }
    monthlyReportNotification {
      ...MonthlyReportNotificationFields
    }
    friendJoinedNotification {
      ...FriendJoinedNotificationFields
    }
  }
}
    ${BadgeNotificationFieldsFragmentDoc}
${EventNotificationFieldsFragmentDoc}
${ProfileUpdateNotificationFieldsFragmentDoc}
${MonthlyReportNotificationFieldsFragmentDoc}
${FriendJoinedNotificationFieldsFragmentDoc}`;

/**
 * __usePopupNotificationsSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePopupNotificationsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePopupNotificationsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopupNotificationsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePopupNotificationsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PopupNotificationsSubscriptionSubscription,
    PopupNotificationsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PopupNotificationsSubscriptionSubscription,
    PopupNotificationsSubscriptionSubscriptionVariables
  >(PopupNotificationsSubscriptionDocument, options);
}
export type PopupNotificationsSubscriptionSubscriptionHookResult = ReturnType<
  typeof usePopupNotificationsSubscriptionSubscription
>;
export type PopupNotificationsSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<PopupNotificationsSubscriptionSubscription>;
export const SparkCreatedDocument = gql`
    subscription sparkCreated($eventId: String!) {
  sparkCreated(eventId: $eventId) {
    id
    event {
      id
    }
    user {
      id
    }
  }
}
    `;

/**
 * __useSparkCreatedSubscription__
 *
 * To run a query within a React component, call `useSparkCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSparkCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparkCreatedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useSparkCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SparkCreatedSubscription,
    SparkCreatedSubscriptionVariables
  > &
    ({ variables: SparkCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SparkCreatedSubscription, SparkCreatedSubscriptionVariables>(
    SparkCreatedDocument,
    options,
  );
}
export type SparkCreatedSubscriptionHookResult = ReturnType<typeof useSparkCreatedSubscription>;
export type SparkCreatedSubscriptionResult = Apollo.SubscriptionResult<SparkCreatedSubscription>;
export const CommunitySparkCreatedDocument = gql`
    subscription communitySparkCreated {
  communitySparkCreated {
    id
  }
}
    `;

/**
 * __useCommunitySparkCreatedSubscription__
 *
 * To run a query within a React component, call `useCommunitySparkCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommunitySparkCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitySparkCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCommunitySparkCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CommunitySparkCreatedSubscription,
    CommunitySparkCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CommunitySparkCreatedSubscription,
    CommunitySparkCreatedSubscriptionVariables
  >(CommunitySparkCreatedDocument, options);
}
export type CommunitySparkCreatedSubscriptionHookResult = ReturnType<
  typeof useCommunitySparkCreatedSubscription
>;
export type CommunitySparkCreatedSubscriptionResult =
  Apollo.SubscriptionResult<CommunitySparkCreatedSubscription>;
export const VideoAskFormCreatedDocument = gql`
    subscription videoAskFormCreated {
  videoAskFormCreated {
    id
  }
}
    `;

/**
 * __useVideoAskFormCreatedSubscription__
 *
 * To run a query within a React component, call `useVideoAskFormCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVideoAskFormCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoAskFormCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVideoAskFormCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    VideoAskFormCreatedSubscription,
    VideoAskFormCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    VideoAskFormCreatedSubscription,
    VideoAskFormCreatedSubscriptionVariables
  >(VideoAskFormCreatedDocument, options);
}
export type VideoAskFormCreatedSubscriptionHookResult = ReturnType<
  typeof useVideoAskFormCreatedSubscription
>;
export type VideoAskFormCreatedSubscriptionResult =
  Apollo.SubscriptionResult<VideoAskFormCreatedSubscription>;
export const VideoCallUpdatedDocument = gql`
    subscription videoCallUpdated($id: String!) {
  videoCallUpdated(id: $id) {
    id
    title
    dailyRoomId
    closedDoorAt
    endedAt
  }
}
    `;

/**
 * __useVideoCallUpdatedSubscription__
 *
 * To run a query within a React component, call `useVideoCallUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVideoCallUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoCallUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoCallUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    VideoCallUpdatedSubscription,
    VideoCallUpdatedSubscriptionVariables
  > &
    ({ variables: VideoCallUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    VideoCallUpdatedSubscription,
    VideoCallUpdatedSubscriptionVariables
  >(VideoCallUpdatedDocument, options);
}
export type VideoCallUpdatedSubscriptionHookResult = ReturnType<
  typeof useVideoCallUpdatedSubscription
>;
export type VideoCallUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<VideoCallUpdatedSubscription>;
export const ChannelVideoCallDocument = gql`
    subscription channelVideoCall($id: String!) {
  channelVideoCall(id: $id) {
    id
    title
    dailyRoomId
    closedDoorAt
    endedAt
  }
}
    `;

/**
 * __useChannelVideoCallSubscription__
 *
 * To run a query within a React component, call `useChannelVideoCallSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChannelVideoCallSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelVideoCallSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChannelVideoCallSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ChannelVideoCallSubscription,
    ChannelVideoCallSubscriptionVariables
  > &
    ({ variables: ChannelVideoCallSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChannelVideoCallSubscription,
    ChannelVideoCallSubscriptionVariables
  >(ChannelVideoCallDocument, options);
}
export type ChannelVideoCallSubscriptionHookResult = ReturnType<
  typeof useChannelVideoCallSubscription
>;
export type ChannelVideoCallSubscriptionResult =
  Apollo.SubscriptionResult<ChannelVideoCallSubscription>;
